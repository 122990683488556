import React from 'react'
import Dezajno1 from '../../Dezajnoj/Dezajno1'
import Dezajno2 from '../../Dezajnoj/Dezajno2'
import Dezajno3 from '../../Dezajnoj/Dezajno3'
import Dezajno4 from '../../Dezajnoj/Dezajno4'
import Dezajno5 from '../../Dezajnoj/Dezajno5'

class Momento extends React.Component {

    render() {
        return this.dezajno(this.props.dezajnTipo)
    }

    dezajno = (dezajnTipo) => {
        console.log("sonoebleco:" + this.props.sono)
        let enhavo = [...this.props.enhavo]
        let admin = this.props.admin
        let header = this.props.header
        let navigado = this.props.navigado
        enhavo = this.sonoŜalto(enhavo, this.props.sono)
        switch (dezajnTipo) {
            case "1": return <Dezajno1
                enhavo={enhavo}
                navigado={navigado}
                admin={admin}
                header={header}                
            />
            case "2": return <Dezajno2
                enhavo={enhavo}
                navigado={navigado}
                admin={admin}
                header={header}
            />
            case "3": return <Dezajno3
                enhavo={enhavo}
                admin={admin}
                header={header}
            />
            case "4": return <Dezajno4
                enhavo={enhavo}
                admin={admin}
                header={header}
            />
            case "5": return <Dezajno5
                enhavo={enhavo}
                admin={admin}
                header={header}
            />
            default: return <Dezajno1
                enhavo={enhavo}
                navigado={navigado}
                admin={admin}
                header={header}
            />
        }
    }
    sonoŜalto = (enhavo, sono) => {
        if (sono !== undefined)
            if (!sono)
                for (let indekso in enhavo)
                    if (enhavo[indekso] !== undefined)
                        if (enhavo[indekso].props !== undefined)
                            if (enhavo[indekso].props.className === "sono-enhavo")
                                enhavo[indekso] = ""
        return enhavo
    }

}

export default Momento