class GramatikajElementoj {
    constructor() {
        this.finaĵoj = ["a", "e", "i", "o", "u", "an", "j", "jn", "aj", "ajn", "en", "oj", "on", "ojn", "as", "is", "os"]
        this.sufiksoj = ["ant", "int", "ont", "aĉ", "ad", "aĵ", "an", "ar", "ebl", "ec", "eg", "ej", "em", "ebl",
            "end", "er", "estr", "et", "i", "id", "ig", "iĝ", "il", "in", "it", "at", "ot", "ind", "ing", "ism", "ist",
            "obl", "on", "op", "uj", "ul", "um"]
    }
    ĉuFinaĵo = (teksto) => this.finaĵoj.includes(teksto)
    ĉuSufikso = (teksto) => this.sufiksoj.includes(teksto)
}
export default GramatikajElementoj