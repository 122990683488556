import DatumLegilo from "./DatumLegoj"
import ObjektoLegilo from "./ObjektoLegoj"
import DatumSavilo from "./DatumSavoj"
import BildoMapoSavo from './BildoTrakto/BildoMapoSavo'
import BildoSavo from './BildoTrakto/BildoSavo'

class DatumbazoInterfaco {

   leguLeciononLaŭId = (id, referenco) => new DatumLegilo().legu("lecionoj", "*", "lecionoId=" + id, referenco) //[id, nomo] listo
   leguLeciononLaŭNomo = (lecionoNomo, referenco) => new DatumLegilo().legu("lecionoj", "*", "lecionoNomo=" + lecionoNomo, referenco) //return la donitaĵoj de la sceno

   leguScenonLaŭId = (scenoId, referenco) => new DatumLegilo().legu("scenoj", "*", "scenoId=" + scenoId, referenco) //return la donitaĵoj de la sceno
   leguScenonLaŭNomo = (scenoNomo, referenco) => new DatumLegilo().legu("scenoj", "*", "scenoNomo='" + scenoNomo + "'", referenco) //return la donitaĵoj de la sceno



   // legoDeLaBildoj = (bildoNomoj, referenco) => new DatumLegilo().legoDeLaBildoj(bildoNomoj, referenco) //return la surskribo difinoj de la bildoj
   leguBildoMaponLaŭNomo = (bildoMapoNomo, referenco) => new DatumLegilo().legu("bildoMapoj", "*", "bildoMapoNomo='" + bildoMapoNomo + "'", referenco)
   legoDeBildojKunMapo = (bildomapoNomoListo, referenco) => new DatumLegilo().legoDeBildojKunMapo(bildomapoNomoListo, referenco)
   legoDeLaBildojLauId=(idListo,referenco)=>new DatumLegilo().legoDeLaBildojLauId(idListo,referenco)

   legoDeSonoKunMapo = (sonoNomo, referenco) => new ObjektoLegilo().legoDeSonoKunMapo(sonoNomo, referenco)
   //legoSonon = (sonoNomo, referenco) => new DatumLegilo().legoDeBildojKunMapo(bildomapoNomoListo, referenco)


   petoDeScenoListo = (referenco) =>
      new DatumLegilo().legu("scenoj", "scenoId, scenoNomo", "", referenco)
   petoDeLecionoListo = (referenco) =>
      new DatumLegilo().legu("lecionoj", "lecionoId, lecionoNomo", "", referenco)
   petoDeBildoMapoListo = (referenco) =>
      new DatumLegilo().legu("bildomapoj", "bildoMapoId, bildoMapoNomo", "", referenco)
   petoDeSonoListo = (referenco) =>
      new DatumLegilo().legu("sonoj", "sonoId, sonoNomo", "", referenco)
   petoDeScenoNomoj = (scenoIdListo, referenco) =>
      new DatumLegilo().legu("scenoj", "scenoId, scenoNomo", "scenoId in (" + scenoIdListo + ")", referenco) // [id, nomo] listo

   simplajDatumoTraktoj = (tabulo,savaĵListo, forigListo, novaĵListo, referenco) => 
      new DatumSavilo().simplajDatumoTraktoj(tabulo,savaĵListo, forigListo, novaĵListo, referenco)
   kreuScenon = (sceno, referenco) =>
      new DatumSavilo().kreuScenon(sceno, referenco)
   modifuScenon = (sceno, referenco) =>
      new DatumSavilo().modifuScenon(sceno, referenco)
   forviŝuScenon = (id, referenco) =>
      new DatumSavilo().forviŝu("scenoj", "scenoId", id, referenco)
   forviŝuScenonLaŭNomo = (nomo, referenco) =>
      new DatumSavilo().forviŝu("scenoj", "scenoNomo", nomo, referenco)

   kreuLecionon = (titolo, scenoListo, referenco) =>
      new DatumSavilo().kreuLecionon(titolo, scenoListo, referenco)
   modifuLecionon = (id, titolo, scenoListo, referenco) =>
      new DatumSavilo().modifuLecionon(id, titolo, scenoListo, referenco)
   forviŝuLecionon = (id, referenco) =>
      new DatumSavilo().forviŝu("lecionoj", "lecionoId", id, referenco)
   forviŝuBildoMapon = (id, referenco) =>
      new DatumSavilo().forviŝu("bildomapoj", "bildoMapoId", id, referenco)
   savoDeBildoMapo = (datumoj, referenco) =>
      new BildoMapoSavo().savo(datumoj, referenco)
   ŝanĝuBildon = (datumoj, referenco) =>
      new BildoSavo().savo(datumoj, referenco)
}

export default DatumbazoInterfaco