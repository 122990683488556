import React from 'react'
import './css/sceno.css'
import NavigiloKradoKreilo from '../../KursoKomponantoj/KradoKreiloj/NavigiloKradoKreilo'
import RipetoTraktilo from './RipetoTraktilo'
import Kontrolo from './Kontrolo'
import ObjektoKradoj from './ObjektoKradoj'
import Momento from './Momento'

class ScenoStarto extends React.Component {
    constructor(props) {
        super(props)
        this.referenco = this.saltoAlMomento
        this.ScenoObjekto = this.props.scenoStato.prenoDeScenoDifino()
        this.momentoIndekso = this.props.scenoStato.prenoDeMomentoIndekso()
        this.RipetoTraktilo = new RipetoTraktilo()
        this.ObjektoKradoj = new ObjektoKradoj(
            this.ScenoObjekto,
            this.RipetoTraktilo
        )
        this.dezajnTipoj = this.ScenoObjekto.prenoDeDezajnTipoj()
        this.solvo = []
        this.demando = false

        this.state = {
            enhavo: [],
            dezajnTipo: "",
            sono: this.props.sono
        }

    }
    componentDidMount = () => {
        let dezajnTipo = this.ScenoObjekto.prenoDeMomentaDezajnTipo(0)
        this.setState({ dezajnTipo: dezajnTipo })
        this.momentoEnhavo()
    }
    componentWillUnmount = () => {
        let scenoStato = this.props.scenoStato
        scenoStato.valoroAlMomentoIndekso(this.momentoIndekso)
    }
    render = () => {
        return (
            <div className='{this.props.sono}'>
                <Momento enhavo={this.state.enhavo}
                    dezajnTipo={this.state.dezajnTipo}
                    sono={this.props.sono}
                    navigado={[this.navigadoKrado]}
                />
            </div>
        )
    }


    momentoEnhavo = () => {
        let momento = this.momentoIndekso
        let momentoPriskribo = [...this.ScenoObjekto.prenoDeMomento(momento)]
        if (this.isReĝisorInstrukcio(momentoPriskribo)) return
        this.navigadoKrado = new
            NavigiloKradoKreilo().enhavo(
                momento,
                this.nombroDeMomentoj(),
                this.referenco
            )
        let { solvo, demando, kradoEnhavoj } =
            this.ObjektoKradoj.petoDeKradoEnhavoj(momentoPriskribo)
        this.solvo = solvo
        this.demando = demando

        this.aktivigoDeLaMomento(kradoEnhavoj)
    }

    aktivigoDeLaMomento(kradoEnhavoj) {
        let enhavoj = []
        if (this.state !== undefined)
            enhavoj = [...this.state.enhavo]
        for (let i = 0; i < kradoEnhavoj.length; i++)
            if (kradoEnhavoj[i] !== undefined)
                enhavoj[i] = kradoEnhavoj[i]

        this.setState({
            enhavo: enhavoj
        })
    }

    isReĝisorInstrukcio(momentoPriskribo) {
        if (momentoPriskribo[0] === "dezajnTipo") {
            let dezajnTipo = momentoPriskribo[1]
            this.setState(
                { dezajnTipo: dezajnTipo }
            )
            this.neprePluen()
            return true
        }
        let isRipeto = this.RipetoTraktilo
            .isRipeto(
                momentoPriskribo,
                this.momentoIndekso
            )
        if (isRipeto) {
            if (momentoPriskribo[0] === "fino")
                this.momentoIndekso--
            this.neprePluen()
        }
        return isRipeto
    }



    nombroDeMomentoj() {
        return this.ScenoObjekto.prenoDeMomentoj().length
    }

    kontrolo = () => new Kontrolo().kontrolo(this.solvo)
    /////////////////////////Eventoj

    reen = () => {
        this.momentoIndekso = this.RipetoTraktilo.antaŭaMomentIndekso(this.momentoIndekso)
        if (this.momentoIndekso < 1)
            this.momentoIndekso = 0
        this.momentoEnhavo()
    }

    pluen = () => {
        if (this.demando)
            if (!this.kontrolo()) return
        this.neprePluen()
    }

    neprePluen = () => {
        this.demando = false
        this.momentoIndekso = this.sekvaMomentIndekso()
        this.momentoEnhavo()
    }

    saltoAlMomento = (evento) => {
        if (evento === "reen") return this.reen()
        if (evento === "pluen") return this.pluen()
        if (evento === "neprePluen") return this.neprePluen()
        let butonoKontenero = evento.target.parentElement
        let novaMomentoIndekso = parseInt(butonoKontenero.id)
        if (isNaN(novaMomentoIndekso)) return
        this.RipetoTraktilo.ripetoNuligo()
        if (this.dezajnTipoj[this.momentoIndekso] !== this.dezajnTipoj[novaMomentoIndekso])
            this.setState({ dezajnTipo: this.dezajnTipoj[novaMomentoIndekso] })
        this.momentoIndekso = novaMomentoIndekso - 2
        this.neprePluen()
    }

    sekvaMomentIndekso = () => {
        this.momentoIndekso++
        this.momentoIndekso = this.RipetoTraktilo.sekvaMomentIndekso(this.momentoIndekso)
        if (this.momentoIndekso > this.nombroDeMomentoj() - 1)
            this.momentoIndekso = 0
        return this.momentoIndekso
    }
}

export default ScenoStarto