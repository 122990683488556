
import React from 'react'
class TitolpaghoHTML extends React.Component {

    render() {

        return (

            <div id="titolo-pagho">
                <img src="../bildoj/kurso.png" id="kurso" alt="komenca paĝo" />
            </div>
        )
    }

}


export default TitolpaghoHTML
