import React from 'react'

import SelectEtikedo from '../../Utilajhoj/SelectEtikedo/SelektEtikedo'
import TitolpaghoHTML from './TitolPagho'
import Datumbazo from '../../Datumoj/DatumbazoMastrumilo/DatumbazoInterfaco'


class Lecionoelekto extends React.Component {
	akceptoDeLecionoId = (lecionoId) => this.lecionoElekto(lecionoId) 
	lecionoElekto = (lecionoId) => {
		this.lecionoId = lecionoId
		this.referenco = this.props.referenco
		if (lecionoId === 0) this.referenco(<TitolpaghoHTML />)
		else new Datumbazo().leguLeciononLaŭId(
					lecionoId, this.akceptoDeScenoListo)
	}

	akceptoDeScenoListo = (scenoListo) => this.prenoDeScenoListo(scenoListo)
	prenoDeScenoListo = (scenoListo) => {
		let lecionoNomo=JSON.parse(scenoListo)["lecionoNomo"]
		scenoListo = JSON.parse(scenoListo)["scenoListo"].slice(1, -1)		
		scenoListo = scenoListo.split(",")
		this.referenco( {lecionoId:this.lecionoId,scenoListo:scenoListo,lecionoNomo: lecionoNomo})
		return
	}

	render() {
		return (
			<SelectEtikedo id="leciono"
				kio="leciono" referenco={this.akceptoDeLecionoId} />
		)
	}
}

export default Lecionoelekto;