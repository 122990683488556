
import React from 'react'
import TitolPaĝo from './TitolPagho'
import LecionoElekto from './LecionoElekto'
import { Link } from "react-router-dom";
import ScenoAktivigilo from "../..//Utilajhoj/ScenoLegilo"
import { Button } from 'primereact/button';
import { PrimeIcons } from 'primereact/api';
import { Panel } from 'primereact/panel';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-green/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import Datumbazo from '../../Datumoj/DatumbazoMastrumilo/DatumbazoInterfaco'
import iei from '../../Utilajhoj/bildoj/iei.png';
import { TabView, TabPanel } from 'primereact/tabview';
import ScenoStato from '../../Utilajhoj/ScenoStato';

class Lecionoj extends React.Component {
    constructor(props) {
        super(props)
        this.scenoStato = new ScenoStato()
        this.ScenoAktivigilo = new ScenoAktivigilo()
        this.scenoNomoj = ""
        this.state = {
            scenoStato: <TitolPaĝo />,
            indekso: 0,
            sono: true,
            navigadoInterScenoj: "hidden",
            tabIndekso: 0
        }
        this.referenco = this.akceptoDeLeciono
    }

    render() {
        return (
            <Panel style={{ position: "absolute", top: "0px", left: "0px" }}>
                <TabView >
                    <TabPanel header="Lecionoj">
                        {this.supraEntenilo()}
                        <ScenoAktivigilo
                            scenoStato={this.state.scenoStato}
                            sono={this.state.sono}
                        />
                    </TabPanel>
                    <TabPanel header="Ekzercoj"></TabPanel>
                </TabView>
            </Panel>


        )
    }
    supraEntenilo() {
        let scenoButonoj = []
        let scenoNomoj = this.scenoNomoj
        if (scenoNomoj !== "")
            for (let indekso in scenoNomoj) {
                let label = scenoNomoj[indekso]
                scenoButonoj[indekso] = <Button key={indekso} icon={PrimeIcons.THUMBS_UP}
                    id={"sceno-butono" + indekso}
                    onClick={(e) => this.saltuAlSceno(e)}
                    label={label}
                    className="p p-m-5" />
            }
        return (
            <div >
                <Link to={
                    {
                        pathname: '/',
                        search: "admin"
                    }
                }>
                    <img src={iei} alt="al komenco" heigth="20" width="20" />
                </Link>

                <Button icon={PrimeIcons.HOME}
                    onClick={() => this.setState({
                        html0: <TitolPaĝo />,
                        indekso: 0,
                        navigadoInterScenoj: "hidden"
                    })} />
                <LecionoElekto referenco={this.referenco} />
                <Button icon={this.state.sono ? PrimeIcons.VOLUME_UP : PrimeIcons.VOLUME_OFF}
                    onClick={() => this.setState({
                        sono: !this.state.sono
                    })} />
                <div id="navigadoInterScenoj"
                    style={{ visibility: this.state.navigadoInterScenoj }}>
                    {scenoButonoj}

                </div>
            </div>)
    }

    akceptoDeLeciono = (leciono) => this.traktoDeLeciono(leciono)
    traktoDeLeciono(leciono) {
        this.scenoIdListo = leciono.scenoListo
        let scenoId = this.scenoIdListo[0]
        this.nombroDeScenoj = this.scenoIdListo.length
        this.scenoId = scenoId
        new Datumbazo().petoDeScenoNomoj(this.scenoIdListo, this.akceptoDeScenoNomoj)

    }
    akceptoDeScenoNomoj = (scenoNomoj) => {
        scenoNomoj = JSON.parse("[" + scenoNomoj + "]")
        this.scenoNomoj = []
        for (let indekso in scenoNomoj) {
            let id = scenoNomoj[indekso].scenoId
            let nomo = scenoNomoj[indekso].scenoNomo
            for (let i in this.scenoIdListo)
                if (id === this.scenoIdListo[i])
                    this.scenoNomoj[i] = nomo
        }
        this.scenoLego()
    }
    akceptoDeSceno = (sceno) => this.aperigo(sceno)
    aperigo = (sceno) => {
        sceno = JSON.parse(sceno)
        sceno = sceno["sceno"]
        this.scenoStato.valoroAlScenoId(this.scenoId)
        this.scenoStato.valoroAlScenoDifino(sceno)
        this.setState({
            scenoStato: ""
        })
        this.setState({
            scenoStato: this.scenoStato,
            navigadoInterScenoj: "visible"
        })
    }

    scenoLego = () => {
        if (this.scenoId > 0)
            new Datumbazo().leguScenonLaŭId(this.scenoId, this.akceptoDeSceno)
    }

    saltuAlSceno(evento) {
        let label = evento.target
        if (!label.className.includes("p-button-label"))
            label = evento.target.getElementsByClassName("p-button-label")[0]
        let scenoNomo = label.innerHTML
        new Datumbazo().leguScenonLaŭNomo(scenoNomo, this.akceptoDeSceno)
    }

}


export default Lecionoj