import React from 'react'
import { DataTable as DatumoTabulo } from 'primereact/datatable'
import { Column as Kolumno } from 'primereact/column'
import { InputNumber } from 'primereact/inputnumber'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast';
import TabuloDatumoj from './TabuloDatumoj'

class MapoTabulo extends React.Component {
    constructor(props) {
        super(props);
        this.elektitaVico = null
        this.TabuloDatumoj = new TabuloDatumoj(
            this.props.tipo,
            this.redaktilo,
            this.agado)
        this.kolumnoKapoVicoj = this.TabuloDatumoj.kolumnoKapoVicoj
        this.vicoEnhavoj = this.TabuloDatumoj.vicoEnhavoj
        this.state = {
            tabulEnhavo: this.props.tabulEnhavo,
            editingRows: {},
            selected: null
        }
        this.editingCellRows = {}
        this.originalRows = {}
        this.originalRows2 = {}
        this.plusajKolumnoj = this.TabuloDatumoj.plusajKolumnoj
    }

    render() {
        let tabulEnhavo= this.state.tabulEnhavo
        if (this.props.tabulEnhavo !== null)
            if (this.props.tabulEnhavo !== undefined)
                if (this.props.tabulEnhavo !== this.tabulEnhavo) {
                    tabulEnhavo = this.props.tabulEnhavo
                    this.tabulEnhavo = tabulEnhavo
                    console.log(tabulEnhavo)
                    this.setState({ tabulEnhavo: tabulEnhavo })
                } //else tabulEnhavo = this.state.tabulEnhavo
        return <div>
            <Toast ref={(el) => this.toast = el} />
            <DatumoTabulo
                value={tabulEnhavo}
                headerColumnGroup={this.kolumnoKapoVicoj}
                editMode="row" dataKey="id"
                onRowEditInit={this.onRowEditInit}
                onRowEditCancel={this.onRowEditCancel}
                selectionMode="single"
                onSelectionChange={(e) => this.onSelectionChange(e)}
                selection={this.state.selected}
                reorderableColumns
                onRowReorder={this.onRowReorder}
            >
                {this.vicoEnhavoj}
                <Kolumno rowEditor
                    headerStyle={{ width: '7rem', textAlign: 'center' }}
                    bodyStyle={{ textAlign: 'center' }}
                />
                <Kolumno body={this.vicoForigoButono} />
                {this.plusajKolumnoj}

            </DatumoTabulo>
        </div>
    }

    redaktilo = (datumoŜlosilo, props, tipo) => {
        if (this.TabuloDatumoj.tekstoListo.includes(tipo))
            return this.tekstoRedaktilo(datumoŜlosilo, props, tipo)
        return this.numeroRedaktilo(datumoŜlosilo, props, tipo)
    }

    tekstoRedaktilo(datumoŜlosilo, props, tipo) {
        return this.inputTextEditor(datumoŜlosilo, props, tipo);
    }

    numeroRedaktilo(datumoŜlosilo, props, tipo) {
        return <InputNumber value={props.rowData[tipo]}
            onValueChange={(e) => this.onEditorValueChange(datumoŜlosilo, props, e.value)}
            mode="decimal"
            locale="hu-HU"
            minFractionDigits={0}
            maxFractionDigits={2}
            min="0" max="100"
        />
    }

    inputTextEditor = (datumoŜlosilo, props, field) => {
        return <InputText type="text" value={props.rowData[field]}
            onChange={(e) => this.onEditorValueChange(datumoŜlosilo, props, e.target.value)}
        />;
    }

    onRowReorder = (e) => {
        this.setState({ tabulEnhavo: e.value }, () => {
            this.toast.show({
                severity: 'success',
                summary: 'La vicoj estas reordigitaj',
                life: 3000
            });
        });
        this.props.referenco(e.value)
    }

    onRowEditInit = (event) => {
        console.log(this.originalRows)
        this.originalRows[event.index] = { ...this.state.tabulEnhavo[event.index] };
        console.log(this.originalRows)
        this.setState({})
    }

    onRowEditCancel = (event) => {
        let tabulEnhavo = [...this.state.tabulEnhavo];
        tabulEnhavo[event.index] = this.originalRows[event.index];
        delete this.originalRows[event.index];
        this.setState({ tabulEnhavo: tabulEnhavo });
        this.props.referenco(tabulEnhavo)
    }

    onEditorValueChange = (datumoŜlosilo, props, value) => {
        let modifitaMapo = [...props.value];
        modifitaMapo[props.rowIndex][props.field] = value;
        this.setState({
            [`${datumoŜlosilo}`]: modifitaMapo,
        })
        this.props.referenco(modifitaMapo)
    }

    onSelectionChange(e) {
        this.setState({ selected: e.value })
    }

    vicoForigoButono = () => {
        return (
            <Button icon="pi pi-trash" className="p-button-rounded p-button-danger"
                onClick={(e) => this.agado(e)} />)
    }

    agado = (e) => {
        if (this.state.tabulEnhavo.length === 1) return
        let butono = e.target
        while (butono.nodeName !== "BUTTON") butono = butono.parentElement
        let td = butono.parentElement
        let tr = td.parentElement
        let teksto = tr.children[0].innerHTML
        butono = butono.children[0].className
        let indekso = this.elektitaVicoIndekso(teksto)
        this.vicoEkstrakto(indekso, butono)
    }

    vicoEkstrakto(indekso, butono) {
        let nova = [...this.state.tabulEnhavo]
        let vico = nova[indekso]
        nova.splice(indekso, 1)
        if (butono.includes("trash"))
            this.setState({ tabulEnhavo: nova })
        this.props.referenco({ nova: nova, vico: vico, butono: butono })
    }

    elektitaVicoIndekso(teksto) {
        let vicoId = this.TabuloDatumoj.vicoId
        for (let indekso in this.state.tabulEnhavo)
            if (this.state.tabulEnhavo[indekso][vicoId] === teksto)
                return (indekso)
    }
}

export default MapoTabulo