import React from 'react'
import BildoMapoTrakto from './BildoMapoTrakto'

class BildoTabulo extends React.Component{

    render() {
        return <table style={{ border: '1px solid red', width: '80%' }}>
            <tbody>
                <tr style={{ height: '20em' }}>
                    <td id="bildo-mapo-teksto"
                        style={{ width: '10%' }}></td>
                    <td style={{ width: '40%', textAlign: "center"  }} >
                        <img id="bildo" src={this.props.bildo} alt="bildo"
                            useMap="#imgMap"
                            onClick={(e) => this.koordinatoj(e)}
                            style={{ border: '1px solid black', height: '100%' }}
                        />
                        <map name="imgMap">
                            {this.petoDeAreoj()}
                        </map>

                    </td>
                    <td style={{ width: '10%' }}>
                        <div id="koordinatoj" />
                    </td>
                    <td style={{ width: '40%' }}>
                    <img id="bildo" src={this.props.bildo2} alt="bildo"
                            style={{ border: '1px solid black', height: '100%' }}
                        />
                    </td>
                </tr>
            </tbody>
        </table>

    }

    koordinatoj = (event) => {
        new BildoMapoTrakto().koordinatoj(event)
        this.setState({})
    }

    petoDeAreoj = () => {
        let surskriboj = this.props.bildoMapo
        let areoj = new BildoMapoTrakto().petoDeAreoj(surskriboj)
        return areoj
    }
}

export default BildoTabulo