import api from "./api.json"

class DatumSavoj {
    constructor() {
        let urlBazo = api.urlBazo
        this.url = ""
        this.aldonoURL = urlBazo + api.novaSceno
        this.modifoURL = urlBazo + api.scenoModifo
        this.forigoURL = urlBazo + api.forigo
        this.bildoMapoModifoURL = urlBazo + api.mapoModifoLaŭNomo
        this.lecionoModifo = urlBazo + api.lecionoModifo
        this.lecionoKreo = urlBazo + api.lecionoKreo
        this.simplajSavoj = urlBazo + api.simplajSavoj
    }

    ajaxGet(getPeto, referenco) {
        (async () => {
            const res = await fetch(this.url + getPeto);
            let rezulto = await res.text()
            referenco(rezulto)
        })();
      //  console.log(this.url + getPeto)
    }

    simplajDatumoTraktoj(tabulo, savaĵListo, forigListo, novaĵListo, referenco) {
  
        this.url = this.simplajSavoj
        let getPeto = "?tabulo=" + tabulo
        getPeto +=  this.getPetigu("&forigi[]=",forigListo)
        getPeto += this.getPetigu("&savi[]=" , savaĵListo)
        getPeto += this.getPetigu("&novaj[]=", novaĵListo)
        this.ajaxGet(getPeto, referenco)
    }
    //insert
    getPetigu(kio,listo){
        let getPeto=""
        for (let indekso in listo){
            getPeto+=kio+listo[indekso]
        }
        return getPeto
    }
    kreuScenon(sceno, referenco) {
        this.url = this.aldonoURL
        let scenoNomo = JSON.parse(sceno)["scenoNomo"]
        sceno = JSON.stringify(sceno)
        sceno = sceno.substring(1, sceno.length - 1)
        sceno = sceno.replaceAll("&", "%26")
        let getPeto =
            "?sceno=" + sceno + "&titolo=" + scenoNomo
        this.ajaxGet(getPeto, referenco)
    }

    //update
    modifuScenon(sceno, referenco) {
        this.url = this.modifoURL
        let sceno0 = JSON.parse(sceno)
        let id = sceno0["scenoId"]
        let titolo = sceno0["scenoNomo"]
        sceno = JSON.stringify(sceno)
        sceno = sceno.substring(1, sceno.length - 1)
        sceno = sceno.replaceAll("&", "%26")
        let getPeto =
            "?titolo=" + titolo + "&id=" + id+"&sceno=" + sceno 
        this.ajaxGet(getPeto, referenco)
    }
    kreuLecionon(titolo, scenoListo, referenco) {
        this.url = this.lecionoKreo
        let getPeto = "?titolo=" + titolo + "&scenoListo=[" + scenoListo + "]"
        return this.ajaxGet(getPeto, referenco)
    }
    modifuLecionon(identigilo, titolo, scenoListo, referenco) {
        this.url = this.lecionoModifo
        let getPeto = "?id=" + identigilo + "&titolo=" + titolo + "&scenoListo=[" + scenoListo + "]"
        return this.ajaxGet(getPeto, referenco)
    }
    // delete

    forviŝu(tabulo, kolumno, valoro, referenco) {
        this.url = this.forigoURL
        let getPeto = "?tabulo=" + tabulo + "&kolumno=" + kolumno + "&valoro=" + valoro
        return this.ajaxGet(getPeto, referenco)
    }
    forviŝuBildonLaŭMaponId(identigilo, referenco) {
        this.url = this.forigoURL
        let tabulo = "bildoMapoj"
        let kondiĉo = "WHERE bildoMapoId=" + identigilo
        let getPeto = "?tabulo=" + tabulo + "&kondicho=" + kondiĉo
        return this.ajaxGet(getPeto, referenco)
    }

    forviŝuBildonLaŭBildoNomo(bildoNomo, referenco) {
        this.url = this.forigoURL
        let tabulo = "bildoMapoj"
        let kondiĉo = "WHERE bildoNomo=" + bildoNomo
        let getPeto = "?tabulo=" + tabulo + "&kondicho=" + kondiĉo
        return this.ajaxGet(getPeto, referenco)
    }

}


export default DatumSavoj