
import { Link } from "react-router-dom";
import { Button } from 'primereact/button';

import "./stilo.css";
import React from "react";


class   Salutpaĝo extends React.Component{

  render(){
  return (
    <div id="nula-pagho">
  
      <h2 className="subtitolo">internacia lingvo</h2>
      
      <h1>ESPERANTO</h1>

      <div className="butono-al-chefo">
        <Link to="/komenco">
          <Button label="KURSO DE ESPERANTO" 
          icon="pi pi-clock" 
          iconPos="top"
          className="p-button-lg" />
        </Link>
      </div>
      
    </div>
  )}

}


export default Salutpaĝo;
