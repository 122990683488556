import React from 'react'
import ScenoAdministrado from './ScenoAdministrado'
import BildoAdministrado from './BildoMapoAdministrado'
import SonoAdministrado from './SonoAdministrado'
import LecionoAdministrado from './LecionoAdministrado'

import SalutPaĝo from './Salutpagho'
import { TabView, TabPanel } from 'primereact/tabview';
import { ReactFitty } from "react-fitty";
import Lecionoj from '../Kurso/Leciono/Lecionoj'


class AdministradoStarto extends React.Component {
  constructor() {
    super()
    this.tabEnhavoj = this.tabEnhavoj()
    this.pozicio = { position: "absolute", top: "45px", left: "0px", width: "100%" }
    this.state = {
      admin: <SalutPaĝo referenco={this.freŝigo} />
    }
  }

  render() {

    if (window.location.href.slice(-5) === "admin")
      window.location.href += "istrejo"
    return <div id="admin" style={{ position: "absolute",left:"10px",width: "100%", height: "100%" }}>
      {this.state.admin}
    </div>
  }

  komenco = () => {
    this.setState({
      admin: <SalutPaĝo referenco={this.freŝigo} />
    })
  }
  tabEnhavoj = () => {
    let tabEnhavoj = {
      sceno: <ReactFitty onClick={() => this.scenoAdmin("")} style={{ width: "50%" }}>
        - Kreo de nova sceno el nulo aŭ el alia sceno<br />
        - Elekto kaj redakto, modifo de sceno<br />
        - Forviŝo de sceno<br />
      </ReactFitty>,
      leciono: <ReactFitty onClick={() => this.lecionoAdmin("")} style={{ width: "50%" }}>
        - Kreo de nova leciono<br />
        - Aldono aŭ forviŝo de sceno <br />
        - Ŝanĝo de la vicordo de la scenoj<br />
      </ReactFitty>,
      bildoj: <ReactFitty onClick={() => this.bildoAdmin("")} style={{ width: "50%" }}>

        - Elekto de bildo kun bildomapo el la datumbazo<br />
        - Alŝuto de bildo (aŭtomate aldoniĝas la baza bildomapo)<br />
        - Redakto de la bildomapo, kaj la bildomapo nomo<br />
        - Savo de la modifoj<br />
        - Savo kiel nova bildomapo<br />
        - Forviŝo de bildomapo <br />
        (Se iu sono jam ne havas mapon, tiam eĉ ĝi forviŝiĝas !)<br />
        - Ŝango de sono<br />
      </ReactFitty>,
      sono: <ReactFitty onClick={() => this.sonoAdmin("")} style={{ width: "50%" }}>
        - Elekto de sonofajlo kun sonomapo el la datumbazo<br />
        - Alŝuto de sonofajlo (aŭtomate aldoniĝas la baza sonomapo)<br />
        - Redakto de la sonomapo, kaj la sonomapo nomo<br />
        - Savo de la modifoj<br />
        - Forviŝo de sonofajlo kun sia mapo <br />
        - Ŝango de sono<br />
      </ReactFitty>
    }
    return tabEnhavoj
  }
  freŝigo = () => {
    let admin = this.admin()
    this.setState({
      admin: admin
    })
  }
  admin = () => {


    let admin =

      <TabView style={{ position: "absolute", top: "0px", left: "0px" }}>

        <TabPanel header="LecionoPaĝo">
          <div style={this.pozicio}>
            <Lecionoj />
          </div>
        </TabPanel>
        <TabPanel header="Sceno administrado">
          <div style={this.pozicio}>
            {this.tabEnhavoj.sceno}
          </div>
        </TabPanel>
        <TabPanel header="Lecionoredakto">
          <div style={this.pozicio}>
            {this.tabEnhavoj.leciono}
          </div>
        </TabPanel>
        <TabPanel header="Bildo administrado">
          <div style={this.pozicio}>
            {this.tabEnhavoj.bildoj}
          </div>
        </TabPanel>
        <TabPanel header="Sono administrado">
          <div style={this.pozicio}>
            {this.tabEnhavoj.sono}
          </div>
        </TabPanel>
      </TabView>
    return admin
  }
  scenoAdmin = (stato) => {
    this.tabEnhavoj.sceno = <ScenoAdministrado state={stato} referenco={this.scenoAdmin} />
    this.freŝigo()
  }
  lecionoAdmin = (stato) => {
    this.tabEnhavoj.leciono = <LecionoAdministrado
      state={stato}
      referenco={this.lecionoAdmin}
    />
    this.freŝigo()
  }

  bildoAdmin = (stato) => {
    this.tabEnhavoj.bildoj = <BildoAdministrado
      state={stato}
      referenco={this.bildoAdmin}
    />
    this.freŝigo()
  }

  sonoAdmin = (stato) => {
    this.tabEnhavoj.sono = <SonoAdministrado
      state={stato}
      referenco={this.bildoAdmin}
    />
    this.freŝigo()
  }

}

export default AdministradoStarto
