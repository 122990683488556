import DatumoObjekto from "./DatumObjekto"

class VortaroKreilo {

  kreuScenon(datumo) {

    let DatumObjekto = new DatumoObjekto()
    let vortoListo = datumo["vortoListo"]
    for (let indekso in vortoListo) {
      if (vortoListo[indekso].length > 2) {
        let difino = vortoListo[indekso][1] + "\n" + vortoListo[indekso][2]
        vortoListo[indekso][1] = difino
      }
    }
    DatumObjekto.valoroAlVortolisto(vortoListo)
    return DatumObjekto
  }

}

export default VortaroKreilo