import React from 'react';
import { Editor } from 'primereact/editor';
import { Button } from 'primereact/button';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css'
//import './editor.css'
class TekstoRedaktilo extends React.Component {
	constructor(props) {
		super(props);
		this.teksto = this.props.teksto
		this.state = {
			teksto: this.props.teksto
		};
	}

	renderHeader() {
		return (
			<span className='ql-formats'>
				<button className='ql-bold' aria-label='Dika' />
				<button className='ql-italic' aria-label='Klinita' />
				<button className='ql-underline' aria-label='Substrekita' />
				<button className='ql-strike' aria-label='Trastrekita' />
				<button className="ql-script" value="sub"></button>
				<button className="ql-script" value="super"></button>
				<button className='ql-link' />
				<select className="ql-color" />
				<select className="ql-background" />
				<button className='ql-clean' />
			</span>
		);
	}
	butonoj() {
		let butonoj = <div>
			<Button className="p-m-2"
				label='Forviŝu la tekston!'
				icon='pi pi-times'
				onClick={() => this.setState({ teksto: '' })}
			/>

			<Button className="p-m-2"
				label='Kontrolu!'
				icon='pi pi-check'
				onClick={() => this.kontrolu()}
			/>
		</div>
		return butonoj
	}
	kontrolu = () => {
		let teksto = this.state.teksto
		if (teksto === null) return ""
		teksto = teksto.replaceAll('class=', "className=")
		/*teksto = teksto.replaceAll('style="', "style={{")
		teksto = teksto.replaceAll(');"', ")'}}")
		teksto = teksto.replaceAll('rgb', "'rgb")
		teksto = teksto.replaceAll(');', ")',")
		teksto = teksto.replaceAll('"', "'")
		teksto = teksto.replaceAll('-c', "C")*/
		this.props.referenco(teksto)
		//	return teksto
	}
	ŝanĝu = (e) => {
		this.setState({ teksto: e.htmlValue })
	}
	novaEnhavo() {
		console.log(this.props.teksto)
		this.teksto = this.props.teksto
		this.setState({ teksto: this.teksto })

	}
	render() {
		if (this.teksto !== this.props.teksto) this.novaEnhavo()
		const header = this.renderHeader();
		const butonoj = this.butonoj()
		return (
			<div id="redaktilo">
				{butonoj}
				<Editor
					headerTemplate={header}
					value={this.state.teksto}
					onTextChange={e => this.ŝanĝu(e)}
					style={{ width: "100%", height: "600px" }}
				/>


			</div>

		);
	}
}

export default TekstoRedaktilo;
