import Sono from "./Sono"


class SonoKradoKreilo {

    enhavo(kradoIndekso,f,  sono, de, ĝis) {
        console.log([sono,de,ĝis])
        return(
        <div key={"enhavo" + kradoIndekso} id={"enhavo" + kradoIndekso}
            className={"sono-enhavo"}>
            <Sono sono={sono} de={de} ĝis={ĝis} />
        </div>
        )
    }
}

export default SonoKradoKreilo