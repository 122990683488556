import React from 'react';

class Klapo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            enhavo: this.props.enhavo,
            klasoNomo: this.props.klasoNomo
        }
    }

    render(id) {
        return (

            <div key={this.props.id} id={this.props.id} className={this.state.klasoNomo} onClick={(evento, id) => { this.props.onclick(evento, this.props.id) }}>
                <div key={this.props.id + "karto"} className="karto">{this.state.enhavo}</div>
            </div>
        )
    }
    componentDidMount() {
        this.props.senduKlapon(this)
    }
}

export default Klapo