
import BildoKradoKreilo from '../../KursoKomponantoj/KradoKreiloj/BildoKradoKreilo'
import TekstoKradoKreilo from '../../KursoKomponantoj/KradoKreiloj/TekstoKradoKreilo'
import SonoKradoKreilo from '../../KursoKomponantoj/KradoKreiloj/Sono/SonoKradoKreilo'
import Supersignoj from '../../KursoKomponantoj/SuperSignilo/Supersignoj'

class ObjektoKradoj {
    constructor(ScenoObjekto, RipetoTraktilo) {
        this.ScenoObjekto = ScenoObjekto
        this.RipetoTraktilo = RipetoTraktilo
        this.sonoj = ScenoObjekto.prenoDeSonoj()
        this.NURTEKSTO = true
        this.antaŭaSono = ""
        this.referenco = ""
        this.momentoPriskribo = {}

    }

    petoDeKradoEnhavoj(momentoPriskribo) {
        let momentoDatumoj = this.kreoDeBildoKradoEnhavoj(momentoPriskribo)
       return this.petoDePliajEnhavoj(momentoDatumoj)
    }

    kreoDeBildoKradoEnhavoj(momentoPriskribo) {
        this.momentoPriskribo = momentoPriskribo
        let enhavoj = []
        let bildoKradoj = this.listoDeBildoKradoPriskriboj(this.momentoPriskribo)
        let bildoj = this.ScenoObjekto.prenoDeBildoj()
        for (let indekso in bildoKradoj) {
            let bildoNomo = bildoKradoj[indekso].bildoNomo
            bildoNomo = this.ripetŜanĝo(bildoNomo, this.NURTEKSTO)
            let bildo = bildoNomo
            for (let indekso in bildoj)
                if (bildoj[indekso].bildoNomo === bildoNomo) {
                    bildo = bildoj[indekso]
                    break
                }
            let alto=this.altoDeLaKrado(bildoKradoj[indekso].kradoIndekso) 
            let enhavo = new BildoKradoKreilo().enhavo(bildo,alto)
            enhavoj[indekso] = enhavo
        }
        let kradoEnhavoj = []
        for (let indekso in enhavoj) {
            let i = bildoKradoj[indekso].kradoIndekso
            kradoEnhavoj[i] = enhavoj[indekso]
        }

        let momentoDatumoj = {
            "priskribo": momentoPriskribo,
            "kradoEnhavoj": kradoEnhavoj
        }
        return momentoDatumoj
    }
    altoDeLaKrado(indekso){
        let krado=document.getElementById("krado"+indekso)
        if (krado===undefined) krado=document.getElementById("kradot"+indekso)
        let alto=krado.clientHeight
       if (alto===0) alto=200
      return 0.85*alto+"px"
    }
    petoDePliajEnhavoj(momentoDatumoj) {
        let momentoPriskribo = momentoDatumoj.priskribo
        let kradoEnhavoj = momentoDatumoj.kradoEnhavoj
        this.demando = false
        let demando = ""
        this.solvo = []
        let solvoIndekso = 0

        for (let indekso = 0; indekso < momentoPriskribo.length; indekso += 2) {
            let kradoEnhavo = momentoPriskribo[indekso + 1]
            if (kradoEnhavo.slice(0, 2) === "b:") continue
            let kradoIndekso = momentoPriskribo[indekso]
            let teksto = ""

            if (document.getElementsByClassName("sono-enhavo")[0] === undefined) this.antaŭaSono = ""
            if (kradoEnhavo.includes("sono")) {
                if (this.antaŭaSono === kradoEnhavo) continue
                this.antaŭaSono = kradoEnhavo
                let sono = []
                sono[0] = kradoEnhavo
                sono[1] = "0"
                this.sono = sono
                kradoEnhavoj[kradoIndekso] =
                    this.sonoEnhavo(sono, kradoIndekso)
                continue
            }
            if (kradoEnhavo.includes("video")) {
                kradoEnhavoj[kradoIndekso] =
                    this.videoEnhavo(kradoEnhavo, kradoIndekso)
                continue
            }
            if (Array.isArray(kradoEnhavo)) {
                if (kradoEnhavo[0].includes("sono")) {
                    if (this.antaŭaSono === kradoEnhavo) continue
                    this.antaŭaSono = kradoEnhavo
                    kradoEnhavoj[kradoIndekso] =
                        this.sonoEnhavo(kradoEnhavo, kradoIndekso)
                    continue
                }
                ({ teksto, kradoEnhavoj } = this.kompleksaEnhavo(
                    kradoEnhavo, solvoIndekso, demando, teksto,
                    kradoEnhavoj, kradoIndekso)
                )
            } else {
                kradoEnhavo = this.ripetŜanĝo(kradoEnhavo)
                kradoEnhavoj[kradoIndekso] =
                    new TekstoKradoKreilo().enhavo(kradoEnhavo, "")
            }
        }
        let solvo = this.solvo
        demando = this.demando
        return ({ kradoEnhavoj, solvo, demando })
    }



    listoDeBildoKradoPriskriboj(momentoPriskribo) {
        let bildoKradoj = []
        let bildoIndekso = 0
        for (let indekso = 0; indekso < momentoPriskribo.length; indekso += 2) {
            let kradoIndekso = momentoPriskribo[indekso]
            let kradoEnhavo = momentoPriskribo[indekso + 1]
            if (kradoEnhavo.slice(0, 2) === "b:") {
                bildoKradoj[bildoIndekso++] = {
                    kradoIndekso: kradoIndekso,
                    bildoNomo: kradoEnhavo.slice(2)
                }

                continue
            }
        }

        return bildoKradoj
    }







    sonoEnhavo(sono, kradoIndekso) {
        let sonoIndekso = sono[0].slice(sono.indexOf("sono") + 5)
        let sonoLoko = "../sonoj/" + this.sonoj[sonoIndekso]
        sonoLoko = this.ripetŜanĝo(sonoLoko, this.NURTEKSTO)
        let de = sono[1]
        let ĝis = sono[2]
        de = parseFloat(de)
        ĝis = parseFloat(ĝis)
        let sonoEnhavo = new SonoKradoKreilo().enhavo(
            kradoIndekso, sonoIndekso,
            sonoLoko, de, ĝis
        )
        return sonoEnhavo
    }

    ripetŜanĝo(teksto, ĉuNurTeksto) {
        return this.RipetoTraktilo.ripetŜanĝo(teksto, ĉuNurTeksto)
    }

    kompleksaEnhavo(kradoEnhavo, solvoIndekso, demando, teksto, kradoEnhavoj, kradoIndekso) {
        for (let vicoEro = 0; vicoEro < kradoEnhavo.length; vicoEro++) {
            if (kradoEnhavo[vicoEro].includes("...")) {
                ({ solvoIndekso, demando } =
                    this.demandoEnhavo(solvoIndekso, kradoEnhavo, vicoEro, demando)
                )
                vicoEro++
            } else {
                kradoEnhavo[vicoEro] = this.ripetŜanĝo(kradoEnhavo[vicoEro])
                teksto += kradoEnhavo[vicoEro] + "<br/>"
            }
            kradoEnhavoj[kradoIndekso] =
                new TekstoKradoKreilo().enhavo(teksto, demando)

        }
        return { teksto, kradoEnhavoj }
    }

    demandoEnhavo(solvoIndekso, kradoEnhavo, vicoEro, demando) {
        this.solvo[solvoIndekso] = this.ripetŜanĝo(kradoEnhavo[vicoEro + 1], this.NURTEKSTO)
        let longo = this.solvo[solvoIndekso].length + 1
        let respId = "respondo" + solvoIndekso
        demando = this.kunEnigoEtikedoj(kradoEnhavo[vicoEro], longo, respId)
        solvoIndekso++
        return { solvoIndekso, demando }
    }

    kunEnigoEtikedoj(teksto, longo, respId) {
        this.demando = true
        let enigaEtikedo = teksto.split("...")
        if (enigaEtikedo[1] === undefined) enigaEtikedo[1] = ""
        let kunEtikedo =
            <div key={respId + "div"} contentEditable={this.admin}>
                <Supersignoj key={respId + "supersigno"} />
                {enigaEtikedo[0]}
                <input type="text" key={respId} id={respId} size={longo} className="skribebla" />
                {enigaEtikedo[1]}
            </div>
        return kunEtikedo
    }
    nombroDeKradoj() {
        let indekso = -1
        let momento = ""
        do {
            indekso++
            momento = this.ScenoObjekto.prenoDeMomento(indekso)[0]
        } while (isNaN(parseInt(momento)))
        return this.ScenoObjekto.prenoDeMomento(indekso).length / 2
    }

}
export default ObjektoKradoj