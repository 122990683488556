import React from 'react'
import { Dropdown } from 'primereact/dropdown';
import './dropdown.css';
//import Datumlego from '../../Datumoj/DatumbazoMastrumilo/NomoTrakto/DatumLego'
import Datumbazo from '../../Datumoj/DatumbazoMastrumilo/DatumbazoInterfaco'

class Elekto extends React.Component {
    constructor(props) {
        super(props)
        this.referenco = this.props.referenco
        this.state = {
            elektoListo: null
        }
    }
    componentDidMount() {
        this.nomoListo()
    }
    nomoListo = () => {
        if (this.props.nomo === "Bildo")
            new Datumbazo().petoDeBildoMapoListo(this.akceptoDeNomoj)
        if (this.props.nomo === "Sono")
            new Datumbazo().petoDeSonoListo(this.akceptoDeNomoj)
    }
    akceptoDeNomoj = nomoj => this.valoroAlElektoListo(nomoj)
    valoroAlElektoListo = (nomoj) => {
        let nomoListo = JSON.parse("[" + nomoj + "]")
        let listo = []
        for (let indekso in nomoListo) {
            let nomo = nomoListo[indekso][this.props.kampo]
            listo[indekso] = { nomo: nomo, code: indekso }
        }
        this.setState({ elektoListo: listo })
    }

    nomoElekto = (evento) => {
        this.referenco(evento.value.nomo)
    }

    render() {

        return <Dropdown value={null}
            options={this.state.elektoListo}
            onChange={this.nomoElekto}
            optionLabel="nomo"
            filter showClear filterBy="nomo"
            placeholder={this.props.nomo + " el la datumbazo"}
        />
    }

}
export default Elekto