import React from 'react'
import $ from 'jquery'
import SuperSignIlo from './SuperSignIlo'

class App extends React.Component {
  constructor() {
    super()
    this.SuperSignIlo = new SuperSignIlo()
    this.tekstaEnigaKampo = NaN
    this.teksto = NaN
    this.indeksoDeLaTraktendaLitero = NaN
  }
  aldonuEventoSpionojn() {
    $(".skribebla").on("keypress", this.klavoPuŝo);
    $(".skribebla").on("focus", this.fokuso);
    $(".skribebla").on("blur", this.blur);
    $(".ĉapeligu").on("click", this.ĉapeligo);
    $(".hoketigu").on("click", this.hoketigo);
  }



  fokuso = evento => this.tekstaEnigaKampo = evento.target
  blur = evento => this.savuKursoranPozicion()
  savuKursoranPozicion = () => this.indeksoDeLaTraktendaLitero = this.tekstaEnigaKampo.selectionStart - 1

  hoketigo = evento => {
    if (this.tekstaEnigaKampo.type === undefined) return
    this.teksto = this.SuperSignIlo.hoketigo(this.tekstaEnigaKampo.value, this.indeksoDeLaTraktendaLitero)
    this.reStarigo()
  }

  ĉapeligo = evento => {
    if (this.tekstaEnigaKampo.type === undefined) return
    this.teksto = this.SuperSignIlo.ĉapeligo(this.tekstaEnigaKampo.value, this.indeksoDeLaTraktendaLitero)
    this.reStarigo()
  }

  elektuLaTraktendanLiteron = () => this.teksto.charAt(this.indeksoDeLaTraktendaLitero)

  klavoPuŝo = evento => {
    let supersignoKlavoj = "xX"
    let puŝita_litero = String.fromCharCode(evento.keyCode)
    if (supersignoKlavoj.includes(puŝita_litero)) {
      this.savuKursoranPozicion()
      let indekso = this.indeksoDeLaTraktendaLitero
      let teksto = evento.target.value
      if (!this.SuperSignIlo.ĉuSupersignebla(teksto, indekso)) return
      evento.preventDefault();
      this.superSignigo(teksto, indekso)
    }
  }

  superSignigo(teksto, indekso) {
    this.teksto = this.SuperSignIlo.superSignigo(teksto, indekso)
    this.reStarigo()
  }

  reStarigo() {
    this.tekstaEnigaKampo.value = this.teksto
    this.tekstaEnigaKampo.selectionEnd = this.indeksoDeLaTraktendaLitero + 1
    this.tekstaEnigaKampo.focus()
    this.indeksoDeLaTraktendaLiteroo = NaN
  }

}

export default App
