import React from 'react';
import { Panel } from 'primereact/panel';
import "./grid.css"
function Dezajno(props) {
    const enhavo = [...props.enhavo]
    let border = props.border
    
    return (
        <Panel id="bazo" header={props.header} toggleable
            className=" p-flex-column " >
            <div className="p0  p-grid  nested-grid ">
                <div className="p-col-3 p-md-2" >
                    <div className="p p-grid p-dir-col p-justify-center">
                        <div className="p30 p-col-12 " style={{ border: border }}>
                            {enhavo[0]}
                        </div>
                    </div>
                </div>

                <div className="p-col-5  p-md-3 ">
                    <div className="p p-grid p-dir-col p-justify-center">
                        <div className="p20 p-col-12 ronda" style={{ border: border }}>
                            {enhavo[7]}
                        </div>
                        <div className="p25 p-col-4 ronda " style={{ border: border }}>
                            {enhavo[1]}
                        </div>
                        <div className="p25 p-col-8 p-offset-4" style={{ border: border }}>
                            {enhavo[4]}
                        </div>
                    </div>
                </div>
                <div className="p p-col-4  p-md-3">
                    <div className="p p-grid p-dir-col p-justify-center" >
                        <div className="p30 p-col-10 p-offset-1" style={{ border: border }}>
                            {enhavo[2]}
                        </div>
                        <div className="p20 p-col-8 p-offset-2" style={{ border: border }}>
                            {enhavo[3]}
                        </div>
                    </div>
                </div>

                <div className="p p-col-4 p-d-none p-d-md-block">
                    <div className="p p-grid p-dir-col p-justify-center" >
                        <div className="p30 p-col-8  p-offset-2" style={{ border: border }}>
                            {enhavo[5]}
                        </div>
                        <div className="p30 p-col-8  p-offset-2" style={{ border: border }}>
                            {enhavo[6]}
                        </div>
                    </div>
                </div>
            </div >

            <div key="animacia-krado" id="animacia-krado" />
        </Panel >)
}
export default Dezajno