//import Butono from './KlakoButono'
import { Button as Butono } from 'primereact/button'
import { PrimeIcons } from 'primereact/api';
//import Navigilo from './Navigilo'
import Navigilo from '../Navigilo/Navigilo'
import './navigilo.css'
import 'primeflex/primeflex.css';
class NavigiloKradoKreilo {

    enhavo(aktualaMomento, nombroDeMomentoj, referenco) {

        let enhavo = <div key="navigilo-krado" id="navigilo-krado" className="malsupra-entenilo">
            <div className="navigado-butonoj">

                <Butono
                    icon={PrimeIcons.CARET_LEFT}
                    onClick={()=>referenco("reen")}
                    label="Al la antaŭa momento"
                    className="p-button-rounded p-button-info p-mx-6"
                    style={{ "fontSize": "1.5em" }} />
                <Butono
                    icon={PrimeIcons.CARET_RIGHT}
                    iconPos="right"
                    onClick={()=>referenco("pluen")}
                    label="Al la sekva momento"
                    className="p-button-rounded p-button-info p-mx-6"
                    style={{ "fontSize": "1.5em" }} />
                <div></div>
                <Butono
                    icon={PrimeIcons.FORWARD}
                    iconPos="right"
                    onClick={()=>referenco("neprePluen")}
                    label="Nepre pluen"
                    className="p-button-rounded p-button-secondary p-mx-6"
                    style={{ "fontSize": "1.5rem" }} />
            </div>
            <div key="navigilo" id="navigilo"
                className="p-m-2"
                onClick={(evento) => { referenco(evento) }}>
                <Navigilo
                    totalaNombro={nombroDeMomentoj}
                    aktuala={aktualaMomento}
                />
            </div>
        </div>
        return enhavo
    }
}

export default NavigiloKradoKreilo