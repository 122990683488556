

class BildoDatumo {
    constructor() {
        this.bildoId= ""
        this.bildoNomo = ""
        this.bildoLoko = ""
        this.mimeTipo = ""
        this.surSkriboj = []
    }
   
    prenoDeBildoId = () => this.bildoId
    prenoDeBildoNomo = () => this.bildoNomo
    prenoDeBildoLoko = () => this.bildoLoko
    prenoDeMimeTipo = () => this.mimeTipo
    prenoDeSurskriboj = () => this.surSkriboj

    valoroAlBildoId = (bildoId) => this.bildoId = bildoId
    valoroAlBildoNomo = (bildoNomo) => this.bildoNomo = bildoNomo
    valoroAlBildoLoko = (bildoLoko) => this.bildoLoko = bildoLoko
    valoroAlMimeTipo = (mimeTipo) => this.mimeTipo = mimeTipo
    valoroAlSurskriboj = (surSkriboj) => this.surSkriboj = surSkriboj

}
export default BildoDatumo