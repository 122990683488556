import DatumoObjekto from "./DatumObjekto"

class KlapoKlakoKreilo {

  kreuScenon(datumo) {

    let DatumObjekto = new DatumoObjekto()
    DatumObjekto.valoroAlTeksto(datumo.teksto)
    return DatumObjekto
  }

}

export default KlapoKlakoKreilo