
import { ReactFitty } from "react-fitty";
import 'primeflex/primeflex.css';
class TekstoKradoKreilo {

    enhavo(teksto, demandoj) {
        if (teksto + demandoj === "")
            return <div > </div>

        let tekstoDiv = <ReactFitty 
            className="teksto-enhavo"
            wrapText="true"
            maxSize="42"
            minSize="15"
            >
            <div className="p-shadow-10"
                contentEditable="false"
                dangerouslySetInnerHTML={{ __html: teksto }}
            />
            {demandoj}
        </ReactFitty>
        let enhavo = tekstoDiv
         
        return enhavo
    }
}

export default TekstoKradoKreilo