import React, { Component } from 'react'
import BildoTabulo from './AdminKomponantoj/BildoTrakto/BildoTabulo'
import BildoMapoTabulo from './AdminKomponantoj/MapoTabulo'
import { Button } from 'primereact/button'
import ForigButono from "./AdminKomponantoj/ForigButono"
import { SplitButton } from 'primereact/splitbutton';
import { Toast } from 'primereact/toast';

import 'primeicons/primeicons.css'
import 'primereact/resources/themes/saga-green/theme.css'
import 'primereact/resources/primereact.css'
import 'primeflex/primeflex.css'
import './datatable.css'

import Elekto from './AdminKomponantoj/Elekto'
import Datumbazo from '../Datumoj/DatumbazoMastrumilo/DatumbazoInterfaco';
import FajloAlŝuto from './AdminKomponantoj/FajloAlshuto/FajloAlshuto'
import SurlokaTekstoEnigo from '../Utilajhoj/SurlokaTekstoEnigo';
import SavoStatoModifo from './AdminKomponantoj/SavoStatoModifo'

class BildoMapoAdministrado extends Component {

    constructor(props) {
        super(props);
        this.savoStato = new SavoStatoModifo("bildo").prenoDeSavoStato()
        this.referenco = this.akceptoDeSavoRezulto
        this.bildoNomo = ""
        this.bildoŜanĝo = false
        this.elektitaVico = null
        this.savoTipoj = this.savotipoj()
        if (this.props.state === "")
            this.state = {
                bildoId: null,
                bildoNomo: null,
                bildoMapoId: null,
                bildoMapoNomo: "",
                mimeTipo: "",
                bildo: "",
                mimeTipo2: "",
                bildo2: "",
                bildoMapo: { teksto: "???", supraX: "0", supraY: "0", subaX: "0", subaY: "0" },
                savoStato: this.savoStato.nesavinda,
                editingRows: {},
                selected: null,
                freŝigoDeElektoListo: false
            }
        else this.state = this.props.state

    }

    componentWillUnmount() {
        this.props.referenco(this.state)
    }

    render() {
        return (
            <div className="datatable">
                <Toast ref={(el) => this.toast = el} />
                <BildoTabulo
                    bildo={this.state.bildo}
                    bildo2={this.state.bildo2}
                    bildoMapo={this.state.bildoMapo} />
                {this.agadStrio()}
                <div className="bildomapo-tabulo">
                    <table><tbody><tr>
                        <td style={{ verticalAlign: "top" }}>
                            <b>Bildomapo de la bildo :</b>
                        </td>
                        <td>
                            <SurlokaTekstoEnigo teksto={this.state.bildoMapoNomo}
                                referenco={this.novaBildoNomo} />
                        </td>
                    </tr></tbody></table>

                    <BildoMapoTabulo
                        tipo="bildo"
                        tabulEnhavo={this.state.bildoMapo}
                        referenco={this.mapoStatoModifita} />

                    <Button id="savo" label="Nova vico" icon="pi pi-plus"
                        className="p-button-rounded p-button-primary p-m-6"
                        onClick={() => this.novaVico()} />
                    <ForigButono
                        mesaĝo='Ĉu vi vere volas forigi el la datumbazo tiun ĉi bildomapon?'
                        butonoSurskribo="Forĵeto de la bildomapo"
                        akcepto={this.forigoDeLaBildoMapo}
                    />


                    <br /><br />
                    {JSON.stringify(this.state)}

                </div>
            </div>
        );
    }

    forigoDeLaBildoMapo = () => {
        new Datumbazo().forviŝuBildoMapon(this.state.bildoMapoId, this.referenco)
    }

    agadStrio() {
        return <table style={{ width: '60%' }}>
            <tbody>
                <tr style={{ height: '3em' }}>
                    <td>
                        <FajloAlŝuto referenco={this.akceptoDeNovaBildo}
                            label="Bildoalŝuto" fajloTipo="image/*" />
                    </td>
                    <td>
                        <Elekto
                            referenco={this.akceptoDeBildoMapoNomo}
                            nomo="Bildo" kampo="bildoMapoNomo"
                            freŝigo={this.state.freŝigoDeBildoElektoListo} />
                    </td>
                    <td style={{ width: '50%', textAlign: "center" }}>
                        <SplitButton
                            className="p-button-secondary p-mx-6"
                            label="Savo" icon="pi pi-save"
                            model={this.savoTipoj} />
                        <br />
                        {this.state.savoStato}
                    </td>

                    <td>
                        <FajloAlŝuto referenco={this.akceptoDeNovaBildo2}
                            label="Bildoalŝuto por ŝanĝi la originan bildon"
                            fajloTipo="image/*" />
                    </td>
                    <td>
                        <Button id="novaMapo"
                            className="p-button-info p-mx-6"
                            label="Interŝanĝu la bildojn" icon="pi-arrows-h"
                            onClick={this.interŜanĝu} /><br />
                    </td>
                </tr>
            </tbody>
        </table>
    }
    savotipoj = () => {
        return [
            {
                label: 'Savo de la ŝanĝoj',
                icon: 'pi pi-pencil',
                command: () => {
                    this.savuŜanĝojn()
                }
            },
            {
                label: 'Savu kiel nova bildomapo',
                icon: 'pi pi-plus-circle',
                command: () => {
                    this.savuŜanĝojn("nova")
                }
            }]
    }
    save = () => {
        this.toast.show({ severity: 'success', summary: 'Success', detail: 'Data Saved' });
    }
    //////////////////////Datumbazokontakto:///////////////////////////////////////////////
    akceptoDeBildoMapoNomo = bildoMapoNomo => this.bildoLego(bildoMapoNomo)
    bildoLego = (bildoMapoNomo) => {
        console.log(bildoMapoNomo)
        new Datumbazo().legoDeBildojKunMapo(
            [bildoMapoNomo],
            this.akceptoDeDatumaro
        )
    }

    akceptoDeDatumaro = datumaro => this.datumaro(datumaro)
    datumaro = (datumaro) => {
        console.log(this.state)
        console.log(datumaro.bildoListo[0])
        let datumoj = datumaro.datumoj[0]
        this.bildoŜanĝo = false
        this.setState({
            bildoId: datumoj.bildoId,
            bildoNomo: datumoj.bildoNomo,
            bildoMapoId: datumoj.bildoMapoId,
            bildoMapoNomo: datumoj.bildoMapoNomo,
            bildo: datumaro.bildoListo[0],
            mimeTipo: datumoj.mimeTipo,
            bildoMapo: JSON.parse(datumoj.bildoMapo),
            savoStato: this.savoStato.nesavinda,
            freŝigoDeBildoElektoListo: true
        })
    }

    akceptoDeNovaBildo = novaBildo => this.novaBildo(novaBildo)
    akceptoDeNovaBildo2 = novaBildo => this.setState({
        mimeTipo2: novaBildo.type,
        bildo2: novaBildo.objectURL
    })
    novaBildo = (novaBildo) => {
        let bildoMapoNomo = novaBildo.name.split(".")[0]
        bildoMapoNomo = bildoMapoNomo.charAt(0).toUpperCase() + bildoMapoNomo.slice(1)
        this.bildoŜanĝo = false
        this.setState({
            bildoId: null,
            bildoMapoId: null,
            bildoNomo: bildoMapoNomo,
            bildoMapoNomo: bildoMapoNomo,
            mimeTipo: novaBildo.type,
            bildo: novaBildo.objectURL,
            bildoMapo: [{ teksto: bildoMapoNomo, supraX: 0, supraY: 0, subaX: 100, subaY: 100 }],
            savoStato: this.savoStato.novaBildo
        })
    }
    interŜanĝu = () => {
        let bildo = this.state.bildo
        let bildo2 = this.state.bildo2
        let mimeTipo = this.state.mimeTipo
        let mimeTipo2 = this.state.mimeTipo2
        this.bildoŜanĝo = !this.bildoŜanĝo
        let ŝanĝoStato = "La bildo estas ŝanĝita"
        let stato
        if (this.bildoŜanĝo) {
            this.statoAntaŭŜanĝo = this.state.savoStato
            stato = ŝanĝoStato

        } else {
            if (this.state.savoStato !== ŝanĝoStato)
                this.statoAntaŭŜanĝo = this.state.savoStato
            stato = this.statoAntaŭŜanĝo
        }
        this.setState({
            mimeTipo: mimeTipo2,
            mimeTipo2: mimeTipo,
            bildo: bildo2,
            bildo2: bildo,
            savoStato: stato
        })
    }

    savuŜanĝojn = (nova) => {
        console.log(nova)
        if (this.state.savoStato === this.savoStato.nesavinda) return
        if (this.state.savoStato === this.savoStato.savita) return
        let datumoj = {
            bildoMapoId: this.state.bildoMapoId,
            bildoMapoNomo: this.state.bildoMapoNomo,
            bildo: this.state.bildo,
            mimeTipo: this.state.mimeTipo,
            bildoMapo: this.state.bildoMapo,
            bildoNomo: this.state.bildoNomo,
            bildoId: this.state.bildoId,
            bildoŜanĝo: this.bildoŜanĝo
        }
        if (nova === "nova") datumoj.bildoMapoId = null
        new Datumbazo().savoDeBildoMapo(datumoj, this.referenco)
    }

    akceptoDeSavoRezulto = (rezulto) => {
        console.log(rezulto)
        if (rezulto.includes("Eraro"))
            this.toast.show({
                severity: 'error',
                summary: "malsukcesis",
                detail: rezulto, life: 10000
            })
        else
            this.toast.show({
                severity: 'success',
                summary: "sukcesis??",
                detail: rezulto, life: 10000
            })
        if (rezulto.includes("Eraro"))
            this.setState({
                savoStato: rezulto
            })
        else this.setState({
            savoStato: this.savoStato.savita
        })
    }

    novaBildoNomo = (bildoMapoNomo) => {
        this.setState({
            bildoMapoNomo: bildoMapoNomo,
            savoStato: this.savoStato.modifita
        })
    }

    mapoStatoModifita = (bildoMapo) => {
        if (bildoMapo.nova !== undefined)
            bildoMapo = bildoMapo.nova
        this.setState({
            bildoMapo: bildoMapo,
            savoStato: this.savoStato.modifita
        })
    }

    novaVico = () => {
        let teksto = { teksto: "???", supraX: "0", supraY: "0", subaX: "0", subaY: "0" }
        let nova = [...this.state.bildoMapo]
        nova.unshift(teksto)
        this.setState({
            bildoMapo: nova,
            savoStato: "Redaktu la novan vicon!"
        })
    }

}
export default BildoMapoAdministrado