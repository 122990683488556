import React from 'react'
import ScenoKreilo from "./BazaScenoKreilo"
import ScenoStarto from './ScenoStarto'
import RipetoTraktilo from './RipetoTraktilo'
import Datumbazo from '../../../Datumoj/DatumbazoMastrumilo/DatumbazoInterfaco'
import { Toast } from 'primereact/toast';

class ScenoPreparo extends React.Component {
    constructor(props) {
        super(props)
        this.scenoStato = this.props.scenoStato
        this.scenoDifino = this.scenoStato.prenoDeScenoDifino()
        if (typeof this.scenoDifino === "string") {
            this.sceno = JSON.parse(this.scenoDifino)
        }
        else this.sceno = this.scenoDifino
        this.state = {
            objekto: <div />
        }
    }

    componentDidMount() {
        if (typeof this.scenoDifino === "string") {
            let bildoNomoListo = this.kreuBildoNomoListon()
            let referenco = this.akceptoDeBildoDatumoj
            new Datumbazo().legoDeBildojKunMapo(bildoNomoListo, referenco)
        }
        else if (this.state)
            this.setState(
                { objekto: <ScenoStarto scenoStato={this.scenoStato} sono={this.props.sono} /> }
            )
    }

    render = () => {
        let html = <div/>
        if (typeof this.scenoStato.prenoDeScenoDifino() !== "string")
            html = <div>
            <Toast ref={(el) => this.toast = el} />
             <ScenoStarto scenoStato={this.scenoStato} sono={this.props.sono} />
             </div>
        return (
            html
        )
    }

    akceptoDeBildoDatumoj = (bildojDao) => this.bildojDaoAlPrg(bildojDao)
    bildojDaoAlPrg(bildojDao) {
       let bildoListo = []

      if  (typeof bildojDao.datumoj === "string")
        this.eraroMesaĝo(bildojDao.datumoj)
        for (let indekso in bildojDao.bildoListo) {
            bildoListo[indekso] = {
                "bildoId": bildojDao.datumoj[indekso].bildoId,
                "bildoNomo": bildojDao.datumoj[indekso].bildoNomo,
                "mimeTipo": bildojDao.datumoj[indekso].mimeTipo,
                "bildoLoko": bildojDao.bildoListo[indekso],
                "surskribo": bildojDao.datumoj[indekso].bildoMapo
            }
        }
        this.objektoKreo(bildoListo)
    }
    eraroMesaĝo(rezulto){
             this.toast.show({
                 severity: 'error',
                 summary: "malsukcesis",
                 detail: rezulto, life: 10000
             })
       }
    objektoKreo = (bildoListo) => {
        let ScenoObjekto = new ScenoKreilo().kreuScenon(this.sceno, bildoListo)
        this.scenoStato.valoroAlScenoDifino(ScenoObjekto)
        this.scenoStato.valoroAlMomentoIndekso(0)
        if (this.state)
            this.setState(
                { objekto: <ScenoStarto scenoStato={this.scenoStato} sono={this.props.sono} /> }
            )
    }

    kreuObjektoNomoListon(objektoTipo) {
        let objektoNomoListo = []
        let novaListo = []
        let ripetoTraktilo = new RipetoTraktilo()
        let momentoj = this.sceno["momentoj"]
        for (let indekso in momentoj) {
            novaListo = this.objektoNomoj(momentoj[indekso], objektoTipo)
            if (momentoj[indekso][0] === "ripetoj") {
                novaListo = ripetoTraktilo.perfortaŜanĝo(
                    objektoNomoListo, momentoj[indekso][1])
            }
            for (let i in novaListo)
                if (!objektoNomoListo.includes(novaListo[i]))
                    objektoNomoListo.push(novaListo[i])
        }
        return objektoNomoListo
    }
    objektoNomoj(momento, objektoTipo){
        let objektoNomoj=[]
        for (let indekso=1;indekso<momento.length;indekso+=2)
            if (momento[indekso].slice(0,2) === objektoTipo){
                objektoNomoj.push(momento[indekso].slice(2))
            }
        return objektoNomoj
    }
    kreuSonoNomoListon() {
        return this.kreuObjektoNomoListon("s:")
    }
    kreuBildoNomoListon() {
        return this.kreuObjektoNomoListon("b:")
    }
}

export default ScenoPreparo