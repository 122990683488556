import DatumoObjekto from "./DatumObjekto"

class ElektoScenoKreilo {

  kreuScenon(datumo) {

    let DatumObjekto = new DatumoObjekto()
    let scenoTeksto = ""
    for (let indekso in datumo["teksto"]) scenoTeksto += datumo["teksto"][indekso]
    DatumObjekto.valoroAlTeksto(scenoTeksto)
    DatumObjekto.valoroAlOpcioj(datumo["opcioj"])
    DatumObjekto.valoroAlDividilo(datumo["dividilo"])
    return DatumObjekto
  }

}

export default ElektoScenoKreilo