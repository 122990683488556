import Konstantoj from './Konstantoj'

class SuperSignIlo {
    constructor() {
        let konstantoj = new Konstantoj()
        this.ĉapeligeblaj = konstantoj.prenoDeĈapeligeblaj()
        this.ĉapelitaj = konstantoj.prenoDeĈapelitaj()
        this.hoketigeblaj = konstantoj.prenoDeHoketigeblaj()
        this.hoketitaj = konstantoj.prenoDeHoketitaj()
    }

    ĉapeligo(teksto, indekso) {
        if (!this.ĉuĈapeligEbla(teksto.charAt(indekso))) return teksto
        let litero = this.ĉapelIgu(teksto.charAt(indekso))
        return this.ŝanĝuLiteron(teksto, indekso, litero)
    }

    hoketigo = (teksto, indekso) => {
        if (!this.ĉuHoketigEbla(teksto.charAt(indekso))) return teksto
        let litero = this.hoketIgu(teksto.charAt(indekso))
        return this.ŝanĝuLiteron(teksto, indekso, litero)
    }

    superSignigo = (teksto, indekso) => {
        if (!this.ĉuSupersignebla(teksto.charAt(indekso))) return teksto
        let litero = this.superSignu(teksto.charAt(indekso))
        return this.ŝanĝuLiteron(teksto, indekso, litero)
    }

    ŝanĝuLiteron = (teksto, indekso, litero) => teksto = teksto.slice(0, indekso) + litero + teksto.slice(indekso + 1)

    ĉuHoketigEbla = litero => "uUŭŬ".includes(litero)
    ĉuĈapeligEbla = litero => "sgcjhSGCJHŝĝĉĵĥŜĜĈĴĤ".includes(litero)
    ĉuSupersignebla(teksto, indekso) {
        let litero = teksto.charAt(indekso)
        return this.ĉuĈapeligEbla(litero) || this.ĉuHoketigEbla(litero)
    }

    superSignu(litero) {
        if (this.ĉuHoketigEbla(litero)) return this.hoketIgu(litero); else return this.ĉapelIgu(litero)
    }
    ĉapelIgu = litero => {
        for (let i = 0; i < 12; i++) {
            if (litero === this.ĉapeligeblaj[i]) {
                return this.ĉapelitaj[i];
            }
            if (litero === this.ĉapelitaj[i]) {
                return this.ĉapeligeblaj[i]
            }
        }
        return litero;
    }
    hoketIgu = litero => {
        switch (litero) {
            case "u": return "ŭ"
            case "U": return "Ŭ"
            case "ŭ": return "u"
            case "Ŭ": return "U"
            default: return litero
        }
    }

}
export default SuperSignIlo
