class SavoStatoModifo {
    constructor(kio) {
        this.savoStato = {            
            novaVico: "Redaktu la novan vicon!",
            nesavinda: "Ne okazis ŝanĝoj.",
            modifita: "La modifoj ankoraŭ ne estas savitaj.",
            savita: "Savita",
            freŝigoDeElektoListo: false
        }
    }
    prenoDeSavoStato = () => this.savoStato
    ŝanĝoDeSavoStato(savoStato,aktualaStato) {
        let novaSavoStato = aktualaStato
        switch (savoStato) {
            
            case this.savoStato.modifita:
                if ([
                    this.savoStato.savita,
                    this.savoStato.nesavinda,
                    this.savoStato.novaVico
                ].indexOf(aktualaStato) > -1
                ) novaSavoStato = savoStato
                break
            default:
        }
        return novaSavoStato
    }}
    export default SavoStatoModifo