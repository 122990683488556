class ScenoObjekto {

    constructor() {
        this.scenoTipo=1
        this.id = ""
        this.dezajnTipoj = []
        this.bildoj = []
        this.bildoNomoj = []
        this.sonoj = []
        this.momentoj = []
    }
    prenoDeScenoTipo = () => this.scenoTipo
    prenoDeId = () => this.id
    prenoDeDezajnTipoj = () => this.dezajnTipoj
    prenoDeBildoj = () => this.bildoj
    prenoDeBildoNomoj = () => this.bildoNomoj

    prenoDeSonoj = () => this.sonoj
    prenoDeMomentoj = () => this.momentoj

    prenoDeBildoNomo = (indekso) => this.bildoNomoj[indekso]
    prenoDeBildoLoko = (indekso) => this.bildoj[indekso].prenoDeBildoLoko()
    prenoDeMomento = (indekso) => this.momentoj[indekso]
    prenoDeMomentaDezajnTipo = (momento) => this.dezajnTipo[momento]

    valoroAlScenoTipo = (scenoTipo) => this.scenoTipo = scenoTipo
    valoroAlId = (id) => this.id = id
    valoroAlDezajnTipoj = (dezajnTipoj) => this.dezajnTipo = dezajnTipoj
    valoroAlBildoj = (bildoj) => this.bildoj = bildoj
    valoroAlBildoNomoj = (bildoNomoj) => this.bildoNomoj = bildoNomoj
    valoroAlSonoj = (sonoj) => this.sonoj = sonoj
    valoroAlMomentoj = (momentoj) => this.momentoj = momentoj

    valoroAlBildo = (indekso, bildo) => this.bildoj[indekso] = bildo



}


export default ScenoObjekto