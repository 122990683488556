import React from 'react';
import EventoSpionoj from './EventoSpionoj'

class Supersignoj extends React.Component {


    componentDidMount() {
        new EventoSpionoj().aldonuEventoSpionojn()
    }


    render() {
        return (
            <div className="superSignu">
                <label >Ĉapeligu aŭ hoketigu! </label>
                <button type="button" className="ĉapeligu">^</button>
                <button type="button" className="hoketigu">˘</button>

            </div>
        )
    }


}


export default Supersignoj