import sha256 from 'crypto-js/sha256';

class Kashvorto {

    constructor(){
        this.kaŝvorto = {
            sigBytes: 32,
            words: 
            [244887637, -1196459887, -1796413102, 1638530748, -1430437996, -1246027874, 738230730, 961608693]
        }
    }  
 
    egala(kaŝvorto){          
        kaŝvorto = sha256(kaŝvorto) 
        let egala = true
        if (this.kaŝvorto.sigBytes !== kaŝvorto.sigBytes) 
            egala = false
        for (let i = 0; i<8;i++) 
            if (this.kaŝvorto.words[i] !== kaŝvorto.words[i])
                egala = false
        
        return egala
    }
}

export default Kashvorto
    