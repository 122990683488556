class ScenoStato {
     constructor() {
        this.scenoId = 1
        this.scenoDifino = null
        this.momentoIndekso = 0
        this.sono = false
    }
    prenoDeScenoId = () => this.scenoId
    prenoDeScenoDifino = () => this.scenoDifino
    prenoDeMomentoIndekso = () => this.momentoIndekso
    prenoDeSono = () => this.sono



    valoroAlScenoId = (scenoId) => this.scenoId = scenoId
    valoroAlScenoDifino = (scenoDifino) => this.scenoDifino = scenoDifino
    valoroAlMomentoIndekso = (momentoIndekso) => this.momentoIndekso = momentoIndekso
    valoroAlSono = (sono) => this.sono = sono



}


export default ScenoStato