import React, { Component } from 'react';
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import { InputText } from 'primereact/inputtext';

class SurlokaTekstoEnigo extends Component {
    constructor(props) {
        super(props);
        this.referenco = this.props.referenco
        this.teksto0 = this.props.teksto
       this.state = {
            teksto: this.teksto0
        }
    }
    onChange = (e) => {
        this.setState({ teksto: e.target.value })
        this.referenco(e.target.value)
    }
    teksto = () => {
  
        if (this.teksto0 !== this.props.teksto) {
            this.teksto0 = this.props.teksto
            this.setState({
                teksto:this.teksto0
            })
            return this.teksto0 
        }
        return this.state.teksto 
    }
    render() {
        return (
            <div className="card">
                <Inplace closable>
                    <InplaceDisplay style={{ fontsize: 3 }}>
                        <b>
                            {this.teksto()}</b>

                    </InplaceDisplay>
                    <InplaceContent>
                        <InputText value={this.state.teksto || "" }
                            onChange={(e)=>this.onChange(e)} autoFocus />
                    </InplaceContent>
                </Inplace>

            </div>
        )
    }
}
export default SurlokaTekstoEnigo