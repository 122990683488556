
import Datumbazo from '../DatumbazoInterfaco'


class SonoMapoSavo {
    constructor(datumoj, referenco) {
        this.referenco = referenco
        this.datumoj = datumoj
        this.savuSonoMapon()
    }

    savuSonoMapon() {
        let originaMapo = this.datumoj.originaSonoMapo
        let novaMapo = this.datumoj.sonoMapo
        let forigindajVicoj = this.minusDuaFromUnua(originaMapo, novaMapo, ["paroloId"])
        let savendaj = this.minusDuaFromUnua(
            novaMapo, originaMapo,
            ["paroloId", "sonoId", "paroloNomo", "ekde", "ĝis"]
        )
        let { savendajVicoj, novajVicoj } = this.disigoDeNovaj(savendaj)
     
        new Datumbazo().simplajDatumoTraktoj(
            "paroloj",
            savendajVicoj,
            forigindajVicoj,
            novajVicoj,
            this.referenco
        )

    }
    minusDuaFromUnua(unua, dua, kolumnoj) {
        let diferenco = []
        for (let indekso in unua) {
            let unu = unua[indekso]
            let egala = false
            for (let indekso in dua)
                if (this.egala(unu, dua[indekso], kolumnoj))
                    egala = true
            if (!egala) {
                diferenco.push(this.getPeto(unua[indekso], kolumnoj))
            }
        }
        return diferenco
    }
    egala(unua, dua, kolumnoj) {
        for (let indekso in kolumnoj) {
            let kolumno = kolumnoj[indekso]
            if (unua[kolumno] !== dua[kolumno])
                return false
        }
        return true
    }
    getPeto(vico, kolumnoj) {
        let getPeto
        if (kolumnoj.length === 1)
            getPeto = this.getPetoPorDELETE(vico, kolumnoj)
        else if (vico.paroloId === undefined)
            getPeto = this.getPetoPorINSERT(vico, kolumnoj)
        else getPeto = this.getPetoPorUPDATE(vico, kolumnoj)
        return getPeto
    }
    getPetoPorDELETE(vico, kolumnoj) {
       // paroloId,8;9
       //DELETE FROM paroloj WHERE paroloId in (8,9)
       
        let getPeto =  kolumnoj + ","
        let dividilo = ""
        for (let indekso in kolumnoj)
            if (vico[kolumnoj[indekso]] !== undefined) {
                getPeto += dividilo + vico[kolumnoj[indekso]]
                dividilo = ";"
            }

        return getPeto
    }
    getPetoPorINSERT(vico, kolumnoj) {
        //  INSERT INTO tabulo (kolumn1,kolumn2,...) VALUES (valoro1,valoro2,...);
        console.log([vico,kolumnoj])
        let kolumnoListo = ""
        let valoroListo= ""
        let dividilo = ""
        for (let indekso in kolumnoj)
            if (kolumnoj[indekso] !== "paroloId"){
                kolumnoListo+=dividilo+kolumnoj[indekso]
                valoroListo+=dividilo+"'"+vico[kolumnoj[indekso]]+"'"
                dividilo=";"}
        return kolumnoListo+","+valoroListo
    }
    getPetoPorUPDATE(vico, kolumnoj) {
        // UPDATE tabulo kolumn1 = valoro1, kolumn2 = valoro2, ...WHERE id=idValoro;
        //?valorParo=sonoId=6,paroloNomo=Mi ĝojas vidi vin.,ekde=10,ĝis=45,kondicho[]=paroloId=5
        let getPeto = ""
        let dividilo = ""
        let idValoro = ""
        for (let indekso in kolumnoj)
            if (kolumnoj[indekso] !== "paroloId") {
                getPeto += dividilo + "'"+kolumnoj[indekso] + "'='" + vico[kolumnoj[indekso]]+"'"
                dividilo = ";"
            } else idValoro = vico[kolumnoj[indekso]]
        getPeto += ",paroloId=" + idValoro

        return getPeto
    }
    disigoDeNovaj(savendaj) {
        //Ankaŭ la savendajVicoj ŝanĝiĝas ĉar objekto
        let novajVicoj = []
        let savendajVicoj = []
        for (let indekso in savendaj)
            if (savendaj[indekso].includes("paroloId="))
            savendajVicoj.push(savendaj[indekso])
            else novajVicoj.push(savendaj[indekso])
        return { savendajVicoj, novajVicoj }
    }
}
export default SonoMapoSavo