import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import Salutpaĝo from './Salutpagho/Salutpagho';
import Lecionopaĝo from '../Kurso/Leciono/Lecionoj';
import Administrado from '../Administrado/AdministradoStarto'

function Paĝoj() {
	let komenco = Salutpaĝo
	if (window.location.href.slice(-12) === "administrejo")
		komenco = Administrado

	return (
		<BrowserRouter>
			<Route path="/" exact component={komenco} />
			<Route path="/lecionopaĝo" exact component={Lecionopaĝo} />
			<Route path="/administrejo" exact component={Administrado} />
			<Route path="/komenco" exact component={Lecionopaĝo} />
		</BrowserRouter>
	);
}
window.onbeforeunload = (event) => {
	const e = event || window.event;
	e.preventDefault();
	window.location.reload()
}

export default Paĝoj;