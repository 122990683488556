import $ from 'jquery';
import api from "../api.json"

class BildoSavo {
  constructor() {
    this.bildoNomo = ""
    this.bildoObjektURL = ""
    this.mimetipo = ""
    this.bildoMapo = ""
    this.bildoMapoNomo = ""
    this.referenco = ""
    this.msg = ""
  }

  savuBildon = (datumoj, referenco) => {
    this.id=datumoj.bildoId
    this.bildoMapoNomo = datumoj.bildoMapoNomo
    this.bildoObjektURL = datumoj.bildo
    this.mimeTipo = datumoj.mimeTipo
    this.bildoMapo = JSON.stringify(datumoj.bildoMapo)
    this.bildoNomo = datumoj.bildoNomo
    this.referenco = referenco
    this.urlAlBlob(this.bildoObjektURL, this.akceptoDeBlob)
  }

  urlAlBlob = (objektoURL, referenco) => {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', objektoURL, true);
    xhr.responseType = 'blob';
    xhr.onload = function (e) {
      if (this.status === 200) {
        let blob = this.response
        referenco(blob);
      }
    };
    xhr.send();
  }

  akceptoDeBlob = blob => this.savoDeBildo(blob)
  savoDeBildo = (blob) => {
    let apiURL = api.urlBazo + api.novaBildoSavo
    var fd = new FormData();
    if (this.id !== null){ 
      fd.append('bildoId', this.id);
      apiURL = api.urlBazo + api.bildoSavo
    }
    fd.append('bildoNomo', this.bildoNomo);
    fd.append('bildo', blob);
    fd.append('mimeTipo', this.mimeTipo);
    $.ajax({
      type: 'POST',
      url: apiURL,
      data: fd,
      processData: false,
      contentType: false,
      success:  (msg) => {
        this.akceptoDeBildoSavo(msg)
      }
    })
  }
  
  akceptoDeBildoSavo = (msg) => {
    msg=JSON.parse(msg)
   this.referenco(msg.bildoId)
  }
}
export default BildoSavo