import GramatikajElementoj from './GramatikajElementoj'
import Konstantoj from './Konstantoj'



class EmfazIlo {
    constructor() {
        this.KiaGramatikaĵo = new GramatikajElementoj()
        let konstantoj = new Konstantoj()
        this.sufiksoStilo = "<span style=" + konstantoj.prenoDeSufisksoStilo() + ">"
        this.finaĵoStilo = "<span style=" + konstantoj.prenoDeFinaĵoStilo() + ">"
        this.fermoDeElemento = "</span>"

    }
    vortoListo = teksto => teksto.split(" ")
    vicoListo = teksto => teksto.split("\n")
    tekstoAnalizo(teksto) {
        var vicoj = this.vicoListo(teksto)
        for (let vicIndekso = 0; vicIndekso < vicoj.length; vicIndekso++) {
            var vortoj = this.vortoListo(vicoj[vicIndekso])
            for (let indekso = 0; indekso < vortoj.length; indekso++) {
                vortoj[indekso] = this.vortAnalizo(vortoj[indekso])
            }
            let vico = ""
            for (let indekso = 0; indekso < vortoj.length; indekso++) {
                vico += vortoj[indekso] + " "
            }
            vicoj[vicIndekso] = vico
        }
        teksto = ""
        for (let indekso = 0; indekso < vicoj.length; indekso++) {
            teksto += vicoj[indekso] + "<br/>"
        }
        return teksto
    }
    vortAnalizo(vorto) {
        let finaĵo = this.prenuFinaĵon(vorto)
        if (finaĵo === "") return vorto
        let sufikso = this.prenuSufikson(vorto, finaĵo)
        let radiko = this.prenuRadikon(vorto, sufikso, finaĵo)
        vorto = radiko + this.sufiksoStilo + sufikso + this.fermoDeElemento + this.finaĵoStilo + finaĵo + this.fermoDeElemento
        return vorto

    }
    prenuFinaĵon(vorto) {
        let finaĵo = ""
        let fino = ""
        let longo = vorto.length
        if (longo < 3) return ""
        let indekso = longo - 1
        while ("\n\r,.!?;:'".includes(vorto.charAt(indekso))) {
            fino += vorto.charAt(indekso)
            if (vorto.charAt(indekso) === "'") return fino
            vorto = vorto.slice(0, indekso)
            indekso--
        }
        longo = vorto.length
        if (longo < 3) return fino
        if (this.KiaGramatikaĵo.ĉuFinaĵo(vorto.slice(longo - 1))) finaĵo = vorto.slice(longo - 1)
        if (this.KiaGramatikaĵo.ĉuFinaĵo(vorto.slice(longo - 2))) finaĵo = vorto.slice(longo - 2)
        if (this.KiaGramatikaĵo.ĉuFinaĵo(vorto.slice(longo - 3))) finaĵo = vorto.slice(longo - 3)
        if (!this.ĉuHavasVokalon(vorto.slice(0, longo - finaĵo.length))) return fino
        return finaĵo + fino
    }
    prenuSufikson(vorto, finaĵo) {
        vorto = vorto.slice(0, vorto.length - finaĵo.length)
        let longo = vorto.length
        if (longo < 4) return ""
        let sufikso = ""
        if (this.KiaGramatikaĵo.ĉuSufikso(vorto.slice(longo - 2))) sufikso = vorto.slice(longo - 2)
        if (this.KiaGramatikaĵo.ĉuSufikso(vorto.slice(longo - 3))) sufikso = vorto.slice(longo - 3)
        if (!this.ĉuHavasVokalon(vorto.slice(0, longo - sufikso.length))) return ""
        return sufikso
    }
    ĉuHavasVokalon(vorto) {
        let vokaloj = ["a", "e", "i", "o", "u", "A", "E", "I", "O", "U"]
        for (let i = 0; i < vokaloj.length; i++) {
            if (vorto.includes(vokaloj[i])) return true
        }
        return false
    }
    prenuRadikon(vorto, sufikso, finaĵo) {
        let radiko = vorto.slice(0, vorto.length - finaĵo.length - sufikso.length)
        return radiko
    }
}
export default EmfazIlo