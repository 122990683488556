import React from 'react'
import Datumbazo from '../../Datumoj/DatumbazoMastrumilo/DatumbazoInterfaco'
import { Dropdown } from 'primereact/dropdown';

import { Toast } from 'primereact/toast';


class SelektEtikedo extends React.Component {
  constructor(props) {
    super(props)
    this.state = { elektita: null, listo: null }
    this.referenco = this.props.referenco
  }
  componentDidMount() {
    this.petoDeElektoListo()
  }

  render() {
    return (
      <span>
        <Toast ref={(el) => this.toast = el} />
      <Dropdown options={this.state.listo}
        onChange={this.elekto}
        optionLabel="name" placeholder={"Elektu " + this.props.kio + "n!"} />
        </span>
    )
  }

  elekto = (evento) => {
    let id = evento.value.code
    this.referenco(id)
  }

  petoDeElektoListo = () => {
    let referenco = this.akceptoDeElektoListo
    switch (this.props.kio) {
      case "sceno": new Datumbazo().petoDeScenoListo(referenco)
        break
      case "leciono": new Datumbazo().petoDeLecionoListo(referenco)
        break
      default:
    }
  }
mesaĝo(rezulto){
  if (rezulto.includes("Eraro"))
      this.toast.show({
          severity: 'error',
          summary: "malsukcesis",
          detail: rezulto, life: 10000
      })
}
  akceptoDeElektoListo = (elektoListo) => this.listoKreilo(elektoListo)
  listoKreilo = (elektoListo) => {     
    let id = this.props.kio + "Id"
    let nomo = this.props.kio + "Nomo"
    try{
    elektoListo = JSON.parse("[" + elektoListo + "]")
  }
  catch(eraro){
    this.mesaĝo(elektoListo)
  }
    let listo = []
    for (let i in elektoListo) {
      listo[i] = { name: elektoListo[i][nomo], code: elektoListo[i][id] }
    }
    this.setState({ listo: listo ,rezulto:elektoListo})
  }

}
export default SelektEtikedo