import { Column as Kolumno } from 'primereact/column'
import { ColumnGroup as KolumnoGrupo } from 'primereact/columngroup'
import { Row as Vico } from 'primereact/row'
import { Button } from 'primereact/button'

class TabuloDatumoj {
    constructor(kio, redaktilo, referenco) {
        this.vicoEnhavoj = this.vicoEnhavo(kio, redaktilo)
        this.kolumnoKapoVicoj = this.kolumnoKapo(kio)
        this.plusajKolumnoj = this.plusKolumnoj(kio,referenco)
        this.numeroListo = ["supraX", "supraY", "subaX", "subaY",
            "ekde", "ĝis"]
        this.tekstoListo = ["teksto", "paroloNomo"]
        this.vicoId = this.vico(kio)[0].field
    }

    vicoEnhavo(kio, redaktilo) {
        let vicoj = this.vico(kio)
        return vicoj.map((col, i) => {
            return <Kolumno key={col.field} field={col.field}
                header={col.header}
                editor={(props) => redaktilo('tabulEnhavo', props, col.field)}
            />
        })
    }

    vico(kio) {
        switch (kio) {
            case "sono": return this.sonoVicoj()
            case "bildo": return this.bildoVicoj()
            default:
        }
    }

    sonoVicoj() {
        let tab = [
            { field: "paroloNomo", header: "Teksto" },
            { field: "ekde", header: "komenco [sec]" },
            { field: "ĝis", header: "fino [sec]" }
        ]
        return tab
    }

    bildoVicoj() {
        let tab = [
            { field: "teksto", header: "Teksto" },
            { field: "supraX", header: "Demaldekstre %" },
            { field: "supraY", header: "Desupre %" },
            { field: "subaX", header: "Demaldekstre %" },
            { field: "subaY", header: "Desupre %" }
        ]
        return tab
    }

    kolumnoKapo(kio) {
        let unuaKapoVico =
            <Vico>
                <Kolumno header="Teksto" rowSpan={2} />
            </Vico>
        if (kio === "bildo")
            unuaKapoVico = <Vico>
                <Kolumno header="Teksto" rowSpan={2} />
                <Kolumno header="SUPRA MALDEKSTRA punkto" colSpan={2} />
                <Kolumno header="SUBA DEKSTRA punkto" colSpan={2} />
            </Vico>
        let duaKapoVico = [...this.vicoEnhavoj]
        duaKapoVico[0] = null
        return (
            <KolumnoGrupo>
                {unuaKapoVico}
                <Vico>
                    {duaKapoVico}
                </Vico>
            </KolumnoGrupo>
        )
    }
    plusKolumnoj(kio, referenco) {
        let porBildoMapo = [
            <Kolumno key="1" rowReorder style={{ width: '3em' }}
                    rowReorderIcon="pi pi-th-large" />
        ]
        let butono = () => <Button icon="pi pi-volume-up"
        className="p-button-rounded  p-button-info"
        onClick={(e) => referenco(e)} />
        let porSonoMapo = [
            <Kolumno key="1" body={butono}/>
        ]
        switch (kio) {
            case "bildo": return porBildoMapo
            case "sono": return porSonoMapo
            default: return porSonoMapo
        }
    }
}

export default TabuloDatumoj