import { Button } from 'primereact/button';
import Kaŝvorto from './AdminKomponantoj/Kashvorto/Kashvorto'
import { Password } from 'primereact/password';

import "./stilo.css";
import React from "react";


class Salutpaĝo extends React.Component {

  render() {
    return (

      <div id="salut-pagho">
        <h2 className="subtitolo">Nur por administrantoj</h2>
        <br />
          Enskribu la kaŝvorton:
        <Password feedback={false} toggleMask />
        <h1>ĈE-KURSO de IEI</h1>
        <Button label="ADMINISTRADO DE LA KURSO"
          icon="pi pi-clock"
          iconPos="top"
          className="p-button-lg"
          onClick={() => this.administrado()} />
      </div>
    )
  }

  administrado() {
    let kaŝvorto = document.getElementsByClassName("p-password-input")[0].value
    let admin = new Kaŝvorto().egala(kaŝvorto)
    if (admin)
      this.props.referenco()
  }
}


export default Salutpaĝo;
