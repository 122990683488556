import BildoSavo from './BildoSavo'
import Datumbazo from '../DatumbazoInterfaco'
import api from "../api.json"

class BildoMapoSavo {
    constructor() {
        this.url = ""
        this.referenco = ""
        this.datumoj = ""
        this.bildoDatumojListo = []
        this.bildoIdTipoListo = []
        this.bildoListo = []
        let urlBazo = api.urlBazo
        this.bildoMapoModifoLaŭIdURL = urlBazo + api.mapoModifo
        this.novaBildoMapoURL = urlBazo + api.novaBildoMapo
    }
    savo(datumoj, referenco) {
        this.datumoj = datumoj
        this.referenco = referenco
        if (datumoj.bildoId === null) {
            let kontrolo = true
            this.savuNovanBildon(kontrolo)
        }
        else if (datumoj.bildoŜanĝo) {
            let kontrolo = false
            this.savuNovanBildon(kontrolo)
        }
        else if (datumoj.bildoMapoId === null)
            this.savuNovanBildoMapon()
        else this.savuBildoMapon()
    }
    savuBildon() {
        new BildoSavo().savuBildon(this.datumoj, this.referenco)
    }

    savuNovanBildon(kontrolo) {
        if (kontrolo) {
            this.kontroluLaEkzistonDeBildoMapo(this.datumoj.bildoMapoNomo)
            return
        }
        let referenco = this.savuAnkaŭLaMapon
        new BildoSavo().savuBildon(this.datumoj, referenco)
    }

    kontroluLaEkzistonDeBildoMapo(bildoMapoNomo) {
        new Datumbazo().leguBildoMaponLaŭNomo(bildoMapoNomo, this.akceptoDeEkzisto)
    }
    akceptoDeEkzisto = (bildoMapo) => {
        if (bildoMapo === "") this.savuNovanBildon(false)
        else this.referenco("Eraro: Jam ekzistas la bildoMapo " + this.datumoj.bildoMapoNomo)

    }
    savuAnkaŭLaMapon = (bildoId) => {
        this.datumoj.bildoId = bildoId
        if (this.datumoj.bildoŜanĝo)
            this.savuBildoMapon(this.datumoj, this.referenco)
        else
            this.savuNovanBildoMapon(this.datumoj, this.referenco)
    }
    savuNovanBildoMapon = () => {
        let bildoId = this.datumoj.bildoId
        let bildoMapoNomo = this.datumoj.bildoMapoNomo
        let bildoMapo = JSON.stringify(this.datumoj.bildoMapo)
        let getPeto = "?bildoMapoNomo=" + bildoMapoNomo + "&bildoId=" + bildoId + "&datumoj=" + bildoMapo
        this.url = this.novaBildoMapoURL
        this.ajaxGet(getPeto, this.referenco)
    }

    savuBildoMapon() {
        let bildoMapoId = this.datumoj.bildoMapoId
        let bildoMapoNomo = this.datumoj.bildoMapoNomo
        let bildoMapo = JSON.stringify(this.datumoj.bildoMapo)
        let getPeto = "?bildoMapoNomo=" + bildoMapoNomo + "&id=" + bildoMapoId + "&datumoj=" + bildoMapo
        this.url = this.bildoMapoModifoLaŭIdURL
        console.log(bildoMapo)
        console.log(this.url + getPeto)
        this.ajaxGet(getPeto, this.referenco)
    }

    ajaxGet(getPeto, referenco) {
        let rezulto = "";
        (async () => {
            const res = await fetch(this.url + getPeto);
            rezulto = await res.text()
            referenco(rezulto)
        })();
        console.log(this.url + getPeto)
    }
}
export default BildoMapoSavo