import React, { Component } from 'react'
import SonoTabulo from './AdminKomponantoj/SonoTrakto/SonoTabulo'
import SonoMapoTabulo from './AdminKomponantoj/MapoTabulo'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast';
//import SonoDaŭro from '../Utilajhoj/getAudioDuration'

import 'primeicons/primeicons.css'
import 'primereact/resources/themes/saga-green/theme.css'
import 'primereact/resources/primereact.css'
import 'primeflex/primeflex.css'
import './datatable.css'

import SonoElekto from './AdminKomponantoj/Elekto'
import Datumbazo from '../Datumoj/DatumbazoMastrumilo/DatumbazoInterfaco';
import FajloAlŝuto from './AdminKomponantoj/FajloAlshuto/FajloAlshuto'
import SurlokaTekstoEnigo from '../Utilajhoj/SurlokaTekstoEnigo';
import SavoStatoModifo from './AdminKomponantoj/SavoStatoModifo'
import SonoSavo from '../Datumoj/DatumbazoMastrumilo/SonoTrakto/SonoSavo'

class SonoMapoAdministrado extends Component {

    constructor(props) {
        super(props);
        this.savoStato = new SavoStatoModifo().prenoDeSavoStato()
        this.referenco = this.akceptoDeSavoRezulto
        this.sonoNomo = ""
        this.sonoŜanĝo = false
        this.elektitaVico = null
        if (this.props.state === "")
            this.state = {
                sono: "",
                sonoId: null,
                sonoNomo: null,
                originaSonoNomo: null,
                mimeTipo: "",
                sonoKategorio: null,

                sonoMapo: [],
                originaSonoMapo: [],

                de: 0,
                ĝis: 10,
                sonoStato: "silentu",

                sono2: "",
                mimeTipo2: "",
                sono2Nomo: null,

                savoStato: this.savoStato.nesavinda,
                editingRows: {},
                selected: null,
                freŝigoDeElektoListo: false
            }
        else this.state = this.props.state
    }

    componentWillUnmount() {
        this.props.referenco(this.state)
    }

    render() {
        return (
            <div className="datatable">
                <Toast ref={(el) => this.toast = el} />
                <SonoTabulo
                    sono={this.state.sono}
                    sonoNomo={this.state.sonoNomo}
                    de={this.state.de}
                    ĝis={this.state.ĝis}
                    sonoStato={this.state.sonoStato}
                    sono2={this.state.sono2}
                    sono2Nomo={this.state.sono2Nomo}
                    de2={this.state.de2}
                    ĝis2={this.state.ĝis2}
                />
                {this.agadStrio()}
                <div className="sonomapo-tabulo">
                    <table><tbody><tr>
                        <td style={{ verticalAlign: "top" }}>
                            <b>Sonomapo de la sono :</b>
                        </td>
                        <td>
                            <SurlokaTekstoEnigo teksto={this.state.sonoNomo}
                                referenco={this.novaSonoNomo} />
                        </td>
                    </tr></tbody></table>

                    <SonoMapoTabulo
                        tipo="sono"
                        tabulEnhavo={this.state.sonoMapo}
                        referenco={this.mapoStatoModifita} />

                    <Button id="savo" label="Nova vico" icon="pi pi-plus"
                        className="p-button-rounded p-button-primary p-m-6"
                        onClick={() => this.novaVico()} />



                    <br /><br />
                    {JSON.stringify(this.state)}

                </div>
            </div>
        );
    }

    agadStrio() {
        return <table style={{ width: '60%' }}>
            <tbody>
                <tr style={{ height: '3em' }}>
                    <td>
                        <FajloAlŝuto referenco={this.akceptoDeNovaSono}
                            label="Sonoalŝuto"
                            fajloTipo="audio/*"
                        />
                    </td>
                    <td>
                        <SonoElekto referenco={this.akceptoDeSono}
                            nomo="Sono" kampo="sonoNomo"
                            freŝigo={this.state.freŝigoDeSonoElektoListo} />
                    </td>
                    <td style={{ width: '50%', textAlign: "center" }}>
                        <Button
                            className="p-button-secondary p-mx-6"
                            label="Savo" icon="pi pi-save"
                            onClick={() => this.savuŜanĝojn()} />
                        <br />
                        {this.state.savoStato}
                    </td>

                    <td>
                        <FajloAlŝuto referenco={this.akceptoDeNovaSono2}
                            label="Sonoalŝuto por ŝanĝi la originan sonon"
                            fajloTipo="audio/*" />
                    </td>
                    <td>
                        <Button id="novaMapo"
                            className="p-button-info p-mx-6"
                            label="Interŝanĝu la sonojn" icon="pi-arrows-h"
                            onClick={this.interŜanĝu} /><br />
                    </td>
                </tr>
            </tbody>
        </table>
    }

 
    //////////////////////Datumbazokontakto:///////////////////////////////////////////////
    akceptoDeSono = sonoNomo => this.sonoLego(sonoNomo)
    sonoLego = (sonoNomo) => {
        console.log(sonoNomo)
        new Datumbazo().legoDeSonoKunMapo(
            sonoNomo,
            this.akceptoDeDatumaro
        )
    }

    akceptoDeDatumaro = datumaro => this.datumaro(datumaro)
    datumaro = (datumoj) => {
        console.log(datumoj)
        //   let datumoj = datumaro.datumoj[0]
        this.sonoŜanĝo = false
        //  console.log(SonoDaŭro(datumoj.sonoURL))
        let originaMapo= JSON.stringify(datumoj.sonoMapo)
        originaMapo=JSON.parse(originaMapo)
        this.setState({
            sonoId: datumoj.sonoId,
            sonoNomo: datumoj.sonoNomo,
            originaSonoNomo: datumoj.sonoNomo,
            sono: datumoj.sonoURL,
            de: 0,
            ĝis: 60,
            mimeTipo: datumoj.mimeTipo,
            sonoMapo: datumoj.sonoMapo,
            originaSonoMapo: originaMapo,
            savoStato: this.savoStato.nesavinda,
            freŝigoDeSonoElektoListo: false
        })
        console.log(this.state)
    }

    akceptoDeNovaSono = novaSono => this.novaSono(novaSono)
    akceptoDeNovaSono2 = novaSono => this.setState({
        mimeTipo2: novaSono.type,
        sono2: URL.createObjectURL(novaSono),
        sono2Nomo: novaSono.name.split(".")[0]

    })
    novaSono = (novaSono) => {
        console.log(novaSono)
        let sonoURL = URL.createObjectURL(novaSono)
        let sonoNomo = novaSono.name.split(".")[0]
        sonoNomo = sonoNomo.charAt(0).toUpperCase() + sonoNomo.slice(1)
        this.setState({
            mimeTipo: novaSono.type,
            sono: sonoURL,
            sonoNomo: sonoNomo
        })

    }
    interŜanĝu = () => {
        let sono = this.state.sono
        let sono2 = this.state.sono2
        let mimeTipo = this.state.mimeTipo
        let mimeTipo2 = this.state.mimeTipo2
        this.sonoŜanĝo = !this.sonoŜanĝo
        let ŝanĝoStato = "La sono estas ŝanĝita"
        let stato
        if (this.sonoŜanĝo) {
            this.statoAntaŭŜanĝo = this.state.savoStato
            stato = ŝanĝoStato

        } else {
            if (this.state.savoStato !== ŝanĝoStato)
                this.statoAntaŭŜanĝo = this.state.savoStato
            stato = this.statoAntaŭŜanĝo
        }
        this.setState({
            mimeTipo: mimeTipo2,
            mimeTipo2: mimeTipo,
            sono: sono2,
            sono2: sono,
            savoStato: stato
        })
    }

    savuŜanĝojn = () => {
        console.log(this.state)
        /* if (this.state.savoStato === this.savoStato.nesavinda) return
         if (this.state.savoStato === this.savoStato.savita) return*/
         console.log(this.a)
        let datumoj = {
            sono: this.state.sono,
            sonoId: this.state.sonoId,
            sonoNomo: this.state.sonoNomo,
            sonoMapo: this.state.sonoMapo,
            mimeTipo: this.state.mimeTipo,

            originaSonoNomo: this.state.originaSonoNomo,
            originaSonoMapo: this.state.originaSonoMapo,

            sonoŜanĝo: this.sonoŜanĝo
        }
        console.log(datumoj)
        
       
           new SonoSavo().savuSonon(datumoj, this.referenco)
    }

    akceptoDeSavoRezulto = (rezulto) => {
        console.log(rezulto)
        if (rezulto.includes("Eraro"))
            this.toast.show({
                severity: 'error',
                summary: "malsukcesis",
                detail: rezulto, life: 10000
            })
        else
            this.toast.show({
                severity: 'success',
                summary: "sukcesis??",
                detail: rezulto, life: 10000
            })
        if (rezulto.includes("Eraro"))
            this.setState({
                savoStato: rezulto
            })
        else this.setState({
            savoStato: this.savoStato.savita
        })
    }

    novaSonoNomo = (sonoNomo) => {
        this.setState({
            sonoNomo: sonoNomo,
            savoStato: this.savoStato.modifita
        })
    }

    mapoStatoModifita = (sonoMapo) => {
        console.log(sonoMapo)
        console.log(sonoMapo.butono)
        if (sonoMapo.butono === undefined) {
            this.ŝanĝu(sonoMapo)
            return
        }
        if (sonoMapo.butono.includes("trash")) {
            this.ŝanĝu(sonoMapo)
            return
        }

        if (sonoMapo.butono.includes("volume"))
            this.setState({
                de: sonoMapo.vico.ekde,
                ĝis: sonoMapo.vico.ĝis,
                sonoStato: "ludu"
            })

    }

    ŝanĝu(sonoMapo) {
        if (sonoMapo.nova !== undefined)
            sonoMapo = sonoMapo.nova
        this.setState({
            sonoMapo: sonoMapo,
            savoStato: this.savoStato.modifita
        })
    }
    novaVico = () => {
        let teksto = {sonoId:this.state.sonoId, paroloNomo: "???", ekde: "0", ĝis: "100" }
        let nova = [...this.state.sonoMapo]
        nova.unshift(teksto)
        this.setState({
            sonoMapo: nova,
            savoStato: "Redaktu la novan vicon!"
        })
    }



}
export default SonoMapoAdministrado