import React from 'react'

import { PickList } from 'primereact/picklist';
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import ForigButono from "./AdminKomponantoj/ForigButono"
import LecionoElekto from '../Kurso/Leciono/LecionoElekto'
import SurlokaTekstoEnigo from '../Utilajhoj/SurlokaTekstoEnigo';
import Datumbazo from '../Datumoj/DatumbazoMastrumilo/DatumbazoInterfaco'
import { ReactFitty } from "react-fitty";
import 'primeflex/primeflex.css';


class LecionoAdministrado extends React.Component {
  constructor(props) {
    super(props)
    this.lecionoNomo = ""
    this.scenoLego()
    this.lecionoScenoj = []
    this.lecionoNomo = "????"
    this.lecionoId = null
    this.novaLecionoLabel = "Ĝi estas nova leciono !!"
    if (this.props.state === "")
      this.state = {
        lecionoScenoj: [],
        scenoListo: [],
        lecionoNomo: ""
      }
    else this.state = this.props.state
  }

  componentWillUnmount() {
    this.props.referenco(this.state)
  }

  render() {
    return (
      <div >
        <Toast ref={(el) => this.toast = el} />
        <LecionoElekto referenco={this.akceptoDeLeciono} />
        <Button id="savo" icon="pi pi-save"
          className='p-mx-6'
          label="Savo de la modifoj"
          onClick={() => this.lecionoModifo()} />
        <ForigButono
          mesaĝo='Ĉu vi vere volas forigi el la datumbazo tiun ĉi lecionon?'
          butonoSurskribo="Forĵeto de la leciono"
          akcepto={this.forigoDeLaLeciono}
        />
        <Button onClick={this.novaLeciono}
          icon="pi pi-sun" label={this.novaLecionoLabel}
          className="p-button-rounded p-button-info p-mx-6" />
        <div>
          <PickList source={this.state.scenoListo}
            target={this.state.lecionoScenoj}
            itemTemplate={this.itemTemplate}
            onChange={this.onChange}
            sourceHeader={<p className='p-shadow-2'>Elekteblaj scenoj</p>}
            targetHeader={<div className='p-shadow-2'>Scenoj de la leciono
              <SurlokaTekstoEnigo teksto={this.state.lecionoNomo}
                referenco={this.novaLecionoNomo} />
            </div>}
          />
        </div>
      </div>
    )
  }
  novaLeciono = () => {
    this.lecionoId = null
    this.novaLecionoLabel = "Ĝi estas nova leciono !!"
    this.setState({})
  }

  forigoDeLaLeciono = () => {
    console.log(this.lecionoId)
    new Datumbazo().forviŝuLecionon(this.lecionoId, this.akceptoDeSavoRezulto)
  }
  novaLecionoNomo = (lecionoNomo) => {
    this.setState({
      lecionoNomo: lecionoNomo
    })
  }
  akceptoDeLeciono = (leciono) => this.traktoDeLeciono(leciono)
  traktoDeLeciono(leciono) {
    let scenoListo = leciono.scenoListo
    this.lecionoNomo = leciono.lecionoNomo
    this.lecionoId = leciono.lecionoId
    this.lecionoScenoj = scenoListo
    this.scenoLego()
  }

  scenoLego = () => {
    new Datumbazo().petoDeScenoListo(this.akceptoDeScenoListo)
  }

  akceptoDeScenoListo = (scenoListo) => {
    scenoListo = JSON.parse("[" + scenoListo + "]")
    for (let scenoIndekso in scenoListo) {
      let id = scenoListo[scenoIndekso].scenoId
      let scenoNomo = scenoListo[scenoIndekso].scenoNomo
      scenoListo[scenoIndekso] = [id, scenoNomo]
    }
    let lecionoScenoj = this.lecionoScenoj
    for (let indekso in lecionoScenoj) {
      let nomoIndekso = lecionoScenoj[indekso]
      for (let scenoIndekso in scenoListo) {
        if (scenoListo[scenoIndekso][0] === nomoIndekso) {
          lecionoScenoj[indekso] = scenoListo[scenoIndekso]
          scenoListo.splice(scenoIndekso, 1)
          break
        }
      }
    }
    if (this.lecionoId !== null)
      this.novaLecionoLabel = "Ĉu ĝi estu nova leciono ?"
    this.setState({
      lecionoScenoj: lecionoScenoj,
      scenoListo: scenoListo,
      lecionoNomo: this.lecionoNomo
    })
  }

  itemTemplate = (item) => {

    return <ReactFitty maxSize="20">{item[0]} : {item[1]}</ReactFitty>
  }

  onChange = (event) => {
    this.setState({
      scenoListo: event.source,
      lecionoScenoj: event.target
    })
  }

  lecionoModifo() {
    let scenoListo = [...this.state.lecionoScenoj]
    for (let indekso in scenoListo)
      scenoListo[indekso] = scenoListo[indekso][0]
    let titolo = this.state.lecionoNomo
    let id = this.lecionoId
    if (id !== null)
      new Datumbazo().modifuLecionon(id, titolo, scenoListo, this.akceptoDeSavoRezulto)
    else
      new Datumbazo().kreuLecionon(titolo, scenoListo, this.akceptoDeSavoRezulto)
  }

  akceptoDeSavoRezulto = (rezulto) => this.traktoDeSavoRezulto(rezulto)
  traktoDeSavoRezulto(rezulto) {
    if (rezulto.includes("Eraro"))
      this.toast.show({
        severity: 'error',
        summary: "malsukcesis",
        detail: rezulto, life: 10000
      });
    else
      this.toast.show({
        severity: 'success',
        summary: "sukcesis",
        detail: rezulto, life: 10000
      })
    this.listo = undefined
    this.setState({})
  }

}

export default LecionoAdministrado
