import React from 'react';
import { RadioButton } from 'primereact/radiobutton';
import 'primereact/resources/themes/luna-pink/theme.css';
//import 'primereact/resources/themes/rhea/theme.css';
//import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import './stilo.css'
class Navigilo extends React.Component {
    constructor(props) {
        super(props)
        this.momentoj = []
        for (let i = 1; i <= this.props.totalaNombro; i++)
            this.momentoj[i] =
                { tooltip: i + "a momento", key: i }
    }

    render() {
        return (<div>
            <div className="radiobutton card" >
                {
                    this.momentoj.map(
                        momento => {
                            return (
                                <div  key={momento.key+"rbk"}>
                                    <div key={momento.key} className="p-field-radiobutton">
                                        
                                        <RadioButton inputId={momento.key}
                                            key={momento.key+"rb"}
                                            id={momento.key}
                                            tooltip={momento.tooltip}
                                            tooltipOptions={{ position: 'left', mouseTrack: true, mouseTrackTop: 15 }}
                                            name="momento"
                                            value={momento}
                                            disabled={momento.key === this.props.aktuala+1}
                                            />
                                    </div>
                                </div>
                            )
                        })
                }
            </div></div>
        )
    }
}
export default Navigilo