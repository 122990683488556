import React from 'react'
import Analizilo from './Analizilo'
import Supersignoj from '../../Kurso/KursoKomponantoj/SuperSignilo/Supersignoj'
import ScenoKreilo from "./TekstoAnalizoKreilo"
import DatumObjektoKreilo from '../DatumObjektoKreilo'

class TekstoAnalizoStarto extends React.Component {
    constructor(props) {
        super(props)
       this.DatumObjekto = new DatumObjektoKreilo().petoDeObjekto(
            new ScenoKreilo(),
            this.props.scenoDifino
        )
        this.state = {
            teksto: ""
        }
        this.teksto = this.DatumObjekto.prenoDeTeksto()
    }

    componentDidMount = () => {
        this.valorojPorScenovariabloj()
    }

    render() {
        return (
            <div key="sceno-krado" id="sceno-krado" >
                <button type="button" key="emfazu" id="emfazu" onClick={() => { this.emfazu() }}>Analizu la tekston</button>
                <br />
                <br /><br />
                <div key="emfazita" id="emfazita" contentEditable='true' dangerouslySetInnerHTML={{ __html: this.state.teksto }} />
                <br />
                <label htmlFor="areo">Skribu aŭ enkopiu la tekston analizendan en la suban tekstokampon! </label>
                <Supersignoj />
                <textarea className="skribebla" key="areo" id="areo" nomo="areo"
                    rows="10" cols="100" defaultValue={this.teksto}>

                </textarea>
            </div>
        )
    }

    valorojPorScenovariabloj() {
        this.Analizilo = new Analizilo()
    }

    /////////////////////////Eventoj  
    emfazu = (evento) => {
        let teksto = document.getElementById("areo").value
        teksto = this.Analizilo.tekstoAnalizo(teksto)
        this.setState({
            teksto: teksto
        })
    }

}

export default TekstoAnalizoStarto