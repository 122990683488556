import DatumoObjekto from "./DatumObjekto"

class TekstoAnalizoKreilo {

  kreuScenon(datumo) {

    let DatumObjekto = new DatumoObjekto()
    let scenoTeksto = ""
    for (let indekso in datumo["teksto"]) scenoTeksto += datumo["teksto"][indekso]
    DatumObjekto.valoroAlTeksto(scenoTeksto)
    return DatumObjekto
  }

}

export default TekstoAnalizoKreilo