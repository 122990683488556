import React from 'react'
import { Button } from 'primereact/button'
import { confirmPopup } from 'primereact/confirmpopup'

class ForigButono extends React.Component {

    render() {
        return (
            <Button onClick={this.konfirmo}
          icon="pi pi-trash" label={this.props.butonoSurskribo}
          className="p-button-rounded p-button-danger p-mx-6" />
        )
    }

    konfirmo = (event) => {
        confirmPopup({
          target: event.currentTarget,
          message: this.props.mesaĝo,
          icon: 'pi pi-info-trash',
          acceptClassName: 'p-button-danger',
          rejectClassName: 'p-button-info',
          accept: this.props.akcepto,
          acceptLabel: "Jes, mi volas!",
          rejectLabel: "Ne, tute ne."
        });
      }

     
}
export default ForigButono