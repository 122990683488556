import React from 'react'
import Sound from 'react-sound'
import { ToggleButton as Butono } from 'primereact/togglebutton';
import Slider from './Slider/Slider';
import './index.css'

class Sono extends React.Component {
    constructor(props) {
        super(props)
        this.progreso = 0
        this.de = this.props.de
        this.ĝis = this.props.ĝis
        if (isNaN(this.de)) this.de = 0
        this.pozicio = this.de * 1000
        this.state = {
            position: this.de * 1000,
            sonoStato: Sound.status.STOPPED,
            progreso: 0,
            tempo:this.de 
        }
        this.plu = true;
    }

    render() {
        return (<div id="sono-strio">
            <table>
                <tbody>
                    <tr align="center">
                        <td>
                            <Butono checked={this.state.sonoStato === Sound.status.PLAYING}
                                className='p-m-2 rounded'
                                style={{ 'fontSize': '2em' }}
                                onChange={() => this.jeHaltoEkludo()}
                                onIcon="pi pi-volume-up" offIcon="pi pi-volume-off"
                                onLabel="Haltu!" offLabel="Ekludu!" />
                            {this.state.tempo}
                            
                            <Slider value={this.state.progreso}
                                onChange={(e) => this.setProgress(e)} />
                                {this.props.de +" - " +this.props.ĝis}
                             {this.ŝanĝo(this.props.de, this.props.ĝis)} 
                            <Sound
                                url={this.props.sono} playStatus={this.state.sonoStato}
                                onLoad={this.jeEnlego}
                                onPlaying={(Sono) => this.play(Sono)}
                                position={this.state.position}                                
                                onPause = {this.onPause}
                                autoLoad={true}
                            />
                            
                        </td>
                    </tr>
                </tbody>
            </table>
        </div >

        )
    }
    onPause=(Sono)=>{
        console.log(Sono.position)
        console.log(Sono.duration)
        this.setState({tempo:(Sono.position/1000).toFixed(1)})
    }
    ŝanĝo(de,ĝis){
        if (this.props.sonoStato==="ludu")
        if (de!==this.de || ĝis !== this.ĝis){
            this.de=de ; this.ĝis=ĝis
        this.setState({
            sonoStato: Sound.status.PLAYING,
            position: this.pozicio,
            tempo:this.de
        })
    }
    }
    setProgress = e => {
        this.progreso = e.value
        const daŭro = (this.ĝis - this.de) * 1000
        const pozicio = this.progreso / 100 * daŭro + this.de * 1000
        this.setState({
            sonoStato: Sound.status.PLAYING,
            position: pozicio,
            tempo:(pozicio/1000).toFixed(1)
        })
    }


    ĝisDatigo(Sono) {
        if (this.plu) { this.plu = false; return }
        if (isNaN(this.ĝis)) this.ĝis = Sono.duration / 1000
        if (isNaN(this.de)) this.de = 0
        if (!isNaN(this.props.ĝis) && !isNaN(this.props.de))
            if (this.props.de !== this.de || this.props.ĝis !== this.ĝis) {
                this.de = this.props.de
                this.ĝis = this.props.ĝis
                this.setState({
                    sonoStato: Sound.status.PLAYING,
                    position: this.de * 1000,
                    tempo:this.de
                })
            }
    }

    jeEnlego = (Sono) => {
        this.ĝisDatigo(Sono)
        this.setState({
            sonoStato: Sound.status.STOPPED
        })
    }

    jeHaltoEkludo = () => {
        let stato = Sound.status.PLAYING
        if (stato === this.state.sonoStato) {
            this.pozicio = Sound.position
            stato = Sound.status.PAUSED
        }
        this.setState({
            sonoStato: stato,
            position: this.pozicio,
            tempo:(this.pozicio/1000).toFixed(1)
        })
    }

    play = (Sono) => {
        this.ĝisDatigo(Sono)
        this.moviguPozicioMontrilon(Sono)

        if (Sono.position > this.ĝis * 1000)
            this.setState({
                sonoStato: Sound.status.STOPPED
            })
    }

    moviguPozicioMontrilon(Sono) {
        let pozicio = Sono.position
        this.progreso = (pozicio / 1000 - this.de) / (this.ĝis - this.de) * 100
        
        this.setState({
            position: Sono.position,
            progreso: this.progreso,
            tempo:(Sono.position/1000).toFixed(1)
        })
    }
}

export default Sono