class Konstantoj {
    constructor() {
        this.ĉapeligeblaj = ["s", "g", "c", "j", "h", "S", "G", "C", "J", "H"]
        this.ĉapelitaj = ["ŝ", "ĝ", "ĉ", "ĵ", "ĥ", "Ŝ", "Ĝ", "Ĉ", "Ĵ", "Ĥ"]
        this.hoketigeblaj = ["u", "U"]
        this.hoketitaj = ["ŭ", "Ŭ"]
    }
    prenoDeĈapeligeblaj = () => this.ĉapeligeblaj
    prenoDeĈapelitaj = () => this.ĉapelitaj
    prenoDeHoketigeblaj = () => this.hoketigeblaj
    prenoDeHoketitaj = () => this.hoketitaj
}
export default Konstantoj