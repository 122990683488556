import React from 'react';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-green/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';

class FajloAlŝuto extends React.Component {

    constructor(props) {
        super(props);
        this.referenco = this.props.referenco
        this.state = {
            totalSize: 0,
            objekto: ""
        };

        this.onUpload = this.onUpload.bind(this);
        this.onBasicUpload = this.onBasicUpload.bind(this);
        this.onBasicUploadAuto = this.onBasicUploadAuto.bind(this);
        this.headerTemplate = this.headerTemplate.bind(this);
        this.itemTemplate = this.itemTemplate.bind(this);
        this.emptyTemplate = this.emptyTemplate.bind(this);
    }

    render() {
        return (

            <div>
                <Toast ref={(el) => { this.toast = el; }}></Toast>

                <Tooltip target=".custom-choose-btn" content="Bildo alŝuto" position="bottom" />
                <Tooltip target=".custom-upload-btn" content="Savu" position="bottom" />
                <Tooltip target=".custom-cancel-btn" content="Forigu" position="bottom" />

                <div className="card">

                    <FileUpload name="demo" url="./upload"
                        className="p-mx-6"
                        ref={(el) => this.upload = el}
                        customUpload uploadHandler={this.myUploader}
                        onUpload={this.onUpload} auto
                        chooseLabel={this.props.label}
                        invalidFileSizeMessageSummary="Tro granda fajlo"
                        invalidFileSizeMessageDetail=". Maksimuma grandeco 10MB."
                        accept={this.props.fajloTipo} maxFileSize={10000000}
                        emptyTemplate={<p className="p-m-0">Ŝovu kaj metu la novan objekton ĉi tien!</p>}

                    />

                </div>
                {this.state.objekto}
            </div>
        )
    }
    onUpload() {
        this.toast.show({ severity: 'info', summary: 'Sukcesis', detail: 'la objektolego' });
    }

    onTemplateSelect(e) {
        let totalSize = this.state.totalSize;
        e.files.forEach(file => {
            totalSize += file.size;
        });

        this.setState({
            totalSize
        });
    }

    onTemplateUpload(e) {
        let totalSize = 0;
        e.files.forEach(file => {
            totalSize += (file.size || 0);
        });

        this.setState({
            totalSize
        }, () => {
            this.toast.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
        });
    }

    onTemplateRemove(file, callback) {
        this.setState((prevState) => ({
            totalSize: prevState.totalSize - file.size
        }), callback);
    }

    onTemplateClear() {
        this.setState({ totalSize: 0 });
    }

    onBasicUpload() {
        this.toast.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded with Basic Mode' });
    }

    onBasicUploadAuto() {
        this.toast.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded with Auto Mode' });
    }

    headerTemplate(options) {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = this.state.totalSize / 10000;
        const formatedValue = this.fileUploadRef ? this.fileUploadRef.formatSize(this.state.totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <ProgressBar value={value} displayValueTemplate={() => `${formatedValue} / 1 MB`} style={{ width: '300px', height: '20px', marginLeft: 'auto' }}></ProgressBar>
            </div>
        );
    }

    itemTemplate(file, props) {
        return (
            <div className="p-d-flex p-ai-center p-flex-wrap">
                <div className="p-d-flex p-ai-center" style={{ width: '40%' }}>
                    <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                    <span className="p-d-flex p-dir-col p-text-left p-ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="p-px-3 p-py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger p-ml-auto" onClick={() => this.onTemplateRemove(file, props.onRemove)} />
            </div>
        )
    }

    emptyTemplate() {
        return (
            <div className="p-d-flex p-ai-center p-dir-col">
                <i className="pi pi-image p-mt-3 p-p-5" style={{ 'fontSize': '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ 'fontSize': '1.2em', color: 'var(--text-color-secondary)' }} className="p-my-5">Drag and Drop Image Here</span>
            </div>
        )
    }


    myUploader = (event) => {
        let fajloj = event.files
        console.log(fajloj[0])
        this.referenco(fajloj[0])
        this.upload.clear()

    }

}
export default FajloAlŝuto