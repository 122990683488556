class RipetoTraktilo {
    constructor() {
        this.ripetoNuligo()
    }
    prenoDeRipetoIndekso = () => this.ripetoIndekso
    prenoDeRipetoKomenco = () => this.ripetoKomenco
    prenoDeRipetoFino = () => this.ripetoFino
    prenoDeRipeto = () => this.ripeto
    valoroAlRipetoIndekso = (ripetoIndekso) => this.ripetoIndekso = ripetoIndekso

    ripetŜanĝo(teksto, ĉuSimplaTeksto) {
        if (this.ripeto === undefined) return teksto
        if (this.ripetoIndekso > 0) {
            let teksto0 = this.ripeto[0]
            let teksto1 = this.ripeto[this.ripetoIndekso]
            if (ĉuSimplaTeksto) {
                teksto0 = this.simplaTeksto(teksto0)
                teksto1 = this.simplaTeksto(teksto1)
            }
            teksto0 = teksto0.split(" ")
            teksto1 = teksto1.split(" ")
            for (let indekso in teksto0) {
                teksto =
                    teksto.replaceAll(
                        this.prenuKunMinusklo(teksto0[indekso]), this.prenuKunMinusklo(teksto1[indekso])
                    )
                teksto =
                    teksto.replaceAll(
                        this.prenuKunMajusklo(teksto0[indekso]), this.prenuKunMajusklo(teksto1[indekso])
                    )
            }
        }
        return teksto
    }
    perfortaŜanĝo(listo,al){
        this.ripeto=al
        let i=-1
        let novaListo=[]
        for (let indekso in listo){
            for (let r_indekso in this.ripeto){
                if (r_indekso===0) continue
                this.ripetoIndekso=r_indekso
                let nova = this.ripetŜanĝo(listo[indekso],true)
                if (!listo.includes(nova))
                            novaListo[++i]=nova
            }
        }
        return novaListo
    }
    simplaTeksto(teksto) {
        let indekso1 = teksto.search("<")
        let indekso2 = teksto.search(">")
        while (indekso1 + indekso2 > 0) {
            teksto = teksto.slice(0, indekso1) + teksto.slice(indekso2 + 1)
            indekso1 = teksto.search("<")
            indekso2 = teksto.search(">")
        }
        return teksto
    }
    prenuKunMajusklo(teksto) {
        return teksto[0].toUpperCase() + teksto.slice(1)
    }

    prenuKunMinusklo(teksto) {
        return teksto[0].toLowerCase() + teksto.slice(1)
    }

    ripetoNuligo() {
        this.ripeto = ""
        this.ripetoIndekso = 0
        this.ripetoKomenco = -1
        this.ripetoFino = -1
    }

    
    isRipeto(momento, momentIndekso) {
       
        let isRipeto = false
        if (momento[0] === "ripetoj") {
            this.ripeto = momento[1]
            this.ripeto = this.miksu(this.ripeto)
            this.ripetoKomenco = momentIndekso
            this.ripetoFino = 1000
            isRipeto = true
        } else
            if (momento[0] === "fino") {
                this.ripetoFino = momentIndekso
                isRipeto = true
            }
        return isRipeto
    }

    miksu(listo) {
        let n = listo.length - 2
        for (let i = 0; i < 1000; i++) {
            let n1 = this.prenuHazardanEntjeron(n)
            let n2 = this.prenuHazardanEntjeron(n)
            let enhavo0 = listo[n1]
            listo[n1] = listo[n2]
            listo[n2] = enhavo0
        }
        return listo
    }

    prenuHazardanEntjeron(maksEntjero) {
        let numero = Math.random()
        numero = numero * (maksEntjero)
        numero = Math.ceil(numero)
        return numero
    }

    sekvaMomentIndekso = (momentIndekso) => {
        if (momentIndekso === this.ripetoKomenco)
            momentIndekso++
        if (momentIndekso === this.ripetoFino) {
            this.ripetoIndekso++
            if (this.ripetoIndekso > this.ripeto.length - 1) {
                this.ripetoNuligo()
                momentIndekso++
            } else
                momentIndekso = this.ripetoKomenco + 1
        }
        return momentIndekso
    }

    antaŭaMomentIndekso = (momentIndekso) => {
        momentIndekso--
        if (momentIndekso === this.ripetoKomenco) {
            if (this.ripetoIndekso > 1) {
                this.ripetoIndekso--
                momentIndekso++
            }
            if (this.ripetoIndekso < 0) {
                this.ripetoNuligo()
                momentIndekso--
            }
        }
        return momentIndekso
    }
}
export default RipetoTraktilo