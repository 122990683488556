class Konstantoj {
    constructor() {
        this.finaĵoj = ["a", "e", "i", "o", "u", "an", "j", "jn", "aj", "ajn", "en", "oj", "on", "ojn", "as", "is", "os"]
        this.sufiksoj = ["ant", "int", "ont", "aĉ", "ad", "aĵ", "an", "ar", "ebl", "ec", "eg", "ej", "em", "ebl", "end",
            "er", "estr", "et", "i", "id", "ig", "iĝ", "il", "in", "it", "at", "ot", "ind", "ing", "ism", "ist", "obl",
            "on", "op", "uj", "ul", "um"]
        this.sufiksoStilo = "'color:red; font-weight: bold'"
        this.finaĵoStilo = "'color:blue'"
    }
    prenoDeFinaĵoj = () => this.finaĵoj
    prenoDeSufiksoj = () => this.sufiksoj
    prenoDeSufisksoStilo = () => this.sufiksoStilo
    prenoDeFinaĵoStilo = () => this.finaĵoStilo
}
export default Konstantoj