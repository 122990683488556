class BildoMapoTrakto{
    koordinatoj(event) {
        let element0 = event.target
        if (event.target.tagName === "AREA")
            element0 = element0.parentElement.parentElement.children[0]
        let element = element0
        let top = 0, left = 0;
        do {
            top += element.offsetTop || 0;
            left += element.offsetLeft || 0;
            element = element.offsetParent;
        } while (element);
        let larĝo = element0.width
        let alto = element0.height
        let x0 = left
        let y0 = top
        let x = (100 * (event.clientX - x0) / larĝo).toFixed(2)
        let y = (100 * (event.clientY - y0) / alto).toFixed(2);
        document.getElementById("koordinatoj").innerHTML =
            "  Demaldekstre: " + x + "%<br />  Desupre: " + y + "%"

    }
    petoDeAreoj(surskriboj) {
        if (surskriboj === null) return
        let bildo = document.getElementsByTagName("IMG")[0]
        if (bildo === undefined) return
        let areoj = []
        let larĝoObligo = bildo.clientWidth / 100.
        let altoObligo = bildo.clientHeight / 100
        for (let indekso in surskriboj) {
            let supraX = surskriboj[indekso]["supraX"] * larĝoObligo
            let supraY = surskriboj[indekso]["supraY"] * altoObligo
            let subaX = surskriboj[indekso]["subaX"] * larĝoObligo
            let subaY = surskriboj[indekso]["subaY"] * altoObligo

            let koordinatoj = ""
            koordinatoj += supraX + ","
            koordinatoj += supraY + ","
            koordinatoj += subaX + ","
            koordinatoj += subaY
            areoj[indekso] = <area key={indekso + "areo"} shape="rect"
                coords={koordinatoj}
                className="sildo"
                onMouseOver={(evento) => { this.aperu(evento) }}
                onMouseOut={(evento) => { this.malaperu(evento) }}
                onClick={(evento) => { this.koordinatoj(evento) }}
                alt={surskriboj[indekso]["teksto"]}
                name={surskriboj[indekso]["teksto"]}
            />
        }

        return areoj
    }
    aperu = (evento) => {
        let id = "bildo-mapo-teksto"
        let vorto = evento.target.alt
        document.getElementById(id).innerHTML = vorto

    }
    malaperu = (evento) => {
        let id = "bildo-mapo-teksto"
        document.getElementById(id).innerHTML = ""
    }
}
export default BildoMapoTrakto