import React from 'react'
//import SonoMapoTrakto from './SonoMapoTrakto'
import Sono from '../../../Kurso/KursoKomponantoj/KradoKreiloj/Sono/Sono'

class SonoTabulo extends React.Component {

    render() {
        return <table style={{ border: '1px solid red', width: '80%' }}>
            <tbody>
                <tr style={{ height: '20em' }}>
                    <td style={{ width: '10%' }} />
                    <td id="sono"
                        style={{ width: '40%' }}>
                        <Sono
                            sono={this.props.sono}
                            de={this.props.de}
                            ĝis={this.props.ĝis}
                            sonoStato={this.props.sonoStato}

                        />
                        {this.props.sonoNomo}
                    </td>

                    <td style={{ width: '40%' }}>
                        <Sono
                            sono={this.props.sono2}
                            de={this.props.de2}
                            ĝis={this.props.ĝis2}
                        />
                        {this.props.sono2Nomo}
                    </td>
                </tr>
            </tbody>
        </table>

    }

}

export default SonoTabulo