class ElScenoPriskriboJson {
    constructor() {
        this.dividilo = ";"
    }
    faruPriskribon(json) {
        console.clear()
        json=JSON.parse(json)
        let priskribo = this.simplaValoro(json, "scenoId")
        priskribo += this.simplaValoro(json, "scenoNomo")
        json=JSON.parse(json["sceno"])
        if (json["scenoTipo"] !== 1) return JSON.stringify(json)
        priskribo += this.simplaValoro(json, "scenoTipo")
        priskribo += this.simplaValoro(json, "dezajnTipo")
        priskribo += this.listoValoro(json, "bildoj")
        priskribo += this.listoValoro(json, "sonoj")
        priskribo += this.listoValoro(json, "videoj")
        priskribo += "momentoj:"
        let eroj = ""
        for (let momentIndekso in json["momentoj"]) {
            priskribo += "<br/>"//"\n"
            eroj = json["momentoj"][momentIndekso]
            for (let indekso = 0; indekso < eroj.length; indekso += 2) {
                eroj[indekso] += ":"
            }
            for (let indekso = 1; indekso < eroj.length; indekso += 2)
                if (Array.isArray(eroj[indekso])) {
                    eroj[indekso] = this.listoToString(eroj[indekso], "+<br/>")//\n")
                }
            priskribo += this.listoToString(eroj)
        }
        return priskribo
    }

    simplaValoro(json, eco) {
        let ecoValoro = json[eco]
        if (ecoValoro === undefined) ecoValoro = ""
        return eco + ":" + ecoValoro + "<br/>"//"\n"
    }

    listoValoro(json, eco) {
        let valoro = ""
        for (let indekso in json[eco]) {
            valoro += json[eco][indekso] + this.dividilo
        }
        valoro = valoro.slice(0, -1)
        return eco + ":" + valoro + "<br/>"//"\n"
    }

    listoToString(listo, dividilo) {
        if (dividilo === undefined) dividilo = this.dividilo
        let string = ""
        for (let indekso in listo)
            if (listo[indekso].slice(-1) === ":")
                string += listo[indekso]
            else {
                if (listo[indekso].includes("...")) string += listo[indekso] + this.dividilo
                else
                    if ((indekso > 0) && indekso % 6 === 0) string += listo[indekso] + dividilo
                    else string += listo[indekso] + this.dividilo
            }
        if (string.slice(-this.dividilo.length) === this.dividilo) string = string.slice(0, -this.dividilo.length)
        else string = string.slice(0, -dividilo.length)
        return string
    }

    faruJson(teksto) {
        if (teksto === "") return "{}"
        teksto = this.forigoDeJoditAldonoj(teksto)
        if (teksto[0] === "{") return teksto
        teksto = teksto.replaceAll("+\n", this.dividilo)
        let vicoj = teksto.split("\n")
        let json = "{}"
        let momentIndekso = -1
        json = JSON.parse(json)

        for (let indekso in vicoj) {
            let vico = vicoj[indekso].trim()
            if (vico === "") continue
            let eco = vico.slice(0, vico.indexOf(":")).trim()
            if (momentIndekso === -1) {
                let enhavo = vico.slice(vico.indexOf(":") + 1)
                if (eco.slice(-2) === "oj") {
                    json[eco] = []
                    let elementoj = enhavo.split(this.dividilo)
                    for (let i in elementoj)
                        json[eco][i] = elementoj[i]
                }
                else {
                    if (isNaN(parseInt(enhavo)))
                        json[eco] = enhavo
                    else
                        json[eco] = parseInt(enhavo)
                }
            }
            if (momentIndekso > -1) {
                json["momentoj"][momentIndekso] = this.momentEnhavo(vico)
                momentIndekso++

            }
            if (eco === "momentoj") {
                json[eco] = []
                momentIndekso = 0
            }

        }
        //console.log(JSON.stringify(json)  )
        return JSON.stringify(json)
    }

    forigoDeJoditAldonoj(teksto) {
        teksto = this.forigoDeAlineoj(teksto)
        /*teksto = this.faruVicojn(teksto)
        teksto = this.forigoDeJoditMarker(teksto)
        teksto = this.forigoDeSpacoMarkoj(teksto)
        teksto = this.forigoDeJoditMarker(teksto)
        teksto = this.reŝanĝoDeJoditŜanĝoj(teksto)*/
        return teksto
    }
    forigoDeAlineoj(teksto){
        teksto = teksto.replaceAll("<p>", "")
        teksto = teksto.replaceAll("</p>", "\n")
        return teksto
    }
    faruVicojn(teksto) {
        teksto = teksto.replaceAll("<br>", "\n")
        return teksto
    }
    forigoDeSpacoMarkoj(teksto) {
        teksto = teksto.replaceAll("&nbsp;", " ")
        return teksto;
    }
    forigoDeJoditMarker(teksto) {
        let id1 = teksto.search('<span id="jodit-selection_marker')
        if (id1 === -1) return teksto
        let id2 = teksto.search('none;') + 15
        teksto = teksto.slice(0, id1) + teksto.slice(id2)
        return teksto
    }

    reŝanĝoDeJoditŜanĝoj(teksto) {
        teksto = teksto.replaceAll('"\\&quot;', '\\"')
        teksto = teksto.replaceAll('\\&quot;"', '\\"')
        teksto = teksto.replaceAll(', ', ',')
        teksto = teksto.replaceAll(': ', ':')

        return teksto
    }

    momentEnhavo(vico) {
        let enhavo = []
        let i = 0
        while (vico.length > 0) {
            let kradoEnhavo = this.kradoEnhavo(vico)
            vico = kradoEnhavo[2]
            enhavo[i++] = kradoEnhavo[0]
            if (kradoEnhavo[1].slice(-1) === ",")
                kradoEnhavo[1] = kradoEnhavo[1].slice(0, -1)
            if (kradoEnhavo[1].indexOf(this.dividilo) > -1) {
                enhavo[i++] = this.traktoDeLaHTMLEtikedoj(kradoEnhavo[1])
            } else enhavo[i++] = kradoEnhavo[1]
        }
        return enhavo
    }

    kradoEnhavo(vico) {
        let kradoIndekso = vico.search(":")
        if (kradoIndekso === -1) return [vico, "", ""]
        let enhavoIndekso = 0
        let krado = vico.slice(0, kradoIndekso)

        if (isNaN(parseInt(krado)))
            enhavoIndekso = vico.search("\\n")
        else
            enhavoIndekso = vico.search(/;[0-9]*:/)
        if (enhavoIndekso === -1)
            enhavoIndekso = vico.search("\\n")
        let enhavo = vico.slice(kradoIndekso + 1, enhavoIndekso)
        let resto = vico.slice(enhavoIndekso + 1)
        if (enhavo === "") resto = vico.slice(enhavoIndekso)
        if (enhavoIndekso === -1) {
            enhavo = vico.slice(kradoIndekso + 1)
            resto = ""
        }
        if (resto[0] === this.dividilo) resto = resto.slice(1)
        if (!isNaN(parseInt(krado))) krado = parseInt(krado)

        return [krado, enhavo, resto]
    }

    traktoDeLaHTMLEtikedoj(enhavo) {
        let dividiloj = []
        let etikedoKomencoj = this.trovuĈiun(enhavo, /<.*>/g)
        let etikedoFinoj = this.trovuĈiun(enhavo, />/g)
        let re = new RegExp(this.dividilo, "g");
        let dividilo = this.trovuĈiun(enhavo, re)
        let i = 0
        for (let id in dividilo) {
            let divido = true
            for (let ie in etikedoFinoj) {
                if ((etikedoKomencoj[ie] < dividilo[id]) && (dividilo[id] < etikedoFinoj[ie]))
                    divido = false
            }
            if (divido) dividiloj[i++] = dividilo[id]
        }
        let enhavoj = []
        let komenco = 0
        i = 0
        for (let id in dividiloj) {
            if (komenco < enhavo.length)
                enhavoj[i++] = enhavo.slice(komenco, dividiloj[id])
            komenco = dividiloj[id] + 1

        }
        if (komenco < enhavo.length) enhavoj[i] = enhavo.slice(komenco)
        if (enhavoj.length === 1) enhavoj = enhavoj[0]
        if (dividiloj.length === 0) enhavoj = enhavo
        return enhavoj
    }

    trovuĈiun(teksto, serĉenda) {
        let trafoj = []
        trafoj[0] = 0
        let i = 0
        while (teksto.search(serĉenda) > -1) {
            if (i > 0) trafoj[i] = trafoj[i - 1] + 1
            trafoj[i++] += teksto.search(serĉenda)
            teksto = teksto.slice(teksto.search(serĉenda) + 1)
        }
        return trafoj
    }

}

export default ElScenoPriskriboJson