import React from 'react';
import { Panel } from 'primereact/panel';
import "./grid.css"
class Dezajno extends React.Component {

    render() {
        return (
            <Panel id="bazo" header={this.props.header} toggleable
                className=" p-flex-column " >
                {this.html()}
                <div key="animacia-krado" id="animacia-krado" />
            </Panel >)
    }

    html = () => {
        if (this.props.admin === undefined) {
            this.telefonoKlaso = "p p-grid p-dir-row p-align-center p-d-md-none"
            this.komputiloKlaso = "p0  p-grid  nested-grid  p-d-none  p-d-md-inline-flex p-col-12"
            return <div>{this.telefono()} {this.komputilo()}</div>
        }
        else return this.admin()
    }
    admin() {
        this.telefonoKlaso = "p p-grid p-dir-row p-align-center p-d-md-none"
        this.komputiloKlaso = "p0  p-grid  nested-grid  p-d-none  p-d-md-inline-flex p-col-12"
        this.border = "solid 1px red"
        this.border1 = this.border.replace("red", "blue")
        if (this.props.admin === "komputilo") {
            // this.komputiloKlaso="p p-grid p-dir-row p-align-center"
            return this.komputilo()
        }

        this.telefonoKlaso = "p p-grid p-dir-row p-align-center"
        return this.telefono()
    }
    telefono = () => {
        this.enhavo = [...this.props.enhavo]
        return (
            <div className={this.telefonoKlaso}
                style={{ border: this.border1 }}>
                {/* telefono*/}
               
                    {/*1a vicoo*/}
                    <div className="p-col-12 ronda" style={{ border: this.border }}>
                        {this.enhavo[7]}
                    </div>
                    {/*2a vicoo*/}
                    <div className="p-col-6 " style={{ border: this.border }}>
                        {this.enhavo[0]}
                    </div>
                    <div className="p-col-6 ronda " style={{ border: this.border }}>
                        {this.enhavo[1]}
                    </div>
                    {/*3a vico*/}
                    <div className="p-col-8 p-offset-2" style={{ border: this.border }}>
                        {this.enhavo[2]}
                    </div>
                    {/*4a vicoo*/}
                    <div className="p-col-5" style={{ border: this.border }}>
                        {this.enhavo[3]}
                    </div>
                    <div className="p-col-6 p-offset-6" style={{ border: this.border }}>
                        {this.enhavo[4]}
                    </div>
                     {/*navigilo*/}
            <div id="navigilo" className="p-col-12" style={{ border: this.border }}>
                {this.props.navigado}
            </div>
                </div>
        
        )
    }
    komputilo() {
        this.enhavo = [...this.props.enhavo]
        return (<div className={this.komputiloKlaso}>
            {/* komputilo*/}
            {/*1a kolumno*/}
            
                <div className="p-col-3 p-md-2" >
                    <div className="p p-grid p-dir-col p-justify-center">
                        <div className="p30 p-col-12 " style={{ border: this.border }}>
                            {this.enhavo[0]}
                        </div>
                    </div>
                </div>
                {/*2a kolumno*/}
                <div className="p-col-5  p-md-3 ">
                    <div className="p p-grid p-dir-col p-justify-top">
                        <div className="p10 p-col-12 ronda p-offset-4" style={{ border: this.border }}>
                            {this.enhavo[7]}
                        </div>
                        <div className="p20"/>
                        <div className="p25 p-col-12 ronda" style={{ border: this.border }}>
                            {this.enhavo[1]}
                        </div>
                        <div className="p25 p-col-8 p-offset-4" style={{ border: this.border }}>
                            {this.enhavo[4]}
                        </div>
                    </div>
                </div>
                {/*3a kolumno*/}
                <div className="p p-col-4  p-md-3">
                    <div className="p p-grid p-dir-col p-justify-center" >
                        <div className="p30 p-col-12 p-offset-1" style={{ border: this.border }}>
                            {this.enhavo[2]}
                        </div>
                        <div className="p10 p-col-10 p-offset-1" style={{ border: this.border }} />

                        <div className="p20 p-col-12 p-offset-2" style={{ border: this.border }}>
                            {this.enhavo[3]}
                        </div>
                    </div>
                </div>

                <div className="p p-col-4 p-d-none p-d-md-block">
                    <div className="p p-grid p-dir-col p-justify-center" >
                        <div className="p30 p-col-8  p-offset-2" style={{ border: this.border }}>
                            {this.enhavo[5]}
                        </div>
                        <div className="p30 p-col-8  p-offset-2" style={{ border: this.border }}>
                            {this.enhavo[6]}
                        </div>
                        <div className="p30 p-col-8  p-offset-2" style={{ border: this.border }}>
                            {this.enhavo[8]}
                        </div>
                    </div>
                    </div>
                     {/*navigilo*/}
            <div id="navigilo" className="p-col-12" style={{ border: this.border }}>
                {this.props.navigado}
            </div>
                
        </div>)
    }

}
export default Dezajno