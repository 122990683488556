import React from 'react';
import './KlapoKlako.css'
import Tabulo from './Tabulo'
import ScenoKreilo from "./KlapoKlakoKreilo"
import DatumObjektoKreilo from '../DatumObjektoKreilo';

class KlapoKlakoStarto extends React.Component {
    constructor(props) {
        super(props)
        this.DAO = new DatumObjektoKreilo().petoDeObjekto(
            new ScenoKreilo(),
            this.props.scenoDifino
        )
        this.valorojPorScenovariabloj()
        this.state = {
            teksto: this.teksto,
            mezuro: this.mezuro
        }

    }

    render() {

        return (
            <div key="1" id="klapo-klako">
                <Tabulo key="2" enhavo={this.state.teksto} mezuro={this.state.mezuro} />
            </div>
        )
    }

    valorojPorScenovariabloj() {
        this.teksto = this.DAO.prenoDeTeksto()
        this.mezuro = 3
    }

}

export default KlapoKlakoStarto