import React from 'react';
import DatumObjektoKreilo from '../DatumObjektoKreilo';
import ScenoKreilo from "./ElektoScenoKreilo"

class ElektoScenoStarto extends React.Component {
    constructor(props) {
        super(props)

        this.DatumObjekto = new DatumObjektoKreilo().petoDeObjekto(
            new ScenoKreilo(),
            this.props.scenoDifino
        )
        this.state = ({
            teksto: ""
        })
    }

    componentDidMount = () => {
        this.pravalorojPorScenovariabloj()
    }

    render() {
        //<button>Kontrolo</button>
        return (
            <div key="elekto" id="elektoKrado">
                <div key="teksto" id="teksto" contentEditable='true' dangerouslySetInnerHTML={{ __html: this.state.teksto }} />
                <button key="solvo-butono" onClick={() => { this.solvu() }}> Solvo</button>
                <button key="nuligo-butono" onClick={() => { this.nuligu() }}> Nuligu</button>
            </div>
        )
    }

    pravalorojPorScenovariabloj = (indekso) => {
        this.teksto = this.DatumObjekto.prenoDeTeksto()
        this.opcioj = this.DatumObjekto.prenoDeOpcioj()
        this.dividilo = this.DatumObjekto.prenoDeDividilo()
        this.tekstoListo = this.aldonuOpciojn()
        this.tekstoKunOpcioj = ""
        for (let indekso in this.tekstoListo) this.tekstoKunOpcioj += this.tekstoListo[indekso]
        this.solvoTeksto = this.kreuSolvon()
        this.setState({
            teksto: this.tekstoKunOpcioj
        })
    }

    aldonuOpciojn() {
        let teksto = this.teksto.split(this.dividilo + this.dividilo)
        this.solvo = new Array(teksto.length).fill(-1)
        for (let opcio of Object.keys(this.opcioj)) {
            let opcioj = this.kreuOpciojn(this.opcioj[opcio])
            for (let indekso = 0; indekso < teksto.length; indekso++)
                if (teksto[indekso].startsWith(opcio)) {
                    this.solvo[indekso] = this.opcioValue(teksto[indekso], opcio)
                    teksto[indekso] = "<select>" + opcioj + "</select>"
                }
        }
        return teksto
    }

    kreuOpciojn(opcioj) {
        let select = "<option>  </option>"
        for (let indekso = 1; indekso < opcioj.length + 1; indekso++)
            select += "<option>" + opcioj[indekso - 1] + "</option>"
        return select
    }

    opcioValue(teksto, opcio) {
        teksto = teksto.split(opcio)[1]
        let opcioListo = this.opcioj[opcio]
        for (let indekso = 0; indekso < opcioListo.length; indekso++) {
            if (teksto === opcioListo[indekso])
                return indekso
        }
        return 1000
    }

    kreuSolvon() {
        let teksto = this.tekstoListo
        for (let indekso = 0; indekso < this.solvo.length; indekso++)
            if (this.solvo[indekso] !== -1) teksto[indekso] = this.estuElektitaOpcio(teksto[indekso], this.solvo[indekso])
        let teksto0 = ""
        for (let indekso in teksto) teksto0 += teksto[indekso]
        return teksto0
    }

    estuElektitaOpcio(opcio, indekso) {
        let opcioj = opcio.split("<option")
        let teksto = opcioj[0]
        for (let i = 0; i < opcioj.length - 1; i++)
            if (i === indekso + 1) teksto += "<option selected='selected'" + opcioj[i + 1]
            else teksto += "<option" + opcioj[i + 1]

        return teksto
    }

    /////////////////////////Eventoj
    solvu = () => {
        this.setState({
            teksto: this.solvoTeksto
        })
    }

    nuligu = () => {
        this.setState({
            teksto: this.tekstoKunOpcioj
        })
    }

}

export default ElektoScenoStarto

