import React from 'react';
import { Panel } from 'primereact/panel';
import "./grid.css"
class Dezajno extends React.Component {


    render() {
        return (
            <Panel id="bazo" header={this.props.header} toggleable
                className=" p-flex-column " >
                {this.html()}
                <div key="animacia-krado" id="animacia-krado" />
            </Panel >)
    }

    html = () => {
        if (this.props.admin === undefined) {
            this.telefonoKlaso = "p0 p-grid p-dir-row p-align-center p-d-md-none"
            this.komputiloKlaso = "p0  p-grid  nested-grid  p-d-none  p-d-md-inline-flex p-col-12"
            return <div>{this.telefono()}{this.komputilo()}</div>
        }
        else return this.admin()
    }
    admin() {
        this.telefonoKlaso = "p p-grid nested-grid p-dir-row p-align-center p-d-md-none "
        this.komputiloKlaso = "p0  p-grid  nested-grid  p-d-none  p-d-md-inline-flex p-col-12"
        this.border = "solid 1px red"
        this.border1 = this.border.replace("red", "blue")
        if (this.props.admin === "komputilo") {
            return this.komputilo()
        }

        this.telefonoKlaso = "p p-grid p-dir-row p-align-center"
        return this.telefono()
    }
    telefono = () => {
        this.enhavo = [...this.props.enhavo]
        return (
            <div className={this.telefonoKlaso}
                style={{ border: this.border1 }}>
                <div className='p p-grid p-col-5'>
                    {/*1a vico*/}
                    <div id="kradot7" className="p5 p-col-12 ronda" style={{ border: this.border }}>
                        {this.enhavo[7]}
                    </div>
                    {/*2a vico*/}
                    <div id="kradot0" className="p30 p-col-4 " style={{ border: this.border }}>
                        {this.enhavo[0]}
                    </div>
                    <div id="kradot1" className="p20 p-col-6 ronda " style={{ border: this.border }}>
                        {this.enhavo[1]}
                    </div>
                    {/*3a vico*/}
                    <div id="kradot2" className="p20 p-col-8 " style={{ border: this.border }}>
                        {this.enhavo[2]}
                    </div>
                    {/*4a vico*/}
                    <div id="kradot3" className="p5 p-col-5" style={{ border: this.border }}>
                        {this.enhavo[3]}
                    </div>
                    <div id="kradot4" className="p10 p-col-6 p-offset-6" style={{ border: this.border }}>
                        {this.enhavo[4]}
                    </div>
                    {/*navigilo*/}
                    <div className="p-col-12" style={{ border: this.border }}>
                        {this.props.navigado}
                    </div>
                </div>
            </div >)
    }
    komputilo() {
        this.enhavo = [...this.props.enhavo]
        return <div className={this.komputiloKlaso}>
            {/*1a kolumno*/}
            <div className="p-col-4 " >
                <div className="p p-grid p-dir-row p-align-center" style={{ border: this.border1 }}>
                    <div id="krado0" className="p50 p-col-4 " style={{ border: this.border }}>
                        {this.enhavo[0]}
                    </div>
                    <div id="krado1" className="p25 p-col-8 ronda " style={{ border: this.border }}>
                        {this.enhavo[1]}
                    </div>
                </div>
            </div >
            {/*2a kolumno*/}
            < div className="p p-col-4 " >
                <div className="p p-grid p-dir-col p-justify-top" style={{ border: this.border1 }}>
                    <div id="krado7" className="p5 p-col-12 ronda" style={{ border: this.border }}>
                        {this.enhavo[7]}
                    </div>
                    <div className="p10" />
                    <div id="krado2" className="p50 p-col-12" style={{ border: this.border }}>
                        {this.enhavo[2]}
                    </div>
                    <div className="p30 p-col-12">
                        <div id="krado3" className="p40 p-col-10" style={{ border: this.border }}>
                            {this.enhavo[3]}
                        </div>
                        <div id="krado4" className="p40 p-col-10 p-offset-2" style={{ border: this.border }}>
                            {this.enhavo[4]}
                        </div>
                    </div >
                </div >
            </div >
            {/*3a kolumno*/}
            < div className="p p-col-4 " >
                <div className="p p-grid p-dir-col p-justify-center" >
                    <div id="krado5" className="p40 p-col-10  p-offset-2" style={{ border: this.border }}>
                        {this.enhavo[5]}
                    </div>
                    <div className="p5" />
                    <div id="krado6" className="p40 p-col-10  p-offset-2" style={{ border: this.border }}>
                        {this.enhavo[6]}
                    </div>
                </div >
            </div >
            {/*navigilo*/}
            <div id="navigilo" className="p-col-12" style={{ border: this.border }}>
                {this.props.navigado}
            </div>
        </div >
    }

}
export default Dezajno