import React from 'react'
import { Button } from 'primereact/button'
import ForigButono from "./AdminKomponantoj/ForigButono"

import ScenoLegilo from '../Utilajhoj/ScenoLegilo'
import ElScenoPriskriboJson from '../Datumoj/ElScenoPriskriboJson'
import TekstoRedaktilo from './AdminKomponantoj/Editor/Editor'
import MomentoRedaktilo from './MomentoRedaktilo'
import { Toast } from 'primereact/toast'
import { PrimeIcons } from 'primereact/api';
import 'primeicons/primeicons.css';
import Momento from '../Kurso/Scenoj/BazaSceno/Momento'
import SurlokaTekstoEnigo from '../Utilajhoj/SurlokaTekstoEnigo';
import ScenoStato from '../Utilajhoj/ScenoStato';

import Datumbazo from '../Datumoj/DatumbazoMastrumilo/DatumbazoInterfaco'


import SelectEtikedo from '../Utilajhoj/SelectEtikedo/SelektEtikedo'

class ScenoAdministrado extends React.Component {
  constructor(props) {
    super(props)
    this.scenoId = 0
    this.scenoTipoNomo = ""
    this.scenoDifino = ""
    this.elektoIdentigilo = ""
    this.elektoIndekso = 0
    this.momentoIndekso = 0
    this.kradoj = []
    for (let indekso = 0; indekso < 20; indekso++) {
      this.kradoj[indekso] = <h1>{indekso}</h1>
    }
    this.sceno = ""
    if (this.props.state === "")
      this.state = {
        scenoStato: "",
        teksto: "",
        prezento: "",
        dezajnTipo: "1",
        sono: true
      }
    else this.state = this.props.state
  }

  componentWillUnmount() {
    this.props.referenco(this.state)
  }

  render() {
    let header = <table><tbody><tr>
      <td style={{ verticalAlign: "top" }}>
        <b>Por komputilo dezajnTipo:</b>
      </td>
      <td>
        <SurlokaTekstoEnigo teksto={this.state.dezajnTipo}
          referenco={(dezajnTipo)=>this.setState({ dezajnTipo: dezajnTipo })} />
      </td>
    </tr></tbody></table>
    return (
      <div id="leciono-pagho">
        <Toast ref={(el) => this.toast = el} />
        {this.adminMenuo()}
        <div id="priskribo"> </div>
        <ScenoLegilo scenoStato={this.state.scenoStato} sono={this.state.sono} />
        <div id="dezajnoj" className="p-grid">
          <div className="p-col-6 " >
            <TekstoRedaktilo teksto={this.state.teksto} referenco={this.kontrolu} />
          </div>
          <div className="p-col-5 " style={{ border: "solid 1px red" }} >
            <Momento dezajnTipo={this.state.dezajnTipo}
              enhavo={this.kradoj}
              header={header}
              admin="komputilo" />
          </div>
          <div className="p-col-1 " style={{ border: "solid 1px red" }} >
            <Momento dezajnTipo={this.state.dezajnTipo}
              enhavo={this.kradoj}
              header="Por telefono"
              admin="telefono" />
          </div>
        </div>
      </div>
    )
  }

  adminMenuo() {
    return (
      <div className="leciono-supra-strio">
        <Button className="p-mx-2" label="reen"
          onClick={() => this.props.referenco()} />
        <SelectEtikedo id="admin" kio="sceno" referenco={this.akceptoDeScenoId} />
        <Button icon={this.state.sono ? PrimeIcons.VOLUME_UP : PrimeIcons.VOLUME_OFF}
          onClick={() => this.setState({
            sono: !this.state.sono
          })} />
        <Button id="prezento" onClick={() => { this.prezento() }}
          label={this.state.prezento}
          className='p-mx-6' />
        <Button id="savu-ŝanĝojn" onClick={() => { this.savuŜanĝojn() }}
          style={{ visibility: "hidden" }}
          lebel="Savu ŝanĝojn" />
        <Button id="estu-skribebla"
          onClick={() => { this.estuSkribeblaj() }}
          style={{ visibility: "hidden" }}
          label="Estu skribeblaj"
          className='p-mx-6' />
        <Button id="savu-scenon" onClick={() => { this.modifuScenon() }}
          label="Savu scenon"
          style={{ visibility: "hidden" }} />
        <Button id="kreu-scenon" onClick={() => { this.kreuScenon() }}
          style={{ visibility: "hidden" }}
          label="Kreu kiel nova sceno"
          className='p-mx-6' />
          <ForigButono
          mesaĝo='Ĉu vi vere volas forigi el la datumbazo tiun ĉi scenon?'
          butonoSurskribo="Forĵeto de la sceno"
          akcepto={this.forigoDeLaSceno}
        />
      </div>
    )
  }
  forigoDeLaSceno = () => {
    new Datumbazo().forviŝuScenon(this.scenoId, this.akceptoDeSavoRezulto)
  }
  kreuScenon = () => {
    let sceno = new ElScenoPriskriboJson().faruJson(this.html)
    let referenco = this.akceptoDeSavoRezulto
    new Datumbazo().kreuScenon(sceno, referenco)
  }

  modifuScenon() {
    let sceno = new ElScenoPriskriboJson().faruJson(this.html)   
    let referenco = this.akceptoDeSavoRezulto
    new Datumbazo().modifuScenon(sceno, referenco)
  }

  akceptoDeSavoRezulto = (rezulto) => this.traktoDeSavoRezulto(rezulto)
  traktoDeSavoRezulto(rezulto) {
    if (rezulto.includes("Eraro"))
      this.toast.show({
        severity: 'error',
        summary: "malsukcesis",
        detail: rezulto, life: 10000
      });
    else
      this.toast.show({
        severity: 'success',
        summary: "sukcesis",
        detail: rezulto, life: 10000
      })
    this.listo = undefined
    this.setState({})
  }

  estuSkribeblaj() {
    MomentoRedaktilo.estuSkribeblaj()
  }

  savuŜanĝojn() {
    let novaMomentoTeksto = MomentoRedaktilo.momento()
    let momentoIndekso = this.scenoIndekso()
    this.html = this.momentoTeksto(momentoIndekso, novaMomentoTeksto)
  }

  momentoTeksto(n, novaTeksto) {
    let komencoIndekso = this.html.indexOf("momentoj:") + 10
    let komenco = this.html.slice(0, komencoIndekso)
    let teksto = this.html.slice(komencoIndekso)
    teksto = teksto.replaceAll("+\n", ";")
    teksto = teksto.split("\n")
    teksto[n] = novaTeksto
    teksto = this.kunigo(teksto)
    return komenco + teksto
  }

  kunigo(tekstoListo) {
    let teksto = ""
    for (let i in tekstoListo) {
      teksto += tekstoListo[i] + "\n"
    }
    return teksto
  }


  akceptoDeScenoId = (scenoId) => this.scenoElekto(scenoId)
  scenoElekto = (scenoId) => {
    this.scenoId = scenoId
    if (this.scenoId > 0)
      new Datumbazo().leguScenonLaŭId(this.scenoId, this.akceptoDeSceno)
  }

  akceptoDeSceno = sceno => this.aperigo(sceno)
  aperigo = (sceno) => {
    this.malplenaPanelo()
    let priSkribo = new ElScenoPriskriboJson().faruPriskribon(sceno)
    this.setState({
      scenoStato: "",
      prezento: "",
      teksto: priSkribo
    })

  }

  montru(scenoJson) {
    let teksto = JSON.stringify(scenoJson).replaceAll("[", "\n[")
    teksto = teksto.replace("]]}", "]\n]\n}")
    teksto = teksto.replace('],"', '],\n"')
    teksto = teksto.replaceAll(':\n[', ':[')
    teksto = teksto.replaceAll('],"', '],\n"')
    teksto = teksto.replace(',"', ',\n"')
    teksto = teksto.replace(',"', ',\n"')
    teksto = teksto.replace(',"', ',\n"')
    return teksto
  }

  administraPanelo() {

    document.getElementById("redaktilo").style.display = "block"
    document.getElementById("dezajnoj").style.visibility = "visible"
    document.getElementById("estu-skribebla").style.visibility = "hidden"
    document.getElementById("savu-ŝanĝojn").style.visibility = "hidden"
    document.getElementById("savu-scenon").style.visibility = "visible"
    document.getElementById("kreu-scenon").style.visibility = "visible"
  }

  momentoPanelo() {
    document.getElementById("redaktilo").style.display = "none"
    document.getElementById("dezajnoj").style.visibility = "hidden"
    document.getElementById("estu-skribebla").style.visibility = "visible"
    document.getElementById("savu-ŝanĝojn").style.visibility = "visible"
    document.getElementById("savu-scenon").style.visibility = "hidden"
    document.getElementById("kreu-scenon").style.visibility = "hidden"
  }

  malplenaPanelo() {
    document.getElementById("redaktilo").style.display = "block"
    document.getElementById("dezajnoj").style.visibility = "visible"
    document.getElementById("estu-skribebla").style.visibility = "hidden"
    document.getElementById("savu-ŝanĝojn").style.visibility = "hidden"
    document.getElementById("savu-scenon").style.visibility = "hidden"
    document.getElementById("kreu-scenon").style.visibility = "hidden"
  }

  prezento = () => {

    if (this.state.prezento === "Redakto") {
      this.malplenaPanelo()
      let teksto = this.state.teksto
      this.setState({
        scenoStato: "",
        prezento: "",
        teksto: teksto
      })
      return
    }

    if (this.state.prezento === "Prezento") {

      this.setState({
        scenoStato: this.sceno,
        prezento: "Redakto"
      })
      this.momentoPanelo()
    }
  }

  aktualaMomento = (momentoIndekso) => {
    this.momentoIndekso = momentoIndekso
  }

  kontrolu = (teksto) => {
    this.html = teksto
    let scenoDifino = new ElScenoPriskriboJson().faruJson(teksto)
    let scenoStato = new ScenoStato()
    scenoStato.valoroAlScenoDifino(scenoDifino)
    this.sceno = scenoStato
    this.administraPanelo()
    this.setState({
      prezento: "Prezento",
      teksto: teksto
    })
  }

}

export default ScenoAdministrado
