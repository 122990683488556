import $ from 'jquery'

class Kontrolo {
    kontrolo(solvo) {
        let kontrolo = true
        for (let indekso in solvo) {
            let respondo = $("#respondo" + indekso).val()
            if (respondo.length < 1) return false
            if (respondo === solvo[indekso] || solvo[indekso] === "?") {
                this.animadoGratulo(solvo[indekso])
                $("#respondo" + indekso).val("")
            } else {
                this.animadoHelpo(solvo[indekso].slice(0, 3) + "...")
                kontrolo = false
                break
            }
        }
        return kontrolo
    }
    animadoGratulo() {
        $("#animacia-krado").html("<img src = '../../bildoj/gratulo.png'>")
        setTimeout(function () { $("#animacia-krado").html("") }, 2000)
    }
    animadoHelpo(teksto) {
        $("#animacia-krado").html("??</br>" + teksto)
        setTimeout(function () { $("#animacia-krado").html("") }, 2000)
    }
}

export default Kontrolo