import $ from 'jquery';
import api from "../api.json"
import SonoMapoSavilo from "./SonoMapoSavo"

class SonoSavo {
  constructor() {
    this.sonoNomo = ""
    this.sonoObjektURL = ""
    this.mimetipo = ""
    this.sonoMapo = ""
    this.sonoMapoNomo = ""
    this.referenco = ""
    this.msg = ""
  }

  savuSonon = (datumoj, referenco) => {
    this.datumoj = datumoj
    this.referenco = referenco
    /*  if (datumoj.sonoId === null || datumoj.sonoŜanĝo)
        this.urlAlBlob(datumoj.sono, this.akceptoDeBlob)
      else*/
    if (datumoj.originaSonoNomo !== datumoj.sonoNomo) {
      this.savuSonoNomon(datumoj.sonoId, datumoj.sonoNomo)
    } else this.savuSonoMapon()
  }

  urlAlBlob = (objektoURL, referenco) => {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', objektoURL, true);
    xhr.responseType = 'blob';
    xhr.onload = function (e) {
      if (this.status === 200) {
        let blob = this.response
        referenco(blob);
      }
    };
    xhr.send();
  }

  akceptoDeBlob = blob => this.savoDeSono(blob)
  savoDeSono = (blob) => {
    let apiURL = api.urlBazo + api.novaSonoSavo
    var fd = new FormData();
    if (this.id !== null) {
      fd.append('sonoId', this.id);
      apiURL = api.urlBazo + api.sonoSavo
    }
    fd.append('sonoNomo', this.sonoNomo);
    fd.append('sono', blob);
    fd.append('mimeTipo', this.mimeTipo);
    console.log(apiURL)
    $.ajax({
      type: 'POST',
      url: apiURL,
      data: fd,
      processData: false,
      contentType: false,
      success: (msg) => {
        this.akceptoDeSonoSavo(msg)
      }
    })
  }

  akceptoDeSonoSavo = (msg) => {
    if (msg.includes("Eraro"))
      this.referenco(msg)
    else {
      this.datumoj.originaSonoNomo = this.datumoj.sonoNomo
      this.savuSonoMapon()
    }
  }
  savuSonoNomon(id, nomo) {
    console.log("sonoNomo")
    console.log([id, nomo])
    this.savuSonoMapon()
  }
  savuSonoMapon(){
        new SonoMapoSavilo(this.datumoj,this.referenco)
  }
  
}
  
export default SonoSavo