import React from 'react'
import BazaSceno from "../Kurso/Scenoj/BazaSceno/ScenoPreparo"
import Vortaro from "../Ideoj/VortaroDeCseh/VortaroStarto"
import TekstoAnalizo from "../Ideoj/TekstoAnalizo/TekstoAnalizoStarto"
import KlapoKlako from "../Ideoj/KlapoKlako/KlapoKlakoStarto"
import ElektoSceno from '../Ideoj/ElektoSceno/ElektoScenoStarto'
import ScenoTipoj from "../Datumoj/konstantoj.json"
class ScenoAktivigilo extends React.Component{
    render(){
        return this.aktiviguLaScenon(this.props.scenoStato,this.props.sono)
    }

    aktiviguLaScenon(scenoStato,sono) {
        if(scenoStato.prenoDeScenoDifino === undefined) return scenoStato
        if (scenoStato.prenoDeScenoDifino()===null) return ""
        let scenoTipo = this.scenoTipo(scenoStato.prenoDeScenoDifino())
        switch (scenoTipo) {
            case "bazaSceno":
                return <BazaSceno key="1" scenoStato={scenoStato} sono={sono} />
            case "elektoSceno":
                return <ElektoSceno key="2" scenoStato={scenoStato} />
            case "vortaro":
                return <Vortaro key="3" scenoStato={scenoStato} />
            case "klapoKlako":
                return <KlapoKlako key="4" scenoStato={scenoStato} />
            case "tekstoAnalizo":
                return <TekstoAnalizo key="5" scenoStato={scenoStato} />
            default: return ""
        }
    }
    scenoTipo(scenoDifino) {
        let scenoTipo=1
        if (typeof scenoDifino === "string")
            scenoTipo = JSON.parse(scenoDifino).scenoTipo
        else scenoTipo=scenoDifino.prenoDeScenoTipo()    
        return ScenoTipoj["scenotipoj"][scenoTipo]
    }
}

export default ScenoAktivigilo
