class TabuloFunkcioj {
    constructor() {
        this.kolonNombro = 4
        this.mezuro = 16
        this.klapoj = []
        this.enhavo = []
        this.solvo = ""
        this.klapoNomo = "kvadrato"
        this.vakaNomo = "vaka"
        this.vaka = 0
    }

    prenoDeMezuro = mezuro => this.mezuro = mezuro
    prenoDeKolonnombro = kolonNombro => this.kolonNombro = kolonNombro
    prenoDeKlapoj = klapoj => this.klapoj = klapoj
    prenoDeEnhavo = enhavo => this.enhavo = enhavo
    prenoDeSolvo = solvo => { this.solvo = solvo; this.solvo[this.mezuro - 1] = "." }


    ĉuPreta() {

        let n = 0
        for (let indekso = 0; indekso < this.vaka; indekso++) {
            if (this.klapoj[indekso].state.enhavo !== this.solvo[indekso]) {
                if (n === 1) return false
                n++
            }
        }
        return true
    }

    interŝanĝuEnhavon(id1, id2) {
        let enhavo = this.klapoj[id1].state.enhavo
        let klasoNomo = this.klapoj[id1].state.klasoNomo
        this.klapoj[id1].setState({
            enhavo: this.klapoj[id2].state.enhavo,
            klasoNomo: this.klapoj[id2].state.klasoNomo
        })
        this.klapoj[id2].setState({
            enhavo: enhavo,
            klasoNomo: klasoNomo
        })
    }

    miksu = () => {
        // this.nuligu()
        let tekstoListo = this.enhavo
        let vaka = this.kiuVakas(tekstoListo)
        tekstoListo = this.mikso(tekstoListo, vaka)
        this.tabulAranĝo(tekstoListo)
    }

    kiuVakas(tekstoListo) {
        let vaka = this.mezuro - 1
        for (let indekso = 0; indekso < this.mezuro; indekso++)
            if (this.enhavo[indekso] === ".") vaka = indekso
        return vaka
    }

    mikso(tekstoListo, vaka) {
        tekstoListo[vaka] = "."
        for (let indekso = 0; indekso < 200; indekso++) {
            let numero = this.prenuHazardanEntjeron(this.kolonNombro)
            switch (numero) {
                case 1://=> destren
                    if (vaka % this.kolonNombro === 0) break
                    tekstoListo[vaka] = tekstoListo[vaka - 1]
                    vaka--
                    tekstoListo[vaka] = "."
                    break
                case 2://<= maldekstren
                    if (vaka % this.kolonNombro === this.kolonNombro - 1) break
                    tekstoListo[vaka] = tekstoListo[vaka + 1]
                    vaka++
                    tekstoListo[vaka] = "."
                    break
                case 3://^ supren
                    if (vaka < this.kolonNombro) break
                    tekstoListo[vaka] = tekstoListo[vaka - this.kolonNombro]
                    vaka -= this.kolonNombro
                    tekstoListo[vaka] = "."
                    break
                case 4://v suben
                    if (vaka > this.mezuro - 1 - this.kolonNombro) break
                    tekstoListo[vaka] = tekstoListo[vaka + this.kolonNombro]
                    vaka += this.kolonNombro
                    tekstoListo[vaka] = "."
                    break
                default:
                    console.log("Erara hazarda numero: " + numero)
            }
        }
        return tekstoListo
    }

    prenuHazardanEntjeron(maksEntjero) {
        let numero = Math.random()
        numero = numero * (maksEntjero + 1)
        numero = Math.ceil(numero)
        return numero
    }

    tabulAranĝo(tekstoListo) {
        for (let indekso = 0; indekso < this.mezuro; indekso++) {
            let klapo = this.klapoj[indekso]
            let enhavo = tekstoListo[indekso]
            let klasoNomo = enhavo === "." ? this.vakaNomo : this.klapoNomo
            if (enhavo === ".") this.vaka = indekso
            klapo.setState({
                enhavo: enhavo,
                klasoNomo: klasoNomo
            })
        }

    }

    solvu() {
        let tekstoListo = this.solvo
        tekstoListo[this.mezuro - 1] = "."
        this.tabulAranĝo(tekstoListo)
    }
}

export default TabuloFunkcioj