
class MomentoRedaktilo {


    static momento() {

        let i = 0;
        let enhavo = document.getElementById("enhavo" + i)

        if (enhavo === null) return ""
        let enhavoj = ""
        while (enhavo !== null) {
            enhavoj += i + ":" + this.tekstigo(enhavo, i) + ";"
            i++
            enhavo = document.getElementById("enhavo" + i)
        }
        enhavoj = enhavoj.replaceAll("<br>", ";")
        enhavoj = enhavoj.slice(0, -1)
        return enhavoj
    }
    static estuSkribeblaj() {
        let i = 0
        let enhavo = document.getElementById("enhavo0")

        while (enhavo !== null) {
            if (enhavo.className.includes("teksto")) {
                document.getElementById("teksto" + i).setAttribute("contentEditable", true)
            }
            i++
            enhavo = document.getElementById("enhavo" + i)
        }
    }
    static tekstigo(enhavo, i) {
        let tipo = enhavo.className.replace("-enhavo", "")
        let teksto = ""
        switch (tipo) {
            case "teksto":
                teksto = document.getElementById("teksto" + i).innerHTML
                break
            case "bildo":
                teksto = "bildo" + document.getElementById("bildo" + i).alt

                break
            default: teksto = tipo
        }
        return teksto
    }
}
export default MomentoRedaktilo