import api from "./api.json"
import Datumbazo from "./DatumLegoj"
class ObjektoLegoj {
    constructor() {
        this.url = ""
        this.referenco = ""
        this.Datumbazo = new Datumbazo()
        this.sono = { sonoId: null, sonoNomo: null, sonoURL: null }
        this.bildoMapoListo = []
        this.bildoListo = []
        let urlBazo = api.urlBazo
        this.bildoMapojURL = urlBazo + api.bildoMapo
        this.bildoURL = urlBazo + api.bildoLego
        this.bildojURL = urlBazo + "bildojLego"
        this.tipoj = { blob: "blob", teksto: "teksto", svg: "teksto" }
    }

    //////bildolego/////////
    legoDeBildojKunMapo = (bildoMapoNomoListo, referenco) => {
        this.referenco = referenco
        this.legoDeBildoMapoj(bildoMapoNomoListo)
    }

    legoDeBildoMapoj(bildoMapoNomoj) {
        let getPeto = "?bildoj=" + bildoMapoNomoj
        getPeto = getPeto.replaceAll('"', '')
        this.Datumbazo.ajax(this.bildoMapojURL, getPeto, this.akceptoDeBildoMapoj)
    }


    akceptoDeBildoMapoj = bildoMapoj => this.bildojLego(bildoMapoj)
    bildojLego = (bildoMapoj) => {
        bildoMapoj = bildoMapoj.replaceAll("{},", "")
        bildoMapoj = bildoMapoj.replaceAll(",{}", "")
        try {
            this.bildoMapoListo = JSON.parse("[" + bildoMapoj + "]")
        }
        catch (err) {
            this.bildoMapoListo = err.message;
            this.referenco({
                "bildoListo": this.bildoListo,
                "datumoj": this.bildoMapoListo
            })
        }
        // this.bildoLego(0)
        this.legoDeLaBildoj()
    }
    legoDeLaBildoj() {
        let referenco = this.akceptoDeLaBildoj
        let idListo = this.bildoIdListo()
        new Datumbazo().legoDeLaBildojLauId(idListo, referenco)
    }
    bildoIdListo = () => {
        let idListo = []
        for (let indekso in this.bildoMapoListo) {
            let id = this.bildoMapoListo[indekso].bildoId
            if (!idListo.includes(id))
                idListo.push(id)
        }
        this.idListo=idListo
        return idListo
    }

    akceptoDeLaBildoj = (bildoj) => this.kreoDeBildoListo(bildoj)
    kreoDeBildoListo = (bildoj) => {
        bildoj = bildoj.split("dividilo:")
        let bildoListo = []
        for (let i = 0; i < bildoj.length; i = i + 2) {
            let bildo = JSON.parse(bildoj[i])
            let bildoBlob = bildoj[i + 1]
            bildoBlob = Buffer.from(bildoBlob, 'base64')
            bildoBlob = new Blob([bildoBlob], { type: bildo.mimeTipo })
            let img = URL.createObjectURL(bildoBlob)
            bildoListo.push({"idKajNomo":bildo,"bildo":img})
        }
        this.bildoListo = []//bildoListo
        for (let indekso in this.bildoMapoListo) {           
           let id = this.bildoMapoListo[indekso].bildoId
            this.bildoListo.push(this.bildoListobildoLauID(id, bildoListo))

        }
        this.referenco({
            "bildoListo": this.bildoListo,
            "datumoj": this.bildoMapoListo
        })
    }
    bildoListobildoLauID(id, bildoListo) {
        for (let indekso in bildoListo){
            if (id+"" === bildoListo[indekso].idKajNomo.id)
                return (bildoListo[indekso].bildo)}
        return bildoListo[0].bildo
    }

    bildoLego = (indekso) => {
        if (indekso === this.bildoMapoListo.length) {
            this.referenco({
                "bildoListo": this.bildoListo,
                "datumoj": this.bildoMapoListo
            })
            return
        }

        let bildoj = this.bildoMapoListo
        if (bildoj[indekso].bildoId === undefined) return
        let id = bildoj[indekso].bildoId
        let tipo = bildoj[indekso].mimeTipo
        this.indekso = indekso
        if (tipo.includes("svg")) tipo = this.tipoj.svg
        else tipo = this.tipoj.blob
        let getPeto = "?id=" + id
        this.Datumbazo.ajax(this.bildoURL, getPeto, this.akceptoDeBildo, tipo)
        //TODO      
    }

    akceptoDeBildo = bildo => this.aldonuAlBildoListo(bildo)
    aldonuAlBildoListo = (bildo) => {
        if (this.bildoMapoListo[this.indekso].mimeTipo === "image/svg+xml")
            bildo = new Blob([bildo], { type: 'image/svg+xml' })
        bildo = URL.createObjectURL(bildo)
        this.bildoListo.push(bildo)
        this.bildoLego(this.bildoListo.length)
    }
    //////sonolego/////////
    legoDeSonoKunMapo = (sonoNomo, referenco) => {
        this.referenco = referenco
        this.Datumbazo.legu(
            "sonoj",
            "sonoId,sonoNomo,mimeTipo",
            "sonoNomo='" + sonoNomo + "'",
            this.akceptoDeSonoDonitaĵoj)
    }
    akceptoDeSonoDonitaĵoj = (sonoDonitaĵoj) => this.sonoLego(sonoDonitaĵoj)
    sonoLego(sonoDonitaĵoj) {
        sonoDonitaĵoj = JSON.parse(sonoDonitaĵoj)
        this.sono.sonoId = sonoDonitaĵoj.sonoId
        this.sono.sonoNomo = sonoDonitaĵoj.sonoNomo
        this.sono.mimeTipo = sonoDonitaĵoj.mimeTipo
        this.Datumbazo.leguSonon(
            sonoDonitaĵoj.sonoNomo,
            this.akceptoDeSono,
        )
    }
    akceptoDeSono = (sono) => this.sonoBlob(sono)
    sonoBlob(sono) {
        let sonoURL = URL.createObjectURL(sono)
        this.sono.sonoURL = sonoURL
        this.Datumbazo.legu(
            "paroloj",
            "*",
            "sonoId='" + this.sono.sonoId + "'",
            this.akceptoDeSonoMapo
        )
    }
    akceptoDeSonoMapo = (sonoMapo) => this.sonoMapo(sonoMapo)
    sonoMapo(sonoMapo) {
        sonoMapo = JSON.parse("[" + sonoMapo + "]")
        this.sono.sonoMapo = sonoMapo
        this.referenco(this.sono)
    }
}
export default ObjektoLegoj