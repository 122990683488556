
class DatumObjekto {
    constructor() {
        this.dividilo = "*"
        this.teksto = ""
        this.opcioj = ""
    }
    prenoDeSceno = () => this.sceno
    prenoDeTeksto = () => this.teksto
    prenoDeOpcioj = () => this.opcioj
    prenoDeDividilo = () => this.dividilo
    valoroAlTeksto = (teksto) => this.teksto = teksto
    valoroAlOpcioj = (opcioj) => this.opcioj = opcioj
    valoroAlDividilo = (dividilo) => this.dividilo = dividilo
}
export default DatumObjekto