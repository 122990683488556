import React from 'react';
import ReactDOM from 'react-dom';
import PrimeReact from 'primereact/api';
import { locale, addLocale,} from 'primereact/api';
import './index.css'

//import 'primereact/resources/themes/saga-green/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

import Paĝoj from './paghoj/Paghoj';

PrimeReact.ripple = true;
PrimeReact.autoZIndex = true;
addLocale('eo', {
  firstDayOfWeek: 1,
  dayNames: ['dimanĉo', 'lundo', 'mardo', 'merkredo', 'ĵaŭdo', 'venredo', 'sabato'],
  dayNamesShort: ['dim', 'lun', 'mar', 'mer', 'ĵaŭ', 'ven', 'sab'],
  dayNamesMin: ['D', 'L', 'M', 'X', 'Ĵ', 'V', 'S'],
  monthNames: ['januaro', 'februaro', 'marto', 'aprilo', 'majo', 'junio', 'julio', 'aŭgusto', 'septembro', 'oktobro', 'novembro', 'decembro'],
  monthNamesShort: ['jan', 'feb', 'mar', 'ajr', 'maj', 'jun', 'jul', 'aŭg', 'sep', 'okt', 'nov', 'dec'],
  today: 'Hodiaŭ',
  clear: 'Klara'
});

locale('eo'); 
ReactDOM.render(
  <React.StrictMode>
    <Paĝoj />
  </React.StrictMode>,
  document.getElementById('root')
)