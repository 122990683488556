import React, { Component } from 'react';
import { Slider } from 'primereact/slider';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-green/theme.css';
import './slider.css';


class Ŝovilo extends Component {

    constructor(props) {
        super(props);
            
        this.state = {
            value: 20
        };
    }

    render() {
        return (
                           
                <Slider value={this.props.value} onChange={this.props.onChange} />
            
           
        );
    }
}
export default Ŝovilo