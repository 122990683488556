import React from 'react'
import VortarIlo from './VortarIlo'
import Supersignoj from '../../Kurso/KursoKomponantoj/SuperSignilo/Supersignoj'

class VortaroStarto extends React.Component {
    constructor(props) {
        super(props)
        this.VortarIlo = new VortarIlo(this.props.scenoDifino)
        this.state = {
            vortoKlarigo: ""
        }
    }

    render() {
        return (
            <div id="sceno-krado" >
                <label htmlFor="vorto">Konversacia vortaro de Andreo Ĉe<br /><br /> Enskribu vorton! </label>
                <Supersignoj />
                <input type="text" className="skribebla" id="vorto" />
                <button type="button" id="sekva" onClick={(evento) => { this.aperuVortDifino(evento) }}>Serĉu</button>
                <p>{this.state.vortoKlarigo}</p>
            </div>
        )
    }

    /////////////////////////Eventoj   
    aperuVortDifino(evento) {
        let vorto = document.getElementById("vorto").value
        let difino = this.VortarIlo.trovuVortDifinon(vorto)
        this.setState({ vortoKlarigo: difino })
    }

}

export default VortaroStarto