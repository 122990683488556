import VortaroStarto from "./VortaroStarto"

class DatumObjekto {
    constructor() {
        this.sceno = <VortaroStarto />
        this.vortoListo = []
    }
    prenoDeSceno = () => this.sceno
    prenoDeVortolisto = () => this.vortoListo.sort()
    valoroAlVortolisto = (vortoListo) => this.vortoListo = vortoListo
}


export default DatumObjekto