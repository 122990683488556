import ObjektoLegoj from "./ObjektoLegoj"
import api from "./api.json"

class DatumLegoj {
    constructor() {
        let urlBazo = api.urlBazo
        this.url = ""
        this.legoURL = urlBazo + api.lego
        this.bildoId = urlBazo + api.bildoLego
        this.bildojLauId = urlBazo +"bildojLegoLauId.php"
        this.sonoLegoLauNomo = urlBazo + "sonoLegoLauNomo.php"

    }

    ajaxGet(getPeto, referenco, tipo) {
        (async () => {
            let rezulto
            const res = await fetch(this.url + getPeto);
            if (tipo === "blob")
                rezulto = await res.blob()
            else rezulto = await res.text()
            referenco(rezulto)
        })();
        if (tipo === undefined) tipo = "teksto"
       // console.log(this.url + getPeto)
      //  console.log(" tipo: " + tipo)
    }
    leguSonon(sonoNomo, referenco) {
        this.url = this.sonoLegoLauNomo
        let getPeto = "?sonoNomo=" + sonoNomo
        this.ajaxGet(getPeto, referenco, "blob")
    }
    ajax(url, getPeto, referenco, tipo) {
        this.url = url
        this.ajaxGet(getPeto, referenco, tipo)
    }

    legu(tabulo, kolumnoj, kondiĉo, referenco, tipo) {
        this.url = this.legoURL
        if (kondiĉo !== "")
            kondiĉo = "WHERE " + kondiĉo
        let getPeto = "?tabulo=" + tabulo + "&kolumnoj=" + kolumnoj + "&kondicho=" + kondiĉo
        this.ajaxGet(getPeto, referenco, tipo)
    }

    legoDeBildojKunMapo(bildoMapoNomoListo, referenco) {
        new ObjektoLegoj().legoDeBildojKunMapo(bildoMapoNomoListo, referenco)

    }
    legoDeSonoKunMapo(sonoNomo, referenco) {
        new ObjektoLegoj().legoDeSonoKunMapo(sonoNomo, referenco)

    }
    legoDeLaBildojLauId(idListo,referenco){
        this.url=this.bildojLauId 
        let getListo=JSON.stringify(idListo).slice(1,-1)
        let getPeto="?idListo="+getListo
        this.ajaxGet(getPeto, referenco)
    }
    legoDeLaBildoLauId(id, referenco) {
        this.url = this.bildoId
        let getPeto = "?id=" + id
        this.ajaxGet(getPeto, referenco)

    }

    /*   legoDeLaBildoLauBildoMapoNomo(bildoMapoNomo, referenco) {
           this.legoDeLaBildojKunMapo([bildoMapoNomo], referenco)
       }*/


}

export default DatumLegoj

