
class DatumObjekto {
    constructor() {

        this.teksto = ""
    }

    prenoDeSceno = () => this.sceno
    prenoDeTeksto = () => this.teksto
    valoroAlTeksto = (teksto) => this.teksto = teksto
}
export default DatumObjekto