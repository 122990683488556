import { ReactFitty } from "react-fitty";
class BildoKradoKreilo { 

    enhavo(bildo,alto) {
        if ( typeof bildo === "string" ) {
            return <ReactFitty >
                    La bildo {bildo} ne ekzistas.
            </ReactFitty>            
        }
        let bildoLoko =  bildo.prenoDeBildoLoko()
        let bildoNomo= bildo.prenoDeBildoNomo()
        let surskriboj=bildo.prenoDeSurskriboj()
        surskriboj=JSON.stringify(surskriboj)
        let enhavo =
            <div  className="bildo-enhavo p-d-flex p-flex-column ">
                {this.tekstoKampo()}                
                {this.bildo(bildoLoko, bildoNomo,alto)}
                <div id={"mapoPor"+bildoNomo} style={{visibility:"hidden"}}>
                    {surskriboj}
                </div>    
            </div>
        return enhavo
    }
    tekstoKampo() {
        let teksto = <div className=" p-as-center p25" style={{fontSize:"150%"}} >.</div> 
        return teksto
    }
    bildo(bildoLoko, bildoNomo,alto) {
        let img =
            <img className=" p-as-center"
                src={bildoLoko}               
                alt={bildoNomo}
                onMouseMove={(evento) => {this.aperu(evento) }}
                onMouseOut={(evento) => { this.malaperu(evento) }}
                style={{maxWidth:"100%", maxHeight:alto}}
            />
        return img
    }
    aperu = (evento) => {   
        let img=evento.target  
        let tekstoKampo=img.parentElement.children[0]
        let mapo=img.parentElement.children[2].innerHTML
        let vorto=this.teksto(img,mapo,evento.clientX,evento.clientY)
        if (vorto !== tekstoKampo.innerHTML)
             tekstoKampo.innerHTML=vorto
     
    }
    malaperu = (evento) => {
        let tekstoKampo=evento.target.parentElement.children[0]
        tekstoKampo.innerHTML = "."
    }
    teksto(img,mapo,x,y) {
        let imgLoko=img.getBoundingClientRect()
        let larĝo= imgLoko.width 
        let alto = imgLoko.height
        x+=-imgLoko.left
        y+=-imgLoko.top
        x=x/larĝo*100.
        y=y/alto*100.
        let teksto=this.tekstoElMapo(mapo,x,y)
        return teksto
    }
    tekstoElMapo(mapo,x,y){
        mapo=JSON.parse(mapo)
        let teksto="."
        for (let indekso in mapo){
            if (x >mapo[indekso].supraX && x<mapo[indekso].subaX)
                if (y >mapo[indekso].supraY && y<mapo[indekso].subaY){
                    teksto=mapo[indekso].teksto
                    break
                }    
        }
        return teksto

    }
 
}

export default BildoKradoKreilo