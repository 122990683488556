import ScenoObjekto from "./ScenoObjekto"
import BildoDatumo from "./BildoDatumo"

class BazaScenoKreilo {
   
    kreuScenon(sceno,bildoListo) {
       // let scenoTipo = sceno.scenoTipo
        let scenoBildoListo = sceno.bildoj
        let bildoj = this.scenoBildoj(bildoListo)
        let Scenoobjekto = new ScenoObjekto()
        let dezajnTipo = sceno.dezajnTipo
        let dezajnTipoj = []
        if (dezajnTipo !== undefined) {
            dezajnTipoj[0] = dezajnTipo
            Scenoobjekto.valoroAlDezajnTipoj(dezajnTipoj)
        }
        Scenoobjekto.valoroAlScenoTipo(sceno.scenoTipo)
        Scenoobjekto.valoroAlId(sceno.id)
        Scenoobjekto.valoroAlBildoNomoj( scenoBildoListo)
        Scenoobjekto.valoroAlBildoj(bildoj)
        Scenoobjekto.valoroAlSonoj(sceno.sonoj)
        let momentoj = this.kreuScenoMomentojn(sceno)
        momentoj = this.ĉiujKradoj(momentoj)
        Scenoobjekto.valoroAlMomentoj(momentoj)
        Scenoobjekto.valoroAlDezajnTipoj(this.listoDeDezajnTipoj(Scenoobjekto))
        return Scenoobjekto
    }
    
    scenoBildoj(bildoListo){
        let bildoj= []
        for (let indekso in bildoListo){
                bildoj[indekso] = new BildoDatumo()
                bildoj[indekso].valoroAlBildoId(bildoListo[indekso].bildoId)
                bildoj[indekso].valoroAlBildoNomo(bildoListo[indekso].bildoNomo)
                bildoj[indekso].valoroAlBildoLoko(bildoListo[indekso].bildoLoko)
                bildoj[indekso].valoroAlMimeTipo(bildoListo[indekso].mimeTipo)
                let surskribo=JSON.parse(bildoListo[indekso].surskribo)
                bildoj[indekso].valoroAlSurskriboj(surskribo) 
                
            }
        return bildoj    
    }
    kreuScenoMomentojn(sceno) {
        if (sceno.momentoj !== undefined) return sceno.momentoj
        let momentoj = []
        for (let momentoIndekso in sceno.momentoj) {
            momentoj[momentoIndekso] = sceno.momentoj[momentoIndekso]
        }
        return momentoj
    }


    ĉiujKradoj(momentoj) {
        let longo = momentoj.length
        let enhavoj = []
        let pliFrua = ""
        for (let i = 0; i < longo; i++) {
            let enhavo = momentoj[i]
            if (!this.ĉuNumero(enhavo[0])) {
                enhavoj[i] = enhavo;
                continue
            }
            if (pliFrua === "") {
                pliFrua = enhavo
                enhavoj[i] = enhavo;
                continue
            }
            enhavoj[i] = this.plenaEnhavo(enhavo, pliFrua)
            pliFrua = enhavoj[i]
        }
        return enhavoj
    }

    plenaEnhavo(enhavo, pliFrua) {
        let novaVico = [...pliFrua]
        for (let i = 0; i < enhavo.length; i += 2)
            for (let indekso = 0; indekso < pliFrua.length; indekso += 2)
                if (pliFrua[indekso] === enhavo[i]) {
                    novaVico[indekso + 1] = enhavo[i + 1]
                }

        return novaVico
    }

    listoDeDezajnTipoj(Datumobjekto) {
        let dezajnTipoj = Datumobjekto.prenoDeDezajnTipoj()
        let momentoj = Datumobjekto.prenoDeMomentoj()
        for (let indekso in momentoj) {
            if (momentoj[indekso][0] === "dezajnTipo")
                dezajnTipoj[indekso] = momentoj[indekso][1]
            if (dezajnTipoj[indekso] === undefined)
                dezajnTipoj[indekso] = dezajnTipoj[parseInt(indekso) - 1]
        }
        return dezajnTipoj
    }

    ĉuNumero(momento) {
        return !isNaN(parseInt(momento))
    }



}

export default BazaScenoKreilo