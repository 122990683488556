import React from 'react';
import Klapo from './Klapo'
import TabuloFunkcioj from './TabuloFunkcioj'


class Tabulo extends React.Component {
    constructor(props) {
        super(props)
        this.TabuloFunkcioj = new TabuloFunkcioj()
        this.klapoNomo = "kvadrato"
        this.vakaNomo = "vaka"

        this.klapoj = []

        this.klako = this.klako.bind(this)
        this.prenuKlapon = this.prenuKlapon.bind(this)

        this.enhavo = this.props.enhavo.split(" ")
        this.kolonNombro = this.props.mezuro

        this.mezuro = this.kolonNombro * this.kolonNombro
        this.vaka = this.mezuro - 1

        this.state = {
            enhavo: "",
            paŝo: 1,
            anonco: ""
        }
    }

    componentDidMount() {
        this.TabuloFunkcioj.prenoDeKolonnombro(this.kolonNombro)
        this.TabuloFunkcioj.prenoDeMezuro(this.mezuro)
        this.TabuloFunkcioj.prenoDeKlapoj(this.klapoj)
        this.TabuloFunkcioj.prenoDeEnhavo(this.enhavo)
        this.TabuloFunkcioj.prenoDeSolvo(this.props.enhavo.split(" "))
    }

    render() {
        let klapoj = []
        let tabuloId = "tabulo" + this.props.mezuro
        for (let indekso = 0; indekso < this.mezuro; indekso++) {
            let klasoNomo = this.klapoNomo
            let enhavo = this.enhavo[indekso]

            if (indekso === this.vaka) { klasoNomo = this.vakaNomo; enhavo = '.' }

            klapoj[indekso] = <Klapo key={indekso + "klapo"} id={indekso} enhavo={enhavo} klasoNomo={klasoNomo}
                onclick={(evento, klapo) => { this.klako(evento, klapo) }}
                senduKlapon={(klapo) => { this.prenuKlapon(klapo) }} />
        }

        return (
            <div key="1tab" id={tabuloId}>
                {klapoj}
                <div key="2tab" id="anonco" >{this.state.anonco}</div>
                <div>
                <input key="3tab" type="button" value="Solvo" onClick={() => { this.solvu() }} />
                <input key="4tab" type="button" value="Miksu" onClick={() => { this.miksu() }} />
                </div>    
            </div>
        )
    }


    prenuKlapon = (klapo) => this.klapoj[klapo.props.id] = klapo

    klako = (evento, id) => {
        if ((Math.abs(id - this.vaka) !== 1) && (Math.abs(id - this.vaka) !== this.kolonNombro))
            return

        this.setState({
            paŝo: this.state.paŝo + 1
        })

        this.TabuloFunkcioj.interŝanĝuEnhavon(id, this.vaka)
        this.vaka = id


        if (!this.ĉuPreta()) return
        let anonco = "Vi gajnis! Vi faris: " + this.state.paŝo + " paŝojn"
        this.setState({ anonco: anonco })
    }

    ĉuPreta() {
        if (this.vaka !== this.mezuro - 1) return false
        let preta = this.TabuloFunkcioj.ĉuPreta()
        if (!preta) return false
        this.setState({
            paŝo: 1
        })
        return true
    }

    miksu = () => {
        this.nuligu()
        this.TabuloFunkcioj.miksu()
        this.vaka = this.TabuloFunkcioj.kiuVakas()
    }

    solvu() {
        this.nuligu()
        this.TabuloFunkcioj.solvu()
        this.vaka = this.mezuro - 1
    }

    nuligu() {
        this.setState({
            anonco: "",
            paŝo: 1
        })
    }
}

export default Tabulo