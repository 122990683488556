import DatumObjektoKreilo from '../DatumObjektoKreilo'
import ScenoKreilo from "./VortaroKreilo"


class VortarIlo {
    constructor(scenoDifino) {
        this.Vortaro = new DatumObjektoKreilo().petoDeObjekto(
            new ScenoKreilo(),
            scenoDifino
        )
        this.vortoListo = this.Vortaro.prenoDeVortolisto()
    }
    prenoDeIdentigilo = () => this.identigilo
    trovuVortDifinon(vorto) {
        let indekso = 100
        let indeksoSuba = 0
        let indeksoSupra = this.vortoListo.length
        while (indeksoSuba !== indeksoSupra) {
            indekso = Math.floor((indeksoSuba + indeksoSupra) / 2)
            if (indekso === indeksoSuba) break
            if (this.vortoListo[indekso][0] <= vorto)
                indeksoSuba = indekso
            else indeksoSupra = indekso
        }
        if (this.vortoListo[indekso][0] !== vorto) indekso++

        return this.vortoListo[indekso][0] + ":\n" + this.vortoListo[indekso][1]
    }
}

export default VortarIlo